import React,{ useState, useEffect } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import {Logger } from '../../tools'
import { Jobs } from '../../requests'
import CompaniesAlbums from '../../requests/CompaniesAlbums'
import CompaniesMovies from '../../requests/CompaniesMovies'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import { withStyles } from '@material-ui/core/styles'

const styles = theme=> ({
	blueBar: {
	  width: '100%',
	  height: '30px',
	  backgroundColor: 'blue',
	  color: 'white',
	  textAlign: 'center',
	  lineHeight: '30px',
	  marginBottom: '10px',
	},
	greenBarContainer: {
	  width: '100%',
	  height: '30px',
	  backgroundColor: '#ddd',
	  marginBottom: '10px',
	},
	greenBar: {
	  height: '100%',
	  backgroundColor: 'green',
	  color: 'white',
	  textAlign: 'center',
	  lineHeight: '30px',
	},
	margin: {
		margin: theme.spacing(1),
	},
  })

const ProgressExport = (props) => {
	const {   ExportJobs ,selectedAlbum} = props

  const [progress, setProgress] = useState(0);
  const [progressSuccess, setProgressSuccess] = useState(0);
  const [progressEchec, setProgressEchec] = useState(0);

  const [jobsDone, setJobsDone] = useState(0); 
  const [jobsFailed, setJobsFailed] = useState(0); 
  const [failedJobs, setFailedJobs] = useState([]);
  const [listJobToAdd, setListJobToAdd] = useState([]);
  const [isExporting, setIsExporting] = useState(true);
  const [completedJobs, setCompletedJobs] = useState(0); // Jobs terminés
  const [totalJobs, setTotalJobs] = useState(ExportJobs.length); 

  useEffect(() => {
    // Appeler la fonction Export à l'ouverture du composant
    const initiateExport = async () => {
      await Export()
      //await saveAllJobs(listJobToAdd)
    };

    initiateExport()
  }, [])

//   useEffect(() => {
//     const runJobs = async () => {
//       for (let i = 0; i < ExportJobs.length; i++) {
//         try {
//           // Simuler l'enregistrement de chaque tâche dans la BD
// 		  console.log(ExportJobs[i])
//           await saveJobToDatabase(ExportJobs[i])
//           setJobsDone((prev) => prev + 1)
//         } catch (error) {
//           setJobsFailed((prev) => prev + 1)
         
//           setFailedJobs((prev) => [...prev, { type: ExportJobs[i].type, ref: ExportJobs[i].ref }]);
//         } finally {
//           // Met à jour le pourcentage de progression
//           setProgress(((i + 1) / ExportJobs.length) * 100)
//         }
//       }
//     };

//     runJobs();
//   }, [ExportJobs]);
const SuccessLinearProgress = withStyles({
	root: {
		height: 20,
		backgroundColor: green[200],
		borderRadius: 20,
	},
	bar: {
		borderRadius: 20,
		backgroundColor: green[700],
	},
})(LinearProgress)

const FailureLinearProgress = withStyles({
	root: {
		height: 20,
		backgroundColor: red[200],
		borderRadius: 20,
	},
	bar: {
		borderRadius: 20,
		backgroundColor: red[700],
	},
})(LinearProgress)

  const Export = async () => {
	let date = new Date()
	let finalJobs = []
	let promises = []
	let endpromise = 0
	let successCount = 0
	let failCount = 0

	ExportJobs.forEach((elt) => {
		//elt.executiondate = date.toLocaleString("en-US")
		//endpromise = endpromise+1
		if (elt.typeid === 12) {
			switch (elt.sfralbumType) {
				case 0: {//album uniquement
					let seriejob = {
						typeid: elt.typeid,
						refid: selectedAlbum.id,
						xml: elt.xml,
						picture: elt.picture,
						executiondate: date.toLocaleString("en-US")
					}
					finalJobs.push(seriejob)
					break
				}
				case 1: {//'Toutes les saisons'
					//let albumparent = selectedAlbum
					if (selectedAlbum.typeid === 5) {
						let promise = CompaniesAlbums.getAlbum(selectedAlbum.parentid).then((res) => {
							if (res.data)
								CompaniesAlbums.getAlbumsChilds(res.data.id).then((data) => {
									data.data && data.data.forEach((season) => {
										let newjob = {
											typeid: elt.typeid,
											refid: season.id,
											xml: elt.xml,
											picture: elt.picture,
											executiondate: date.toLocaleString("en-US")
										}
										finalJobs.push(newjob)
									})
								}
								)
						})
						promises.push(promise)
					}

					break
				}
				case 2: {// Série + saison(4,5)  et pack + programme(2)
					if (selectedAlbum.typeid === 2) {
						let seriejob = {
							typeid: elt.typeid,
							refid: selectedAlbum.id,
							xml: elt.xml,
							picture: elt.picture,
							executiondate: date.toLocaleString("en-US")
						}
						finalJobs.push(seriejob)
						let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
							if (res.data) {

								//listjobs = listjobs.filter(s=>s.typeid !== 11)
								res.data.forEach((item) => {
									if (item.movie.id !== 0) {
										let progjob = {
											typeid: 11,
											refid: item.movie.id,
											xml: elt.xml,
											picture: elt.picture,
											executiondate: date.toLocaleString("en-US")
										}
										finalJobs.push(progjob)
									}
								})
							}
						})
						promises.push(promise)
					} else {
						let albumparent = selectedAlbum
						if (selectedAlbum && selectedAlbum.typeid === 5) {
							let promise = CompaniesAlbums.getAlbum(selectedAlbum.parentid).then((res) => {
								if (res.data) {
									albumparent = res.data
									let seriejob = {
										typeid: elt.typeid,
										refid: albumparent.id,
										xml: elt.xml,
										picture: elt.picture,
										executiondate: date.toLocaleString("en-US")
									}
									finalJobs.push(seriejob)
									if (albumparent && albumparent.typeid === 4) {
										let promise = CompaniesAlbums.getAlbumsChilds(albumparent.id).then((res) => {
											if (res.data) {
												let seasons = res.data
												seasons.forEach((season) => {
													let newjob = {
														typeid: elt.typeid,
														refid: season.id,
														xml: elt.xml,
														picture: elt.picture,
														executiondate: date.toLocaleString("en-US")
													}
													finalJobs.push(newjob)
												})
											}
										}) 
									}
								}
							})
							promises.push(promise)
							//onExportJobs(finalJobs)
						}

					}


					break
				}
				case 3: {//  programme
					if (selectedAlbum.typeid === 2 || selectedAlbum.typeid === 5) {
						let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
							if (res.data) {
								//listjobs = listjobs.filter(s=>s.typeid !== 11)
								res.data.forEach((item) => {
									if (item.movie.id !== 0) {
										let progjob = {
											typeid: 11,
											refid: item.movie.id,
											xml: elt.xml,
											picture: elt.picture,
											executiondate: date.toLocaleString("en-US")
										}
										finalJobs.push(progjob)
									}
								})
							}
						})
						promises.push(promise)
						// 		if(album.typeid === 5){

					}


					break
				}
			}

		}
		else if (elt.typeid === 28) {
			let promise = CompaniesMovies.getMoviesBddpByAlbumId(selectedAlbum.id).then(movies => {
				if (!movies.data) return
				if (movies.data && movies.data.companiesmovies) {
					movies.data.companiesmovies.forEach(movie => {
						if (movie.idbddp) {
							let newjob = {
								typeid: elt.typeid,
								refid: movie.movieId,
								executiondate: date.toLocaleString("en-US"),
								idbddp: movie.idbddp
							}
							finalJobs.push(newjob)
						}
					})

				}
			})
			promises.push(promise)

		}
		else if (elt.typeid === 29) {
				if (elt.albumHAPI  === 0 || elt.albumHAPI  ===1){
					let newjob = {
						typeid: elt.typeid,
						refid: selectedAlbum.id,
						executiondate: date.toLocaleString("en-US")
					}
					finalJobs.push(newjob)
					
				}
				if(elt.albumHAPI === 0 || elt.albumHAPI ==2 ){
					let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
						if (res.data) {
							res.data && res.data.forEach((movie => {
								let newjob = {
									typeid: 26,
									refid: movie.movie.id,
									executiondate: date.toLocaleString("en-US")
								}

								finalJobs.push(newjob)

							}))

						}
					})
					promises.push(promise)
				}
			

		}
		else if (elt.typeid === 30) {
			if (elt.imgtype !== '') {
				if (elt.bddpAlbum === 0 || elt.bddpAlbum === 1) {
					let newjob = {
						typeid: elt.typeid,
						refid: selectedAlbum.id,
						imgtype: elt.imgtype,
						executiondate: date.toLocaleString("en-US")
					}
					finalJobs.push(newjob)

				}
				if (elt.bddpAlbum === 0 || elt.bddpAlbum === 2) {
					let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
						if (res.data) {
							res.data && res.data.forEach((movie => {
								let newjob = {
									typeid: 27,
									refid: movie.movie.id,
									imgtype: elt.imgtype,
									executiondate: date.toLocaleString("en-US")
								}

								finalJobs.push(newjob)

							}))

						}
					})
					promises.push(promise)
				}
			}
		}
		else if(elt.typeid === 8 || elt.typeid === 37){
			let addjob = {
				typeid: elt.typeid,
				refid: selectedAlbum.id,
				executiondate: date.toLocaleString("en-US")
			}
			finalJobs.push(addjob)
		}
	})


	// if(endpromise === ExportJobs.length)
	// await Promise.allSettled(promises).then(() => {
 	// 	console.log(finalJobs)
	// 	setListJobToAdd(finalJobs)
	// 	setTotalJobs(finalJobs.length)
	// 	})
	
		  // Utilisez Promise.allSettled pour surveiller toutes les promesses
		  await Promise.allSettled(promises).then((results) => {
			results.forEach((result, index) => {
			  if (result.status === 'fulfilled') {
				successCount++;
			  } else if (result.status === 'rejected') {
				console.log(`Promesse ${index + 1} échouée :`, result.reason);
				failCount++;
			  }
			}		
		);
		
			// Lorsque toutes les promesses sont terminées (soit réussies, soit échouées)
			console.log("Jobs finaux :", finalJobs);
			setListJobToAdd(finalJobs);
 			
		  }).then(()=>
			{
				setIsExporting(false)
				saveAllJobs(finalJobs)
				setTotalJobs(finalJobs.length);
			}
	);
		




}
const saveAllJobs = async (jobs) => {
    let jobsSucceeded = 0;
    let jobsFailed = 0;
    for (let i = 0; i < jobs.length; i++) {
      try {
        await saveJobToDatabase(jobs[i]);
        setJobsDone((prev) => prev + 1);
		// setCompletedJobs(jobsSucceeded++);
		setProgressSuccess(((jobsSucceeded +1)/jobs.length) *100)
        jobsSucceeded++;
      } catch (error) {
        setJobsFailed((prev) => prev + 1);
		setProgressSuccess(((jobsFailed +1)/jobs.length) *100)

        setFailedJobs((prev) => [...prev, { type: jobs[i].typeid, ref: jobs[i].refid }]);
        jobsFailed++;
      } finally {
        setProgress(((i + 1) / jobs.length) * 100); // Mise à jour de la barre de progression
      }
    }
	setCompletedJobs(jobsSucceeded)
    console.log(`Jobs réussis : ${jobsSucceeded}, Jobs échoués : ${jobsFailed}`);
  };
  const saveJobToDatabase = (job) => {
		return new Promise((resolve, reject) => {
 			Jobs.addJobs(job).then(
				(json) => {
					Logger.log(json)
					resolve()
					// handleSnackbarOpen('Job ajouté','success')
					// setValidateCreateJobs(false)
					// ImportexportRequest.getJob(selected.id, selected.type == 'movie' ? 1 : 2).then((json) => {
					// 	setJobs(json.data.values)
					// }).catch((error)=>{
					// 	console.log(error)
					// 	setJobs([])
					// })
					// resolve()
				}
			).catch((error)=>{
				Logger.debug(error)
				//setValidateCreateJobs(false)
				reject()
			})
		})

     
  };
 
  return (
    <div>
      {/* Barre de progression bleue */}
      {isExporting && (
        <div>
          <div style={{textAlign:'center'}}>
			Chargement des données en cours...
		  <br/>
		  </div>
		  	<br/>
		   <LinearProgress  style={styles.blueBar} />
		   <br/>
		   <br/>
        </div>
		

      )}

      {/* Barre de progression verte */}
      {/* <div style={styles.greenBarContainer}>
	  <span>Progression d'export </span>
        <div style={{ ...styles.greenBar, width: `${progress}%` }}>
          {progress.toFixed(2)}%
        </div>
      </div> */}

      {/* Résultat final
      {!isExporting && (
        <div>
          <p>
            Jobs terminés : {jobsDone}/{totalJobs}
          </p>
          <p>Jobs réussis : {completedJobs }</p>
          <p>Jobs échoués : {jobsFailed}</p>
        </div>
      )} */}

					<div style={{textAlign:'center'}}>Succès : { jobsDone } / { totalJobs }</div>
					<SuccessLinearProgress
						//className={classes.margin}
						variant="determinate"
						color="secondary"
						value={progressSuccess }
					/>
					<br/>
					<div style={{textAlign:'center'}}>Echec : { jobsFailed } / { totalJobs }</div>
					<FailureLinearProgress
						//className={classes.margin}
						variant="determinate"
						color="secondary"
						value={ progressEchec}
					/>

    </div>
  );

//   return (
//     <div>
//       <Typography variant="h6">Export des jobs</Typography>
//       <LinearProgress variant="determinate" value={progress} />
// 	  <Typography variant="body1">{`Progression : ${Math.round(progress)}%`}</Typography>
//       <Typography variant="body1">{`Jobs réussis : ${jobsDone}`}</Typography>
//       <Typography variant="body1">{`Jobs échoués : ${jobsFailed}`}</Typography>
//       <Typography variant="body1">{`Total des jobs : ${totalJobs}`}</Typography>


//       {failedJobs.length > 0 && (
//         <div>
//           <Typography variant="h6">Détails des jobs échoués</Typography>
//           <ul>
//             {failedJobs.map((job, index) => (
//               <li key={index}>
//                 {`Type: ${job.type}, Ref: ${job.ref}`}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
};

export default ProgressExport;
