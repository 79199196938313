import React from 'react'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import { AlbumIcon, MovieIcon, PhotoIcon, DescriptionIcon ,RepeatIcon   } from '../../constants/icons'
import { AlbumField } from '../common/fields'
import CompaniesAlbums from './../../requests/CompaniesAlbums'
import MUIDataTable from 'mui-datatables'
import { Badge, LinearProgress } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { DomainsItems } from '../../../src/tools'
import CustomFooter from './../layout/MuiCustomFooter'
import FilterField from '../common/fields/FilterField'
import { Links } from '../../../src/tools'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { OnChangeCritere, OnChangeValue, onLoadAlbums, OnChangeTab } from '../../actions/companiesAlbums'
import Typography from '@material-ui/core/Typography'
import FavoriteItem from '../common/fields/FavoriteItem'
import CustomFilter from '../publicationtracking/fields/CustomFilter'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import {CustomAddToListButton} from './../layout/GlobalStyles'
import ContactsFavorites from './../../requests/ContactsFavorites'
import { Url } from '../../tools'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link } from 'react-admin'
import AssistantPack from '../assistantpacks/AssistantPack'


//const resourceName = 'Companiesalbums'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},
	formControl: {
		margin: theme.spacing(2),
		width: '15%'
	},
	searchButton: {
		color: 'rgba(0, 0, 0, 0.54)',
		// margin: theme.spacing(3)
	},
	switch: {
		right: '0%',
		position: 'absolute',
		marginTop: '2%',
		width: '20em'
	},
	favHeaderStyle: {
		padding: '1px',
		//position: 'sticky',
		top: '0',
		paddingBottom: '10px',
		color: '#0000008a',
		fontWeight: 'bold',
		fontSize: '0.78em',
		backgroundColor: '#FFF',
		// padding: '5px',
		// paddingLeft: '0px',
		//borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	imageStyle:{
		width: 50,
		maxWidth : 50,
		//height: 60,
		objectFit: 'cover',
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: '10000'
	}
})
const mode = [
	{ id: 1, name: "Nom de l'album" },
	{ id: 2, name: "Identifiant de l'album" },
	{ id: 3, name: 'Identifiant du programme' },
	{ id: 4, name: 'Identifiant BDDP' },
]
const objectId = 2
class CompaniesalbumsList extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			data: [],
			init: [],
			childCount: [],
			albumMovie: [],
			isLoading: true,
			contactFav: [],
			searchText: '',
			openPack : false,
			selectedalbum : 0
		}
	}
	componentDidMount  = async () => {
		const { searchValue, tabValue, OnChangeTab } = this.props
		this.setState({ searchText: searchValue,
		isLoading : true })

		await CompaniesAlbums.getAllalbums().then((json) => {
			if (json.data) {
				this.setState({ init: json.data })
				ContactsFavorites.getAll(objectId).then((fav) => {
					if (fav.data)
						this.setState({ contactFav: fav.data })
					this.searchfunction(searchValue)

				})
			}
		})
		if (tabValue !== 0) OnChangeTab(0)
		this.searchfunction(searchValue)
		this.getChildCount()
	}
	componentDidUpdate(prevProps) {
		const { contactFavorite} = this.props
		if (this.state.contactFav && contactFavorite.length !== 0 && this.state.contactFav !== contactFavorite) {
			this.setState({ contactFav: contactFavorite })
		}
	}
	getAlbumsByMovieId = async (movieid) => {
		await CompaniesAlbums.getAlbumsMovies(movieid).then((json) => {
			if (json.data !== undefined) {
				this.setState({ albumMovie: json.data })
			}
		})
		//return []
	}
	getChildCount = async()=> {
		await CompaniesAlbums.getAlbumsChildsCount().then((json) => {
			this.setState({ childCount: json.data })
		})
		this.setState({isLoading: false})


	}
	handleSearchButtonClick = () => {
		const { searchValue } = this.props
		this.searchfunction(searchValue)
	}
	searchfunction = (value) => {
		const { critere, searchValue, onLoadAlbums } = this.props
		const val = searchValue !== value ? value : searchValue
		this.setState({ isLoading: true })
		let res = []
		if (val === "*") {
			res = this.state.init
		} else	if (this.state.init.length !== 0 && val && val !== '') {
				switch (critere) {
					case 1: {
						res = this.state.init.filter(x => x.name.toUpperCase().includes(val.toUpperCase()))
					}
						break
					case 2: {
						res = this.state.init.filter(x => x.id.toString() === val)
					}
						break
					case 3: {
						this.getAlbumsByMovieId(val)
						res = this.state.albumMovie
					}
						break
					case 4: {
						res = this.state.init.filter(x => x.bddpId.includes(val))
					}
						break
					default:
						break
				}
			}
		if (this.state.contactFav && val==='' ) { //&& Token.getisAlbumFavoris() 
			this.state.contactFav.forEach((item) => {
				if (res && res !== [] && res.find(x => x.id === item.refId) === undefined)
					res.push(this.state.init.find(x => x.id === item.refId))
			})
		}
		this.setState({
			// data: this.convertDataToTable(res).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1)
			// data: this.convertDataToTable(res).sort((a, b) => (a.id < b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1)

			data: this.convertDataToTable(res).sort((a, b) => (a.rank > b.rank) ? 1 : -1)
			.sort((a, b) => (a.type > b.type) ? 1 : -1)
			.sort((a, b) => (a.fakeid > b.fakeid) ? 1 : -1)
			// .sort((a, b) => (a.favorite < b.favorite) ? 1 : -1)
		})
		// onLoadAlbums(this.convertDataToTable(res).sort((a, b) => (a.id < b.id) ? 1 : -1).sort((a, b) => (a.favorite < b.favorite) ? 1 : -1))

		onLoadAlbums(this.convertDataToTable(res).sort((a, b) => (a.rank > b.rank) ? 1 : -1)
		.sort((a, b) => (a.type > b.type) ? 1 : -1)
		.sort((a, b) => (a.fakeid > b.fakeid) ? 1 : -1)
		// .sort((a, b) => (a.favorite < b.favorite) ? 1 : -1)
		)

	}
	getValueFromProperties = (property,label) => {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(property, "text/xml")
		let Properties = []
		if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
			for (let i = 0; i < xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length; i++) {
				let newProp = {
					name: xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME"),
					value: xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("VALUE")
				}
				Properties.push(newProp)
			}
		}
 		// if (xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME") === 'PACK') {

 		// 	xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].setAttribute("VALUE", event.target.checked ? 1 : 0)

		// }
		return Properties.find(s => s.name === label) !== undefined ? Properties.find(s => s.name === label).value : ''
	}
	convertDataToTable = (data) => {
		let result = []
		const Types = this.getalbumsType()
		if (data !== undefined)
			data.forEach(element => {
				const newItem =
				{
					avatar: '',
					id: element.id,
					name: element.name,
					parentid: element.parentid,
					albumCount: this.state.childCount.filter(x => x.id === element.id),
					bddpId: element.bddpId,
					type: Types.find(x => x.itemid === element.typeid) ? Types.find(x => x.itemid === element.typeid).label : '',
					rank: element.rank,
					fakeid: element.typeid === 5 ? element.parentid : element.id,
					favorite: this.state.contactFav && this.state.contactFav.find(x => x.refId === element.id) ? 1 : 0,
					isPack: this.getValueFromProperties(element.properties,'PACK')
				}
				result.push(newItem)

			})

		this.setState({isLoading: false})

		return result
	}

	handleChange = (event) => {
		const { OnChangeCritere } = this.props
		OnChangeCritere(event.target.value)
	}
	handleValueChange = (event, type) => {
		const {  OnChangeValue } = this.props
		//this.setState({searchText : event.target.value})
		if (type === 'reset') {
			OnChangeValue('')
			this.searchfunction('')
		}
		OnChangeValue(event.target.value)

	}
	getKeyValue = event => {
		const { searchValue } = this.props
		if (event.key === "Enter") {
			this.searchfunction(searchValue)
		}
	}
	getMuiTheme = () => createTheme({
		overrides: {
			MuiPaper: {
				root: {
					backgroundColor: '#FFF',

				}
			},
			MUIDataTable: {

				responsiveScrollMaxHeight: {
					overflowX: 'auto',
					minWidth: 'calc(850px + 100vw - 1024px)',
					maxWidth: 'calc(950px + 100vw - 1024px)',
					minHeight: 'calc(75vh - 100px)',//'680px',
				},
				root: {
					backgroundColor: '#AAF',
				},
				paper: {
					boxShadow: 'none',
					width: '100%',
					minHeight: 'calc(70vh - 100px)',//'680px',


				}
			},
			MuiTableCell: {
				root: {
					textAlign: 'center',
					lineHeight: '1em'
				},
			},
			MUIDataTableBodyCell: {
				root: {
					//backgroundColor: '#FFF',
					padding: '0px',
					boxShadow: 'inset 0 1px 0 gey, inset 0 1px 0 white',
					lineHeight: '1em',
					fontSize: '0.7825rem',
				}
			},
			MUIDataTableHeadCell: {
				data: {
					color: '#0000008a',
					fontWeight: 'bold',
					fontSize: '0.825em',
					lineHeight: '1.2em'
				},

				root: {
					backgroundColor: '#FFF',
					padding: '5px',
					//paddingLeft: '20px',// '2px 1px 2px 20px',
					'&:nth-child(1)': {
						width: '3%',
					},
					//position: '-webkit-sticky',
					position: 'sticky',
					top: '0',
					zIndex: '0',
					color: '#0000008a',
					fontWeight: 'bold',
					fontSize: '0.825em',
 
				},
				contentWrapper:{
					alignItems :'center',
					display: 'grid'
				}
			},
			MuiDialog: {
				paperWidthSm: {
					minWidth: '400px',
					maxWidth: '800px',
					backgroundColor: '#fff'// '#232c31'
				}
			},
			MUIDataTableToolbar: {
				actions: {
					position: 'sticky',
					right: '0px',
					bottom: '0px',
					flex: 'unset',
				}
			},
			MuiBadge: {
				badge: {
					//zIndex: 100,

					fontSize: '0.7rem',
				},
				colorSecondary: {
					backgroundColor: '#288db1'

				},
				anchorOriginTopRightRectangle:{
					top: '-3px',
					right: '-6px'
				}
			}

		},
	})

	getalbumsType = () => {
		return Object.values(DomainsItems.getItems(21))
	}
	OpenPackAssistant=(albumid)=>{
		// CompaniesAlbums.getAlbum(albumid).then((json) => 
		// 	{
		// 	this.setState({selectedalbum :json.data } , ()=>{
		// 		this.setState({ openPack: true})
		// 	})}
		// )

  	}
	GetImportExport=(albumid)=>{
		window.open(`${process.env.PUBLIC_URL}/#/Importexport?refid=${albumid}&objectid=2`, '_blank')

  	}
	render() {
		const { classes, critere, searchValue, AlbumsData ,OnChangeTab} = this.props
		const defaultProps = {
			color: 'secondary',
			marginright: '20px',

		}
		console.log(this.state.contactFav)
		// const modalContent = {
		// 	title: 'ASSISTANT PACK',
		// 	description: <AssistantPack defaultalbum={this.state.selectedalbum} />,
		// 	disagreeButtonText: translate('ra.action.undo'),
		// 	agreeButtonText: translate('ra.action.confirm')
		// }
		const columns = //['avatar', 'id' , 'name' , 'parentid' ];
			[
				{
					name: 'avatar',
					label: ' ',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 100, color: '#000', padding: '0' } }),
						filter: false,
						customBodyRender: (value, metadata) => {
							return  (
							//<Image url={Url.pictureAlbum(metadata.rowData[1], metadata.rowData[1] +'_600x800.jpg')} width ={70} height={70}/>
								// <ImageField label={metadata.rowData[2]} source='filename' styleImg="imagespeoples" basePath='/Albumspictures' record={item} size={50} />
								<Link to={Links.CompaniesalbumsShow(metadata.rowData[1])}>
								<img
								title={metadata.rowData[2]}
								alt={metadata.rowData[2]}
								src={Url.avatarAlbum(metadata.rowData[1])}
								onError={
									e => {
										e.target.onerror = null
										e.target.src = Url.defaultPicture
									}
								}
								className={classes.imageStyle }
 								/>
								</Link>
							)
						}
					}
				},
				{
					name: 'id',
					label: 'ID',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddingLeft: '20px', paddingTop: '.8em' } }),
						filter: false,
						search: true,
						customBodyRender: (value, metadata) => {
							return <AlbumField value={metadata.rowData[1]} data={metadata}
								addId={false} addLabel={true} addAvatar={false} isLink={true}
								style={{ color: '#000' ,marginLeft:'20px'}}
							/>

						}

					}
				},
				{
					name: 'name',
					label: "NOM D'ALBUM",
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0', color: '#000', padding: '0', paddingTop: '.8em' } }),
						filter: false,
						search: false,
						customBodyRender: (value, metadata) => {
							return <AlbumField value={metadata.rowData[1]}
								text={metadata.rowData[2]}
								addId={false} addLabel={true} addAvatar={false} isLink={true}
								style={{ color: '#000' }}
							/>
						}
					}
				},
				{
					name: 'parentid',
					label: 'ID PARENT',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0', color: '#000', padding: '0', paddingTop: '.8em',textAlign:'center' } }),
						filter: false,
						search: true,
						customBodyRender: (value, metadata) => {
							return <AlbumField value={value}
								addId={false} addLabel={true} addAvatar={false} isLink={true}
								style={{ color: '#000',marginLeft:'20%' }} toolTipMsg="Consulter l'album parent"
							/>
						}
					}
				},
				{
					name: 'bddpId',
					label: 'ID BDDP',
					filterType: 'textField',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0', color: '#000', padding: '0', paddingTop: '.8em',marginLeft:'50px' } }),
						filter: false,
						search: true,
					}
				},
				{
					name: 'type',
					label: 'TYPE',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: '0', color: '#000', padding: '0', paddingTop: '.8em' } }),
						filter: true,
						search: false,
						setCellProps: (value) => {
							const backgroundColor = this.getalbumsType().filter(s=>s.label ===value)[0].color

							return {
								style: {
									backgroundColor: backgroundColor
								}
							}
						}
					}
				},
				{
					name: 'rank',
					label: 'RANG',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', padding: '0', paddingTop: '.8em' } }),
						filter: false,
						search: true,
					}
				},
				{
					name: 'albumCount',
					label: ' ',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', zIndex: 1000, } }),
						filter: false,
						customBodyRender: (value,metadata) => {
 							// console.log(this.state.init.filter(x => x.id == value[0].id)[0].pack)

							return (
								<div style={{zIndex : 0}} >
									<Link to={Links.CompaniesalbumsShow(metadata.rowData[1])}>
									<Badge badgeContent={value[0] ? value[0].albumseditorialsCount : 0} max={99} {...defaultProps} style={{ marginRight: '25px', color: value[0] && value[0].albumseditorialsCount === 0 ? 'grey' : 'black' }}>
										<Tooltip title="Editorial" >
											<DescriptionIcon onClick={()=>OnChangeTab(1)}/>
										</Tooltip>
									</Badge>
									</Link>
									<Link to={Links.CompaniesalbumsShow(metadata.rowData[1])} style={{ pointerEvents: value[0] && value[0].albumsmoviesCount === 0 ? 'none' : 'auto' }}>
									<Badge badgeContent={value[0] ? value[0].albumsmoviesCount : 0} max={99}  {...defaultProps} style={{ marginRight: '25px', color: value[0] && value[0].albumsmoviesCount === 0 ? 'grey' : 'black' }}>
										<Tooltip title="Programme" >
											<MovieIcon onClick={()=>OnChangeTab(2)} />
										</Tooltip>
									</Badge>	
									</Link>
									<Link to={Links.CompaniesalbumsShow(metadata.rowData[1])}>
									<Badge badgeContent={value[0] ? value[0].albumspicturesCount : 0} max={99} {...defaultProps} style={{ marginRight: '25px', color: value[0] && value[0].albumspicturesCount === 0 ? 'grey' : 'black' }}>
										<Tooltip title="Image" >
											<PhotoIcon onClick={()=>OnChangeTab(3)} />
										</Tooltip>
									</Badge>
									</Link>
									
									<Link to={Links.CompaniesalbumsShow(metadata.rowData[1])}>				
									<Badge badgeContent={value[0] ? this.state.init.filter(x => x.parentid === value[0].id).length : 0} max={99} {...defaultProps} style={{ marginRight: '25px', color: value[0] && this.state.init.filter(x => x.parentid === value[0].id).length === 0 ? 'grey' : 'black' }}>
										<Tooltip title="Sous albums" >
											<AlbumIcon onClick={()=>OnChangeTab(4)}/>
										</Tooltip>
									</Badge>
									</Link>
									{/* <Link to={`${process.env.PUBLIC_URL}/#/Importexport?refid=${value[0].id}&objectid=2`}>	 */}
									<Badge  		>
 										<Tooltip title="Import / Export" >
											<RepeatIcon  onClick={()=>this.GetImportExport(value[0].id)} style={{   color:   'black' }}/>
										</Tooltip>
									</Badge> 
									{/* </Link > */}
									{/* <Link to={Links.CompaniesalbumsShow(metadata.rowData[1])}>				 */}
									{/* <Badge badgeContent={this.state.init.filter(x => x.id == value[0].id)[0].pack ? 1 : 0} max={99} {...defaultProps} style={{ marginRight: '25px', color: this.state.init.filter(x => x.id == value[0].id)[0].pack ?  'black' : 'grey'}}>
										<Tooltip title="Gestion PACK" >
											<SettingsIcon  onClick={()=>this.OpenPackAssistant(value[0].id)}/>
										</Tooltip>
									</Badge> */}
									{/* </Link> */}
								</div>
							)
						}
					}
				},
				{
					name: 'favorite',
					label: 'FAVORIS',
					options: {
						setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', paddin: '0' ,font: '10px',zIndex:100 } }),
						filter: true,
						search: true,
						filterType: 'custom',
						customFilterListOptions: {
							render: v => {
								let res = ''
								v.forEach(item => item === 0 ? res += 'Non favoris,' : res += 'Favoris,')
								return `Favoris: ${res}`
							}
						},
						filterOptions: {
							logic: (favorite, filters) => {
								if (filters.length) return !filters.includes(favorite)
								return false
							},
							display: (filterList, onChange, index, column) => {
								let optionsValues = []
								optionsValues.push({ value: 0, label: "Non favoris" })
								optionsValues.push({ value: 1, label: "Favoris" })

								const defaultLabel = 'Favoris'
								return <CustomFilter filterList={filterList} onChange={onChange} index={index} column={column}
									optionsValues={optionsValues} defaultLabel={defaultLabel} />
							}
						},
						customBodyRender: (value, tableMeta) => {
							return (
							<div style={{zIndex : 0}}>
								<FavoriteItem favStatus={value} object='cet album' refId={tableMeta.rowData[1]} //isFavoritShow={Token.getisAlbumFavoris()}
								objectId={objectId} ObjectFav={this.state.contactFav} searchAlbumfunction={() => this.searchfunction(searchValue)}
								count={this.state.contactFav ? this.state.contactFav.length : 0} />
								</div>)
						},
						customHeadRender: (columnMeta) => (
							// <Badge badgeContent={this.state.contactFav.length} max={99}  {...defaultProps} style={{marginTop:'12px'}}>
							// 	<Typography className={classes.favHeaderStyle}>FAVORIS</Typography>
							// </Badge>
							<TableCell style={{  position: "sticky", top: 0, background: "#fff", zIndex: 1000}}>
								<TableHead>
								<Badge badgeContent={this.state.contactFav.length} max={99}  {...defaultProps} style={{marginTop:'12px'}}>
									<Typography className={classes.favHeaderStyle}>FAVORIS</Typography>
								</Badge>
								</TableHead>
							</TableCell>
						)
					}
				},
				// {
				// 	name: '',
				// 	label: '',
				// 	options: {
				// 		setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', padding: '0' } }),
				// 		 					}
				// },
			]
		const options = {
			responsive: 'scrollMaxHeight',//["standard","vertical","verticalAlways","simple"]
			//fixedSelectColumn: true,
			fixedHeader: true,
			rowsPerPage: 20,
			rowHover: false,
			//sort: false,
			textLabels: {
				body: {
					noMatch: this.state.isLoading  && <LinearProgress />,
					toolTip: 'Trier'
				},
				filter: {
					all: 'Toutes',
					title: 'FILTRES',
					reset: 'REINITIALISER',
				},
				selectedRows: {
					text: 'rows has been deleted',
					delete: 'Delete Row',
					deleteAria: 'Deleted Selected Rows'
				},
				toolbar: {
					search: 'Rechercher',
					downloadCsv: 'Télécharger CSV',
					print: 'Imprimer',
					viewColumns: 'Voir colonnes',
					filterTable: 'Filtrer',
				},
				viewColumns: {
					title: 'Montrer colonnes',
					titleAria: 'Montrer/Cacher colonnes',
				},
			},
			setTableProps: () => {
				return {
					//padding: "10px",
					//size: this.state.denseTable ? "small" : "medium",
					padding: 'none',
					size: 'medium',
				}
			},
			selectableRows: 'none',
			//onRowClick: (event) => this.handleRowClick(event),
			resizableColumns: false,
			print: false,
			download: false,
			customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
				textLabels.rowsPerPage = 'Lignes par page'
				return (
					<CustomFooter
						count={count}
						page={page}
						rowsPerPage={rowsPerPage}
						changeRowsPerPage={changeRowsPerPage}
						changePage={changePage}
						textLabels={textLabels} />
				)
			},
			customToolbar: () => {
				return (
					<CustomAddToListButton link={Links.CompaniesalbumsAdd()} title='Créer un album' />
				)
			},
		}
		// const CloturedFilterSwitch = () => (
		// 	<FormControl component="fieldset" className={classes.switch}>
		// 		<FormControlLabel
		// 			value="top"
		// 			control={
		// 				<Tooltip title={Token.getisAlbumFavoris() ? "Masquer les favoris" : "Afficher les favoris"}
		// 					placement="right-start"
		// 					TransitionComponent={Zoom}
		// 				>
		// 					<Switch
		// 						checked={Token.getisAlbumFavoris()}
		// 						onChange={() => this.handleSwitchFavorisChange()}
		// 						color="primary" size="small"
		// 						inputProps={{ 'aria-label': 'primary checkbox' }}
		// 					/>
		// 				</Tooltip>

		// 			}
		// 			label={<span style={{ fontSize: '.8em', marginLeft: '1.5em' }}>{Token.getisAlbumFavoris() ? "Masquer les favoris" : "Afficher les favoris"}</span>}
		// 			labelPlacement="right"
		// 		/>
		// 	</FormControl>
		// )
		return (
			<ThemeProvider theme={this.getMuiTheme()}>
				{this.state.isLoading && <div className={classes.circularProgress}><CircularProgress size={50} thickness={3} value={25}/></div>}

			<div className={classes.root}>
				<FilterField selectedValue={critere} values={mode} classes={classes}
					valueChange={event => this.handleValueChange(event, '')}
					valueReset={event => this.handleValueChange(event, 'reset')} selectedChange={event => this.handleChange(event)} removeicon={true}
					ButtonClick={() => this.handleSearchButtonClick()} getKeyValue={event => this.getKeyValue(event)} textValue={searchValue}
					//switch={<CloturedFilterSwitch />}
				/>
				<div style={{ height: 500, width: "100%" }}>
					{AlbumsData.length > 0 ?
							<MUIDataTable title="Liste des albums" data={AlbumsData } columns={columns} options={options} />
						:
 							<MUIDataTable title="Liste des albums" columns={columns} options={options} />}
 				</div>
			</div>
			<AssistantPack {...this.props}
						open={this.state.openPack}
						onClose={this.handleClickClosePack}
						// modalContent={modalContent}
						launchActivationProcess={this.handleSubmit} />
			</ThemeProvider >

		)
	}

}
const mapStateToProps = state => ({
	...state,
	critere: state.reducer.albums.critere !== null ? state.reducer.albums.critere : 1,
	searchValue: state.reducer.albums.searchValue !== undefined ? state.reducer.albums.searchValue : '',
	tabValue: state.reducer.albums.tabValue,
	AlbumsData: state.reducer.albums.AlbumsData,
	AllAlbums: state.reducer.albums.AllAlbums,
	contactFavorite: state.reducer.favorite.contactFavorite,

})

const mapDispatchToProps = ({
	OnChangeCritere,
	OnChangeValue,
	onLoadAlbums,
	OnChangeTab,
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default translate(enhance(CompaniesalbumsList))