
import React, { Component,useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Card from '@material-ui/core/Card'
import { ImageGridList } from '../common/lists'
import LogoPicture from '../../pictures/typo_logo_incruste_hawc_bleu.png'
import { Funny } from '../../requests'
import { AUTH_PAGE_LOADED } from '../../constants/actions'
// import { darkTheme } from '../layout/themes'
import { MyNotification } from '../layout'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {Container,  Box, CircularProgress} from '@material-ui/core'
import { Token, Logger } from '../../tools'
import { Domains, Contacts, DomainsItems, Companiestransfers, Authentification } from '../../requests'
import authProvider from '../../authProvider'
//eslint-disable-next-line react/prop-types
import { ConfigContext } from '../layout/ConfigProvider'

const Logo = ({ src, classes }) => <img src={src} alt="Hawc" className={classes.logo} />

const styles = theme => ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		height: '1px',
		alignItems: 'center',
		justifyContent: 'flex-start',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
	card: {
		position: 'fixed',
		top: '25%',
		minWidth: 300,
		textAlign: 'center',
	},
	logo: {
		width: 'auto',
		height: '50px',
		marginTop: '20px',
	},
	error: {
		color: 'red',
		fontSize: 'larger',
		fontWeight: 'bold',
		padding: '25px'
	},
	helperText: {
		//position: "absolute",
		marginRight: "1%",
		//bottom: "-1.6em",
		right: "0",
		fontSize: '0.7rem',
		color: 'darkred'
	},
	valid: {
		color: 'green'
	},
	invalid: {
		color: 'black'
	}
})
// // eslint-disable-next-line no-unused-vars
const sanitizeRestProps = ({ classes, className, location, title, array, theme, staticContext, onLoadMosaic, ...rest }) => rest
class CallBack extends Component {
	state = {
		ResetPwd: '',
		ReinitPwdstatus: true,
		confPassword: '',
		length: false,
		uppercase: false,
		lowercase: false,
		number: false,
		special: false,
		identique: false,
		PwdError: false,
		showPassword: false,
		showPasswordConf: false,
		expiredToken: false,
		succeedPwdReset: false

	}
	static contextType = ConfigContext
	UNSAFE_componentWillMount() {
		const { config } = this.context 

		const key = 'AUTH_MOSAIC'
		const mosaic = localStorage.getItem(key)
		if (null != mosaic) {
			this.props.onLoadMosaic(JSON.parse(mosaic))
		}
		else {
			Funny.getRandomMosaic(346, 1).then(json => {
				this.props.onLoadMosaic(json.data)
				localStorage.setItem(key, JSON.stringify(json.data))
			})
		}
	
			{
			var uid =  window.location.href.split('=')[1]
			Authentification.LoginRollback(uid).then((data) => {
					localStorage.removeItem('refreshToken')
					Token.store(data.data)
					//return true
					if (!localStorage.getItem("dataDomains")) {
						Domains.getAll().then((json) =>
							localStorage.setItem("dataDomains", JSON.stringify(json.data))
						)
					}
					if (!localStorage.getItem("dataContacts")) {
						console.log('call')
						Contacts.getAll().then((json) =>
							localStorage.setItem("dataContacts", JSON.stringify(json.data))
						)
					}
					if (!sessionStorage.getItem("dataDomainsItems")) {
						DomainsItems.getAll().then((json) =>
							sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
						)
					}
					if (!sessionStorage.getItem("pricePlanning")) {
						Companiestransfers.getPricePlanningTargets().then((json) =>
							sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
						)
					}
					authProvider('AUTH_GET_PERMISSIONS').then(()=>{
						setTimeout(() => {
							console.log('rollback get cache')
							 window.location.href = process.env.PUBLIC_URL + '/#'
						}, 2000)
					})
					
					return true
				}).catch(error => {
					var errorMessage = 'ra.notification.http_error'
					switch (error.message) {
						case 'Forbidden':
							console.log(error.message)
							errorMessage = 'ra.auth.sign_in_error'
							break
						default:
							errorMessage = 'ra.notification.http_error'
							break
					}
					//return errorMessage
					return Promise.reject(new Error(errorMessage))
				
				}).catch(error => {
					console.log(error)
					var errorMessage = 'ra.notification.http_error'
					switch (error.message) {
						case 'Forbidden':
							console.log(error.message)
							errorMessage = 'ra.auth.sign_in_error'
							break
						default:
							errorMessage = 'ra.notification.http_error'
							break
					}
					//return errorMessage
					return Promise.reject(new Error(errorMessage))
				})
		}
		
	}

	 
	

 

	 
	 
	render() {
		const { classes, className, pictures, ...rest } = this.props
		console.log('callback')
		return (
			<div
				className={classnames(classes.main, className)}
				{...sanitizeRestProps(rest)}
			>
				<ImageGridList list={pictures} />

				<Card className={classes.card}>
					<div className={classes.avatar}>
						<Logo src={LogoPicture} classes={classes} />
					</div>
					<Container component="main" maxWidth="xs" style={{ width: "400px",padding:'20px' }}  >
						<CssBaseline />
						<Box
							sx={{
								marginTop: 2,
								marginBottom: 8,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>

							{
							<Box component="form"  noValidate sx={{ mt: 1 }} style={{ minWidth: '300px' }}>
								<CircularProgress thickness={5}/>
								{/* <div>Redirection .....</div> */}

							</Box>}
						</Box>


					</Container>

				</Card>
				<MyNotification />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	pictures: state.reducer ? state.reducer.Auth.pictures : null,
})

const mapDispatchToProps = dispatch => ({
	onLoadMosaic: (payload) =>
		dispatch({ type: AUTH_PAGE_LOADED, payload: payload }),

})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

const EnhancedReset = enhance(CallBack)

const CallBackWithTheme = props => (
	<ThemeProvider >
		<EnhancedReset {...props} />
	</ThemeProvider>
)

export default CallBackWithTheme


