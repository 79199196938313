import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose'
import { translate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CircularProgress, Typography, Accordion, AccordionSummary, AccordionDetails, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Tooltip } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { ExpandMore, History, Close, Search } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh'

import { CustomAlert, CustomCheckBox, CustomDisplayDialog } from '../layout/GlobalStyles';

import { Contacts, DateFormatter, Logger, DomainsItems } from '../../tools'
// import DomainsItems from '../../requests/DomainsItems';
import ImportexportRequest from '../../requests/ImportexportRequest';

import SearchListField from '../common/fields/SearchListField'
import SearchWidget from './SearchWidget'
import JobsComponent from './JobsComponent';
import CompaniesAlbums from './../../requests/CompaniesAlbums'
import CompaniesMovies from './../../requests/CompaniesMovies'
import { CheckIcon, CancelIcon } from './../../constants/icons'
import { Jobs } from '../../requests'
import { connect } from 'react-redux'
import { onExportJobs } from '../../actions/Jobs'
import ProgressExport from './ProgressExport';
const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},
	title: {
		display: 'inline-block',
		color: 'white',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		marginTop: '15px'
	},
	pageTitle: {
		fontSize: 20,
		color: 'black',
	},
	typesContainer: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		maxWidth: '100%',
		minHeight: '3em',
		alignItems: 'center',
	},
	borderBottom: {
		position: 'absolute',
		height: '100%',
		borderBottom: '1px solid #b3b3b3',
		left: '2%',
		right: '2%',
		// zIndex: '1',
	},
	typesLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		width: '35%',
		paddingLeft: "5px",
		// zIndex: '2',
	},
	typesContentContainer: {
		width: '79%',
		marginBottom: '10px',
		marginTop: '10px'
	},
	jobsContainer: {
		display: 'flex',
		width: '100%',
		maxWidth: '100%',
		minHeight: '3em',
		alignItems: 'stretch',
		border: '2px solid black',
		borderTop: '0px',
		borderRight: "0px solid black",
		"&:first-child": { borderTop: "2px solid black" },
		// "&:last-child":{borderRight: "0px solid black"}
		// "&:first-child > $labelContainer":{border: "0px"}
	},
	jobsLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		// height: '100%',
		minHeight: '3em',
		width: '20%',
		borderRight: "2px solid black",
		justifyContent: 'center',
		textAlign: 'center'
	},
	jobLabel: {
		fontSize: '1em',
		wordBreak: 'break-word',
		color: 'black',
	},
	label: {
		fontSize: '1em',
		// fontWeight: 'bold',
		// color: '#288DB1',
		color: 'black'
	},
	selectHistory: {
		marginLeft: '5px',
		marginRight: '5px',
		fontSize: '0.8em',
		verticalAlign: 'bottom'
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c'
	},
	buttonCancel: {
		width: '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	button: {
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		}
	},

})

const Importexport = (props) => {
	const { classes, onExportJobs, ExportJobs } = props
	const [expanded, setExpanded] = useState({
		operation: false,
		job: false
	});
	const [selected, setSelected] = useState({
		criteria: '1',
		value: '',
		id: '',
		title: '',
		type: ''
	});
	const [est, setEst] = useState({
		ESTstart: '',
		ESTend: ''
	})
	const [vod, setVod] = useState({
		VODstart: '',
		VODend: ''
	})
	const [img, setImg] = useState({
		id: '',
		title: '',
		filename: ''
	});
	const [data, setData] = useState([])
	const [jobs, setJobs] = useState([])
	const [jobsHistory, setJobsHistory] = useState([])
	const [searchbarLoading, setSearchbarLoading] = useState(false);
	const [listLoading, setListLoading] = useState(false);
	const [domainsItemsList, setDomainsItemsList] = useState([]);
	// const [properties, setProperties] = useState([]);
	const [radio, setRadio] = useState('album');
	const [renderImg, setRenderImg] = useState(false)
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: ' ',
		severity: ''
	});
	const [selectHistoryValue, setSelectHistoryValue] = useState('15')
	const [resultFound, setResultFound] = useState(false)
	const [openSearchListField, setOpenSearchListField] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [dialogData, setDialogData] = useState({
		refid: '',
		typeid: '',
	})
	const [selectedStartDate, setSelectedStartDate] = React.useState(new Date('2000-01-01T00:00:00'));
	// const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
	const [checkedJobs, setCheckedJobs] = useState([])
	const [validatecreateJob, setValidateCreateJobs] = useState(false)

	const [selectedAlbum, setAlbum] = useState(null)
	const [openExportProgress, setOpenExportProgress] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [listJobToAdd, setListJobToAdd] = useState('')

	const parser = new DOMParser()
	useEffect(() => {
		if (domainsItemsList.length === 0)
			getDomainsItems()

	})
	const getDomainsItems = () => {
		setListLoading(true)
		let items = DomainsItems && Object.values(DomainsItems.getItems(93))
		const jobList = items && items.filter(s => !s.disabled)
		setDomainsItemsList(jobList)
		setListLoading(false)

	}

	const getAlbum = async (value) => {
		setSearchbarLoading(true)
		setListLoading(true)
		await ImportexportRequest.getAll(value, 2).then((json) => {
			if (json.data && json.data.values.length > 0) {
				setSelected({ ...selected, id: json.data.values[0].id, title: json.data.values[0].title, type: radio })

				CompaniesAlbums.getAlbum(json.data.values[0].id).then((res) => {
					if (res.data) {
						setAlbum(res.data)
					}
				})
				setSearchbarLoading(false)
				setImg({ id: json.data.values[0].id, title: json.data.values[0].title, filename: json.data.values[0].filename })
				setRenderImg(true)
				setResultFound(true)
				setExpanded({ ...expanded, operation: true, job: true });
				setData(json.data.values)
			}
			else {
				if (selected.criteria === '1') {
					CompaniesAlbums.getAlbum(value).then((res) => {
						if (res.data) {
							setSelected({ ...selected, id: value, title: res.data.name, type: radio })
							setAlbum(res.data)
							setImg({ id: value, title: res.data.name })
						}
					}).catch((error) => {
						console.log(error)
						handleSnackbarOpen("Identifiant introuvable", "error")
						setSelected({ ...selected, id: '', title: '', type: '' })
						setVod({ VODstart: '', VODend: '' })
						setEst({ ESTstart: '', ESTend: '' })
						setSearchbarLoading(false)
						setImg({ id: '', title: '', filename: '' })
						setRenderImg(false)
						setResultFound(false)
					})
					setSearchbarLoading(false)
					setRenderImg(true)
					setResultFound(true)
					setExpanded({ ...expanded, operation: true, job: true })
				}

				setSearchbarLoading(false)

				//setData(json.data.values)
			}
		}).catch((error) => {
			console.log(error)
			handleSnackbarOpen("Identifiant introuvable", "error")
			setSelected({ ...selected, id: '', title: '', type: '' })
			setVod({ VODstart: '', VODend: '' })
			setEst({ ESTstart: '', ESTend: '' })
			setSearchbarLoading(false)
			setImg({ id: '', title: '', filename: '' })
			setRenderImg(false)
			setResultFound(false)
			setListLoading(false)
		})


		await ImportexportRequest.getJob(value, 2).then((json) => {
			setJobs(json.data.values)
			setListLoading(false)

		}).catch((error) => {
			console.log(error)
			setJobs([])
			setListLoading(false)

		})
	}



	const getMovie = async (value) => {
		setSearchbarLoading(true)
		await ImportexportRequest.getAll(value, 1).then((json) => {
			if (json.data) {
				setSelected({ ...selected, id: json.data.values[0].id, title: json.data.values[0].title, type: radio })
				setSearchbarLoading(false)
				setImg({ id: json.data.values[0].id, title: json.data.values[0].title, filename: json.data.values[0].filename })
				setRenderImg(true)
				setResultFound(true)
				setExpanded({ ...expanded, operation: true, job: true });
				setData(json.data.values)
			}
		}).catch((error) => {
			console.log(error)
			handleSnackbarOpen("Identifiant introuvable", "error")
			setSelected({ ...selected, id: '', title: '', type: '' })
			setVod({ VODstart: '', VODend: '' })
			setEst({ ESTstart: '', ESTend: '' })
			setSearchbarLoading(false)
			setImg({ id: '', title: '', filename: '' })
			setRenderImg(false)
			setResultFound(false)
		})

		await ImportexportRequest.getJob(value, 1).then((json) => {
			setJobs(json.data.values)
			setListLoading(false)
		}).catch((error) => {
			console.log(error)
			setJobs([])
			setListLoading(false)

		})
	}

	const getJobHistory = async (refid, typeid, amount, startdate, enddate) => {
		await ImportexportRequest.getJobHistory(refid, typeid, amount, startdate.toLocaleDateString('en-US'), enddate.toLocaleDateString('en-US')).then((json) => {
			console.log(json.data.values)
			setJobsHistory(json.data.values)
			setListLoading(false)
		}).catch((error) => {
			console.log(error)
			setJobsHistory([])
			setListLoading(false)

		})
	}

	const fetchXmlToObject = (data) => {
		const xml = parser.parseFromString(data, 'text/xml');

		// let disabled = xml.querySelector('PROP[NAME="DISABLED"]')
		// disabled = disabled.getAttribute('VALUE')
		// console.log(disabled)

		let prop = []
		prop = xml.querySelectorAll('PROP')
		let obj = {}
		prop.forEach(prop => {
			const xml = parser.parseFromString(prop, 'text/xml');
			if (xml) {
				const name = prop.getAttribute('NAME')
				const value = prop.getAttribute('VALUE')
				obj[name] = value
			}
		})
		// console.log(obj)
		return obj
	}

	const queryParameters = new URLSearchParams(window.location.hash)
	const type = queryParameters.get("type")
	const name = queryParameters.get("name")

	// useEffect( () => {
	// 	//console.log(checkedJobs)
	// }, [checkedJobs])
	useEffect(() => {
		let fromList = window.location.href.split('?')[1]
		if (fromList) {
			const originId = fromList.split('&')[0].replace('refid=', '')
			const object = fromList.split('&')[1].replace('objectid=', '')
			if (originId && object) {
				if (object == 1) {
					setRadio('movie')
					setSelected({ ...selected, value: originId })
					getMovie(originId)
				}
				if (object == 2) {
					setRadio('album')
					setSelected({ ...selected, value: originId })
					getAlbum(originId)
				}
			}
		}
	}, [window.location.href])

	useEffect(() => {
		setVod({ VODstart: '', VODend: '' })
		setEst({ ESTstart: '', ESTend: '' })
		data.map(data => {
			if (data.modecomm === 'VOD' && data.startdate != '0001-01-01T00:00:00.000' && data.enddate != '0001-01-01T00:00:00.000') {
				setVod({ VODstart: DateFormatter.getDayNameDateHourMin(data.startdate), VODend: DateFormatter.getDayNameDateHourMin(data.enddate) })
			}
		})
		data.map(data => {
			if (data.modecomm === 'EST' && data.startdate != '0001-01-01T00:00:00.000' && data.enddate != '0001-01-01T00:00:00.000') {
				setEst({ ESTstart: DateFormatter.getDayNameDateHourMin(data.startdate), ESTend: DateFormatter.getDayNameDateHourMin(data.enddate) })
			}
		})
	}, [data])

	// useEffect( () => {
	// 	domainsItemsList.map(data=>{
	// 		data.properties = fetchXmlToObject(data.properties)
	// 	})
	// 	var length = domainsItemsList.length - 1
	// 	while(length>=0){
	// 		if (domainsItemsList[length].properties.DISABLED === 1 || domainsItemsList[length].properties.DISABLED === '1' ) {
	// 			domainsItemsList.splice(length, 1);
	// 		  }

	// 		  length -= 1;
	// 	}
	// }, [domainsItemsList])

	const handlePanel = (panel) => {

		setExpanded({ ...expanded, [panel]: !expanded[panel] });

	};

	const handleRadioChange = (event) => {
		setRadio(event.target.value);
	};

	const handleSelectHistoryChange = (event) => {
		setSelectHistoryValue(event.target.value)
	}

	const setSelectedCriteria = (value) => {
		setSelected({ ...selected, criteria: value })
	}

	const setSelectedValue = (value) => {
		setSelected({ ...selected, value: value })
	}

	const splitComment = (string) => {
		if (string) {
			//Regexp pour séparer par "," ou ";" tout en gardant la ponctuation en fin de phrase
			var comment = string.split(/(?<=[,;])/)
			return (
				comment.map(value => {
					return (
						<p style={{ margin: 0 }}>{value}</p>
					)
				})
			)
		} else {
			return null
		}

	}

	const splitProperties = (string) => {
		var properties = fetchXmlToObject(string)
		var propertiesArray = []
		for (const [key, value] of Object.entries(properties)) {
			if (key.includes('FILENAME')) {
				var htmlString = key + " = <a target='_blank' href='" + process.env.REACT_APP_BO + "/Logs/" + selected.id + "/" + value + "'>" + value + "</a>"
				propertiesArray.push(htmlString);
			} else {
				propertiesArray.push(`${key} = ${value}`);
			}

		}
		return (
			propertiesArray.map(value => {
				return (
					<div dangerouslySetInnerHTML={{ __html: value }} style={{ margin: 0, marginBottom: '5px' }}></div>
				)
			})
		)
	}

	const handleSearch = (value) => {
		setCheckedJobs([])
		if (radio === 'movie' && value !== '' && selected.criteria === '1') {
			getMovie(selected.value)
		} else if (radio === 'album' && value !== '' && selected.criteria === '1') {
			getAlbum(selected.value)
		}
	}

	const handleClickOpenSearchListField = () => {
		setOpenSearchListField(true)
	}

	const handleClickCloseSearchListField = () => {
		setOpenSearchListField(false)
	}
	const Export = async () => {
		setValidateCreateJobs(false)
		setOpenExportProgress(true)	

		//setValidateCreateJobs(false)
		// let date = new Date()

		// //setValidateCreateJobs(false)
		// console.log(selectedAlbum)
		// let finalJobs = []
		// let promises = []

		// ExportJobs.forEach((elt) => {
		// 	elt.executiondate = date.toLocaleString("en-US")
		// 	console.log(elt)
		// 	if (elt.typeid === 12) {
		// 		console.log(elt.sfralbumType)
		// 		switch (elt.sfralbumType) {
		// 			case 0: {//album uniquement
		// 				let seriejob = {
		// 					typeid: elt.typeid,
		// 					refid: selectedAlbum.id,
		// 					xml: elt.xml,
		// 					picture: elt.picture,
		// 					executiondate: date.toLocaleString("en-US")
		// 				}
		// 				finalJobs.push(seriejob)
		// 				console.log(finalJobs)
		// 				break
		// 			}
		// 			case 1: {//'Toutes les saisons'
		// 				//let albumparent = selectedAlbum
		// 				if (selectedAlbum.typeid === 5) {
		// 					let promise = CompaniesAlbums.getAlbum(selectedAlbum.parentid).then((res) => {
		// 						if (res.data)
		// 							CompaniesAlbums.getAlbumsChilds(res.data.id).then((data) => {
		// 								data.data && data.data.forEach((season) => {
		// 									//console.log(data.data)
		// 									let newjob = {
		// 										typeid: elt.typeid,
		// 										refid: season.id,
		// 										xml: elt.xml,
		// 										picture: elt.picture,
		// 										executiondate: date.toLocaleString("en-US")
		// 									}
		// 									finalJobs.push(newjob)
		// 								})
		// 							}
		// 							)
		// 					})
		// 					promises.push(promise)
		// 				}

		// 				break
		// 			}
		// 			case 2: {// Série + saison(4,5)  et pack + programme(2)
		// 				if (selectedAlbum.typeid === 2) {
		// 					let seriejob = {
		// 						typeid: elt.typeid,
		// 						refid: selectedAlbum.id,
		// 						xml: elt.xml,
		// 						picture: elt.picture,
		// 						executiondate: date.toLocaleString("en-US")
		// 					}
		// 					finalJobs.push(seriejob)
		// 					let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
		// 						if (res.data) {

		// 							//listjobs = listjobs.filter(s=>s.typeid !== 11)
		// 							res.data.forEach((item) => {
		// 								if (item.movie.id !== 0) {
		// 									let progjob = {
		// 										typeid: 11,
		// 										refid: item.movie.id,
		// 										xml: elt.xml,
		// 										picture: elt.picture,
		// 										executiondate: date.toLocaleString("en-US")
		// 									}
		// 									finalJobs.push(progjob)
		// 								}
		// 							})
		// 						}
		// 					})
		// 					promises.push(promise)
		// 				} else {
		// 					let albumparent = selectedAlbum
		// 					if (selectedAlbum && selectedAlbum.typeid === 5) {
		// 						let promise = CompaniesAlbums.getAlbum(selectedAlbum.parentid).then((res) => {
		// 							if (res.data) {
		// 								albumparent = res.data
		// 								let seriejob = {
		// 									typeid: elt.typeid,
		// 									refid: albumparent.id,
		// 									xml: elt.xml,
		// 									picture: elt.picture,
		// 									executiondate: date.toLocaleString("en-US")
		// 								}
		// 								finalJobs.push(seriejob)
		// 								if (albumparent && albumparent.typeid === 4) {
		// 									let promise = CompaniesAlbums.getAlbumsChilds(albumparent.id).then((res) => {
		// 										if (res.data) {
		// 											console.log(res.data)
		// 											let seasons = res.data
		// 											seasons.forEach((season) => {
		// 												let newjob = {
		// 													typeid: elt.typeid,
		// 													refid: season.id,
		// 													xml: elt.xml,
		// 													picture: elt.picture,
		// 													executiondate: date.toLocaleString("en-US")
		// 												}
		// 												finalJobs.push(newjob)
		// 											})
		// 										}
		// 									}).then(() => {
		// 										console.log(finalJobs)
		// 									})
		// 								}
		// 							}
		// 						})
		// 						promises.push(promise)
		// 						//onExportJobs(finalJobs)
		// 					}

		// 				}


		// 				break
		// 			}
		// 			case 3: {//  programme
		// 				if (selectedAlbum.typeid === 2 || selectedAlbum.typeid === 5) {
		// 					let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
		// 						if (res.data) {
		// 							//listjobs = listjobs.filter(s=>s.typeid !== 11)
		// 							res.data.forEach((item) => {
		// 								if (item.movie.id !== 0) {
		// 									let progjob = {
		// 										typeid: 11,
		// 										refid: item.movie.id,
		// 										xml: elt.xml,
		// 										picture: elt.picture,
		// 										executiondate: date.toLocaleString("en-US")
		// 									}
		// 									finalJobs.push(progjob)
		// 								}
		// 							})
		// 						}
		// 					})
		// 					console.log(promise)
		// 					promises.push(promise)
		// 					// 		if(album.typeid === 5){

		// 				}


		// 				break
		// 			}
		// 		}

		// 	}
		// 	else if (elt.typeid === 28) {
		// 		let promise = CompaniesMovies.getMoviesBddpByAlbumId(selectedAlbum.id).then(movies => {
		// 			if (!movies.data) return
		// 			if (movies.data && movies.data.companiesmovies) {
		// 				movies.data.companiesmovies.forEach(movie => {
		// 					if (movie.idbddp) {
		// 						let newjob = {
		// 							typeid: elt.typeid,
		// 							refid: movie.movieId,
		// 							executiondate: date.toLocaleString("en-US"),
		// 							idbddp: movie.idbddp
		// 						}
		// 						finalJobs.push(newjob)
		// 					}
		// 				})

		// 			}
		// 		})
		// 		promises.push(promise)

		// 	}
		// 	else if (elt.typeid === 29) {

		// 	}
		// 	else if (elt.typeid === 30) {
		// 		console.log(elt)
		// 		if (elt.imgtype !== '') {
		// 			if (elt.bddpAlbum === 0 || elt.bddpAlbum === 1) {
		// 				let newjob = {
		// 					typeid: elt.typeid,
		// 					refid: selectedAlbum.id,
		// 					imgtype: elt.imgtype,
		// 					executiondate: date.toLocaleString("en-US")
		// 				}
		// 				finalJobs.push(newjob)
		// 				console.log(finalJobs)

		// 			}
		// 			if (elt.bddpAlbum === 0 || elt.bddpAlbum === 2) {
		// 				let promise = CompaniesAlbums.getAlbumMoviesbyIds(selectedAlbum.id).then(res => {
		// 					console.log(res.data)
		// 					if (res.data) {
		// 						res.data && res.data.forEach((movie => {
		// 							let newjob = {
		// 								typeid: 27,
		// 								refid: movie.movie.id,
		// 								imgtype: elt.imgtype,
		// 								executiondate: date.toLocaleString("en-US")
		// 							}

		// 							finalJobs.push(newjob)

		// 						}))

		// 					}
		// 				}).then(() => {
		// 					console.log(finalJobs)
		// 				})
		// 				promises.push(promise)
		// 			}
		// 		}
		// 	}
		// 	else if(elt.typeid === 8 || elt.typeid === 37){
		// 		let addjob = {
		// 			typeid: elt.typeid,
		// 			refid: selectedAlbum.id,
		// 			executiondate: date.toLocaleString("en-US")
		// 		}
		// 		finalJobs.push(addjob)
		// 	}
		// })

		// Promise.all(promises).then(() => {
		// 	console.log("Tous les jobs sont terminés");
		// 	console.log(finalJobs)
		// 	setListJobToAdd(finalJobs)
		// 	setOpenExportProgress(true)	
		// 	setValidateCreateJobs(false)
		// 	})
		//console.log(finalJobs)
		// ExportJobs.forEach((elt)=>{
		// 	return new Promise((resolve, reject) => {
		// 		console.log(elt)
		// 		Jobs.addJobs(elt).then(
		// 			(json) => {
		// 				Logger.log(json)
		// 				console.log(json)

		// 				handleSnackbarOpen('Job ajouté','success')
		// 				setValidateCreateJobs(false)
		// 				ImportexportRequest.getJob(selected.id, selected.type == 'movie' ? 1 : 2).then((json) => {
		// 					setJobs(json.data.values)
		// 				}).catch((error)=>{
		// 					console.log(error)
		// 					setJobs([])
		// 				})
		// 				resolve()
		// 			}
		// 		).catch((error)=>{
		// 			Logger.debug(error)
		// 			setValidateCreateJobs(false)
		// 			reject()
		// 		})
		// 	})
		// })




	}

	const SaveAlbumsJobs = () => {
		if (checkedJobs.find(s => s === 30) && !ExportJobs.find(s => s.typeid === 30)) {
			setOpenError(true)
			setErrorMessage("Veuillez selectionner un type d'image pour l'export album image BDDP ")
			// }
			// if(ExportJobs.length ===0){
			// 	setErrorMessage("Veuillez sélectionner un type d'export ")

		} else
			setValidateCreateJobs(true)
		// if (checkedJobs){
		// 	let date = new Date()

		// 	checkedJobs.forEach((elt)=>
		// 		{Export(ExportJobs)}
		// 	)

		// 	// 		if(elt === 8 || elt === 37){
		// 	// 			let listjobs =[]
		// 	// 			let job ={
		// 	// 				typeid : elt,
		// 	// 				refid : selected.id,
		// 	// 				executiondate : date.toLocaleString("en-US")
		// 	// 			}
		// 	// 			listjobs.push(job)
		// 	// 			Export(listjobs)				
		// 	// 		}
		// 	// 		if(elt === 12 || elt === 30){
		// 	// 			Export(ExportJobs)
		// 	// 		}
		// 	// 		if(elt === 28){

		// 	// 		let listJobs =[]
		// 	// 		CompaniesMovies.getMoviesBddpByAlbumId(selected.id).then(movies =>{
		// 	// 			if(!movies.data) return
		// 	// 			if(movies.data && movies.data.companiesmovies  ){
		// 	// 				movies.data.companiesmovies.forEach(movie => {
		// 	// 					if(movie.idbddp)
		// 	// 					{	 
		// 	// 						let job ={
		// 	// 							typeid : elt,
		// 	// 							refid : movie.movieId,
		// 	// 							executiondate : date.toLocaleString("en-US"),
		// 	// 							idbddp : movie.idbddp
		// 	// 						}
		// 	// 						listJobs.push(job)
		// 	// 					}
		// 	// 				})
		// 	// 				Export(listJobs)
		// 	// 			}
		// 	// 		})

		// 	// 		}

		// 	// }
		// 	// )

		// }
	}
	const RefreshJobs = (event) => {
		event.stopPropagation()
		ImportexportRequest.getJob(selected.id, selected.type == 'movie' ? 1 : 2).then((json) => {
			console.log(json.data.values)
			setJobs(json.data.values)
			setListLoading(false)
		}).catch((error) => {
			console.log(error)
			setJobs([])
			setListLoading(false)

		})
	}
	//Gestion de la modal d'extra details par job
	const handleOpenDialog = (refid, typeid, amount, startdate, enddate) => {
		setOpenDialog(true)
		setDialogData({ ...dialogData, refid: refid, typeid: typeid })
		getJobHistory(refid, typeid, amount, startdate, enddate)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleMouseDown = (event) => {
		event.preventDefault()
	}

	const handleStartDateChange = (date) => {
		setSelectedStartDate(date)
	}


	const handleEndDateChange = (date) => {
		setSelectedEndDate(date)
	}

	//Gestion de la snackbar d'erreur
	const handleSnackbarOpen = (message, severity) => {
		setSnackbar({ ...snackbar, open: true, message: message, severity: severity });
	};
	const handleSnackbarClose = () => {
		setSnackbar({ ...snackbar, open: false });
	};
	const { vertical, horizontal, open, message } = snackbar;

	const openInNewTab = (url) => {
		window.open(url, "_blank");
	};
	const onCloseExportprogress = () =>{
		onExportJobs([])
		setCheckedJobs([])
		setOpenExportProgress(false)
	}
 	const handleCheckJobs = (e, check, jobid) => {
		console.log(checkedJobs)
		//let listJobs = checkedJobs
 		if (checkedJobs.includes(jobid)) {
			setCheckedJobs(checkedJobs.filter(id => id !== jobid));

			// console.log(checkedJobs)
			// setCheckedJobs(listJobs)
			// onExportJobs(listjobs)
		} else {
			let listjobs = ExportJobs.filter(job => job.typeid !== jobid)
			let jobtoadd = { typeid: jobid, refid: selectedAlbum.id }
			if (jobid === 8 || jobid === 28 || jobid === 37) {
				//listjobs = ExportJobs
				listjobs.push(jobtoadd)
			}
			console.log(listjobs)
			onExportJobs(listjobs)
			setCheckedJobs([...checkedJobs, jobid])

		}
	}
	console.log(checkedJobs)
	const AddButton = withStyles((theme) => ({
		root: {
			color: 'white',
			backgroundColor: '#288DB1',
			'&:hover': {
				backgroundColor: '#1C627B',
			},
		},
	}))(Button);


	var renderOperationTypes
	if (selected.type == 'movie') {
		renderOperationTypes =
			domainsItemsList.map(value => {
				if (value.properties.FILTER == 'M') {
					return (
						<div className={classes.typesContainer}>
							<div className={classes.borderBottom}>
							</div>
							<div className={classes.typesLabelContainer}>
								<div className={classes.label}>
									{value.label} ({value.itemid})
								</div>
							</div>
							<div className={classes.typesContentContainer}>
								<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
									TEST
								</AddButton>
								<br />
								<AddButton variant="contained" size="small" style={{ marginRight: '10px' }}>
									TEST
								</AddButton>
							</div>

						</div>
					)
				}
			})
	} else if (selected.type == 'album') {
		renderOperationTypes =
			domainsItemsList.map(value => {
				if ((value.filter == 'A' && value.itemid !== 5 && value.itemid !== 7 && value.itemid !== 33 && value.itemid !== 4 && value.itemid !== 37 && value.itemid !==12)
					|| (value.itemid === 12 && selectedAlbum && selectedAlbum.typeid !== 1 && selectedAlbum.typeid !== 3)
					|| (value.itemid === 4 && selectedAlbum && selectedAlbum.typeid === 4)
					|| (value.itemid === 37 && selectedAlbum && selectedAlbum.typeid !== 4 && selectedAlbum.typeid !== 3)
					//|| (value.itemid === 15) ( a voir si on utilise encore ou pas)
					|| (value.itemid === 28)) {
					return (
						<div className={classes.typesContainer}>
							<div className={classes.borderBottom}>
							</div>
							<div className={classes.typesLabelContainer}>
								<div className={classes.label}>
									<CustomCheckBox type='export' onChange={(e, check) => handleCheckJobs(e, check, value.itemid)} value={checkedJobs.includes(value.itemid)}
										// 	disabled={((value.itemid === 12 && selectedAlbum.typeid !== 4 && selectedAlbum.typeid!==5 && selectedAlbum.typeid!==2)
										// 	|| (value.itemid === 28 && img.filename && img.filename ==='')
										// 	|| (value.itemid === 30 && (!selectedAlbum.bddpId || selectedAlbum.bddpId ==='' ))
										// 	//|| (value.itemid === 37 && selectedAlbum.typeid === 4)
										// )}
										label={`${value.label} (${value.itemid})`} labelPlacement='end' color='#000' />
									{value.itemid === 15 &&
										<AddButton variant="contained" disabled={!checkedJobs.includes(15)} size="small" style={{ marginLeft: '50px', marginBottom: '5px' }}>
											Voir la liste des épisodes
										</AddButton>}
								</div>
							</div>
							<div className={classes.typesContentContainer}>
								{selectedAlbum && <JobsComponent job={value} listcheckedJobs={checkedJobs} album={selectedAlbum} images={img} onExportJobs={onExportJobs} ExportJobs={ExportJobs} />}
								<br />

							</div>
						</div>
					)
				}
			})
	}


	var renderJobs =
		jobs.map(value => {
			return (
				<div className={classes.jobsContainer}>
					<div className={classes.jobsLabelContainer} style={{ width: '4%' }}>
						<div className={classes.jobLabel}>
							<IconButton
								// onClick={()=>{handleOpenDialog(value.refid, value.typeid, 15, selectedStartDate, selectedEndDate)}}
								onClick={() => { openInNewTab(`${process.env.PUBLIC_URL}/#/JobHistory?refid=${value.refid}&typeid=${value.typeid}`) }}
								onMouseDown={handleMouseDown}
								size="small"
								style={{ verticalAlign: "middle" }}
							>
								<Tooltip title='Historique'>
									<History />
								</Tooltip>
							</IconButton>
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '10%' }}>
						<div className={classes.jobLabel}>
							{value.type} ({value.typeid})
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{value.jobid}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%', backgroundColor: value.state == 'En attente' ? 'lightgray' : value.state == 'Traité' ? 'green' : value.state == 'En cours' ? 'orange' : 'red' }}>
						<div className={classes.jobLabel}>
							{value.state}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '20%' }}>
						<div className={classes.jobLabel}>
							{splitComment(value.comment)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.executiondate)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{Contacts.getFormattedLabel(value.createdby)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.createdon)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{Contacts.getFormattedLabel(value.modifiedby)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.modifiedon)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '6%' }}>
						<div className={classes.jobLabel}>
							{value.servername}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '25%', justifyContent: 'flex-start', textAlign: 'start' }}>
						<div className={classes.jobLabel}>
							{splitProperties(value.properties)}
						</div>
					</div>
				</div>
			)
		})


	var renderJobsHistory =
		jobsHistory.map(value => {
			return (
				<div className={classes.jobsContainer} style={{ width: '99.5%' }}>
					<div className={classes.jobsLabelContainer} style={{ width: '10%' }}>
						<div className={classes.jobLabel}>
							{value.type} ({value.typeid})
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{value.jobid}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%', backgroundColor: value.state == 'En attente' ? 'lightgray' : value.state == 'Traité' ? 'green' : value.state == 'En cours' ? 'orange' : 'red' }}>
						<div className={classes.jobLabel}>
							{value.state}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '20%' }}>
						<div className={classes.jobLabel}>
							{splitComment(value.comment)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.executiondate)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{Contacts.getFormattedLabel(value.createdby)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.createdon)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{Contacts.getFormattedLabel(value.modifiedby)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
						<div className={classes.jobLabel}>
							{DateFormatter.getDayNameDateHourMinSec(value.modifiedon)}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '6%' }}>
						<div className={classes.jobLabel}>
							{value.servername}
						</div>
					</div>
					<div className={classes.jobsLabelContainer} style={{ width: '28%', justifyContent: 'flex-start', textAlign: 'start' }}>
						<div className={classes.jobLabel}>
							{splitProperties(value.properties)}
						</div>
					</div>
				</div>
			)
		})

	var searchListField = null
	switch (radio) {
		case 'movie':
			searchListField =
				<SearchListField
					open={openSearchListField}
					close={handleClickCloseSearchListField}
					exceptionItem={{}}
					defaultValue={selected.value}
					getMovie={getMovie}
					Title='Recherche de programmes'
					// getFavoriteMovies={props.getFavoriteMovies}
					TypeItem='Movies'
					multiSelect={false}
					source='GetMovieId'
				/>
			break;
		case 'album':
			searchListField =
				<SearchListField
					open={openSearchListField}
					close={handleClickCloseSearchListField}
					exceptionItem={{}}
					defaultValue={selected.value}
					getAlbum={getAlbum}
					Title="Recherche d'albums"
					// getFavoriteAlbums={props.getFavoriteAlbums}
					TypeItem='Albums'
					multiSelect={false}
					source='GetAlbumId'
				/>
			break;
	}
	var listJobsTocreate = () => {
		return checkedJobs.forEach((jb) =>
			<div>
				<li><b>	 {domainsItemsList.filter(s => s.itemid === jb.itemid)[0] && domainsItemsList.filter(s => s.itemid === jb.itemid)[0].label}</b> </li><br />
			</div>
		)
	}
	return (
		<>
			<SearchWidget
				vod={vod}
				est={est}
				criteria={selected.criteria}
				value={selected.value}
				id={selected.id}
				title={selected.title}
				type={selected.type}
				setSelectedCriteria={setSelectedCriteria}
				setSelectedValue={setSelectedValue}
				radio={radio}
				handleRadioChange={handleRadioChange}
				handleSearch={handleSearch}
				searchbarLoading={searchbarLoading}
				renderImg={renderImg}
				img={img}
				handleClickOpenSearchListField={handleClickOpenSearchListField}
			/>


			<Card>
				<CardContent>
					{validatecreateJob && <CustomDisplayDialog info='Alerte' type='window' open={validatecreateJob}
						handleClose={() => setValidateCreateJobs(false)} handleSave={() => Export()} maxWidth='200px'
						content={<ul>Vous êtes sur le point de créer les jobs :<br /><br />
							<div>
								{checkedJobs.map((jb) => {
									const item = domainsItemsList.find(s => s.itemid === jb);
									return item ? (
										<div key={jb}>
											<li><b>{item.label}</b></li><br />
										</div>
									) : null;
								})}
							</div>
							pour l'album <b>{selected.title} ({selected.id})</b>, Voulez vous continuer ?</ul>}
					/>}
					{openError && <CustomDisplayDialog info="ERREURS" handleClose={() => setOpenError(false)} open={openError} content={errorMessage} width="sm" />}


					{
						openExportProgress && <CustomDisplayDialog open={openExportProgress} title={'Création des opérations en cours...'}
							handleClose={() => onCloseExportprogress()} maxWidth='150px'
							content={<ProgressExport ExportJobs={ExportJobs} selectedAlbum={selectedAlbum} />}
						/>
					}


					{radio === 'album' && <Accordion square expanded={expanded.operation} onChange={() => { handlePanel('operation') }} style={{ marginBottom: '10px', marginTop: '0px' }}>
						<AccordionSummary
							style={{ backgroundColor: "#288DB1" }}
							expandIcon={<ExpandMore style={{ color: "white" }} />} >
							<Typography className={classes.title}>
								TYPES D'OPÉRATIONS
							</Typography>
						</AccordionSummary>
						<AccordionDetails style={{ padding: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
							{listLoading == true ? <CircularProgress size={50} thickness={3} /> :
								<>
									{renderOperationTypes}
								</>
							}
							<div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }} >
								{/* onClick={e => this.validateSelection(e)} className={classes.buttonCheck} */}
								{/* || (!checkedJobs.find(s=>s === 8) && !checkedJobs.find(s=>s === 12)) */}
								<Button color="primary" variant="contained" disabled={checkedJobs.length === 0} onClick={() => SaveAlbumsJobs()} className={classes.buttonCheck}>
									<CheckIcon className={classes.leftIcon} />
									Valider
								</Button>
								<Button color="secondary" variant="contained" className={classes.buttonCancel} onClick={() => setCheckedJobs([])}>
									<CancelIcon className={classes.leftIcon} />
									Annuler
								</Button>
							</div>
						</AccordionDetails>
					</Accordion>}

					<Accordion square expanded={expanded.job} onChange={() => { handlePanel('job') }} style={{ marginBottom: '10px', marginTop: '0px' }}>
						<AccordionSummary
							style={{ backgroundColor: "#288DB1" }}
							expandIcon={<ExpandMore style={{ color: "white" }} />} >
							<Typography className={classes.title}>
								JOBS
							</Typography>
							<IconButton style={{ marginLeft: '95%', color: 'white' }} disabled={selected.id === ''}
								onClick={(event) => RefreshJobs(event)}
								//onClick={(event) => event.stopPropagation()}
								onFocus={(event) => event.stopPropagation()}
							>
								<Tooltip title='Actualiser'>
									<RefreshIcon />
								</Tooltip>
							</IconButton>

						</AccordionSummary>
						<AccordionDetails style={{ padding: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
							{listLoading == true ? <CircularProgress size={50} thickness={3} /> :
								<>
									<div className={classes.jobsContainer}>
										<div className={classes.jobsLabelContainer} style={{ width: '4%' }}>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '10%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Type
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												JobId
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Status
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '20%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Commentaire
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Exécution le
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Créé par
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Créé le
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Modifié par
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Modifié le
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '6%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Serveur
											</div>
										</div>
										<div className={classes.jobsLabelContainer} style={{ width: '25%' }}>
											<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
												Propriété
											</div>
										</div>
									</div>
									{renderJobs}
								</>
							}
						</AccordionDetails>
					</Accordion>


				</CardContent>
			</Card>
			{searchListField}







			{/* Popup de l'historique des jobs */}
			<Dialog
				fullWidth={true}
				maxWidth="xl"
				scroll="paper"
				fullScreen
				disableRestoreFocus
				open={openDialog}
				onClose={handleCloseDialog}
			>
				<DialogTitle id="max-width-dialog-title" style={{ fontSize: '1em' }}>
					Affichage
					<Select
						value={selectHistoryValue}
						onChange={handleSelectHistoryChange}
						displayEmpty
						className={classes.selectHistory}
						inputProps={{ 'aria-label': 'Without label' }}
					>
						<MenuItem value={15}>des 15 derniers éléments</MenuItem>
						<MenuItem value={25}>des 25 derniers éléments</MenuItem>
						<MenuItem value={0}>de tout l'historique</MenuItem>
					</Select>
					du
					<MuiPickersUtilsProvider utils={DateFnsUtils} style={{ verticalAlign: 'bottom' }}>
						<KeyboardDatePicker
							label="Date de début"
							format="dd/MM/yyyy"
							value={selectedStartDate}
							onChange={handleStartDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							style={{ marginLeft: "5px", marginRight: '5px', verticalAlign: 'bottom' }}
						/>
						au
						<KeyboardDatePicker
							label="Date de fin"
							format="dd/MM/yyyy"
							value={selectedEndDate}
							onChange={handleEndDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							style={{ marginLeft: "5px", marginRight: '10px', verticalAlign: 'bottom' }}
						/>
					</MuiPickersUtilsProvider>
					<IconButton
						onClick={() => { getJobHistory(dialogData.refid, dialogData.typeid, selectHistoryValue, selectedStartDate, selectedEndDate) }}
						onMouseDown={handleMouseDown}
						size="small"
						style={{ verticalAlign: 'bottom' }}
					>
						<Search />
					</IconButton>
					<IconButton
						onClick={() => { handleCloseDialog() }}
						onMouseDown={handleMouseDown}
						size="small"
						style={{ float: 'right' }}
					>
						<Close style={{ color: '#D32F2F' }} />
					</IconButton>
				</DialogTitle>
				<DialogContent style={{ width: '97vw' }}>
					<div className={classes.jobsContainer} style={{ width: '99.5%' }}>
						<div className={classes.jobsLabelContainer} style={{ width: '10%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Type
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								JobId
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Status
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '20%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Commentaire
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Exécution le
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Créé par
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Créé le
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Modifié par
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '5%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Modifié le
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '6%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Serveur
							</div>
						</div>
						<div className={classes.jobsLabelContainer} style={{ width: '28%' }}>
							<div className={classes.jobLabel} style={{ fontWeight: 'bold' }}>
								Propriété
							</div>
						</div>
					</div>
					{renderJobsHistory}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} style={{ color: '#D32F2F' }}>
						Fermer
					</Button>
				</DialogActions>
			</Dialog>
			<CustomAlert libelle={snackbar.message} severity={snackbar.severity} open={open} setOpen={handleSnackbarClose} />
		</>
	)
}
const mapStateToProps = state => ({
	...state,
	ExportJobs: state.reducer.jobs.ExportJobs !== null ? state.reducer.jobs.ExportJobs : [],


})

const mapDispatchToProps = ({
	onExportJobs
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)


export default translate(enhance(Importexport))