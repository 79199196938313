import React from 'react'
import { Admin, Resource } from 'react-admin'
import dataProvider from './dataProvider'
import Dashboard from './Dashboard'
import authProvider from './authProvider'
import { MyLayout } from './components/layout'
import {  Login as MyLoginForm } from './components/auth'
import {  PictureIcon, ContentIcon, PeopleIcon, TransferRightIcon, AlbumIcon, CatalogIcon, WatcherLaterIcon, JobsIcon, EditorialIcon, MovieIcon, ProductIcon, TransferIcon, DomainIcon, PriorityHighIcon, EuroIcon, SettingsIcon, MonetizationOnIcon, CloudUploadIcon, EventNoteIcon, EditIcon ,LoyaltyIcon, AttachMoneyIcon,RepeatIcon } from './constants/icons'
import { Procs } from './constants/procs'

// Resources
import { WatcherList } from './components/watcher'
import { JobsList } from './components/supervision/jobs'
import { CompaniespeoplesList, CompaniespeoplesShow, CompaniespeoplesEdit, CompaniespeoplesCreate } from './components/companiespeoples'
import { PeopleseditorialsShow, PeopleseditorialsEdit, PeopleseditorialsCreate } from './components/companiespeoples/peopleseditorials'
import { PeoplespicturesList } from './components/companiespeoples/peoplespictures'
import { PeoplesmoviesList } from './components/companiespeoples/peoplesmovies'
import { CompaniesmoviesList, CompaniesmoviesShow, CompaniesmoviesEdit, CompaniesmoviesCreate } from './components/companiesmovies'
import { MovieseditorialsShow, MovieseditorialsEdit, MovieseditorialsCreate } from './components/companiesmovies/movieseditorials'
import { MoviespicturesList, MoviespicturesEdit } from './components/companiesmovies/moviespictures'
import { MoviescontentsList, MoviescontentsEdit, MoviescontentsCreate } from './components/companiesmovies/moviescontents'
import { CompaniesalbumsList, CompaniesalbumsShow, CompaniesalbumsEdit, CompaniesalbumsCreate } from './components/companiesalbums'
import { CompaniesproductsList, CompaniesproductsShow, CompaniesproductsEdit, CompaniesproductsCreate } from './components/companiesproducts'
import { CompaniestransfersList, CompaniestransfersShow, CompaniestransfersEdit, CompaniestransfersCreate } from './components/companiestransfers'
import { TransfersrightsShow, TransfersrightsEdit, TransfersrightsCreate } from './components/companiestransfers/transfersrights'
import { CatalogList } from './components/catalogbase/list'
import { DomainsitemsList, DomainsitemsShow } from './components/domainsitems'
import { MoviesprioritiesList, MoviesprioritiesShow, MoviesprioritiesEdit, MoviesprioritiesCreate } from './components/moviespriorities'
import { ConfigurationShow, ConfigurationEdit, ConfigurationList } from './components/configurationmanager'
import { PricingTool } from './components/transfersrightsprices'
import { PricePlanification } from './components/priceplanification'
import  { MyDropzoneArea } from './components/tools'
import { Planning, PreparationBoard } from './components/publicationtracking'
import { PromotionsList, PromotionsShow, PromotionsEdit, PromotionsCreate } from './components/promotions'
import { Pricingscodes } from './components/pricingscodes'
import { Importexport } from './components/importexport'
import { JobHistory } from './components/importexport'

// Customs
import customRoutes from './customRoutes'
import i18nProvider from './i18nProvider'
import reducer from './reducers'
import themeReducer from './reducers/theme'
import { userHasRight } from './userrights/validate'
import IdleLayout from './IdleLayout'
import { MyFooter } from './components/layout'
import { Logger, Token } from './tools'
import JssProvider from 'react-jss/lib/JssProvider'
import { generateClassName } from './generateClassName'
 

// import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
// import { OktaAuth } from '@okta/okta-auth-js';
// import { useHistory } from 'react-router-dom';
// const oktaAuth = new OktaAuth({
//     issuer: 'https://mylogin.canal-plus.com/app/exkcm9laftXBS7Agk417/sso/saml/metadata',// 'https://mylogin.canal-plus.com/oauth2/default',
//     //clientId: '{yourClientId}',
//     redirectUri: process.env.REACT_APP_AUTH  + '/callback',
// });

// let  history  
// const customAuthHandler = () => {
//     history.push(process.env.PUBLIC_URL + '/#/login');
// };
 
const hasRight = async (permissions, procid) => {
    return  await userHasRight(permissions, procid)
}

class App extends React.Component {
	constructor(props){
		super(props)
		const url = window.location.origin
		const params = new URLSearchParams(url.search);
        console.log(props)
		console.log(url)
		Logger.info('url')
		Logger.info(url)
		if (!url.includes('localhost') && !url.includes('https')) {
			window.location = `https:${url.split(':')[1]}`
		}
		
	}
	render(){
		return(
			<React.Fragment>
				<JssProvider generateClassName={generateClassName}>
				<Admin
					locale="fr"
					i18nProvider={i18nProvider}
					customReducers={{ reducer, theme: themeReducer }}
					customRoutes={customRoutes}
					appLayout={MyLayout}
					authProvider={authProvider}
					dashboard={Dashboard}
					loginPage={MyLoginForm}
					dataProvider={dataProvider}

				>
 					{permissions => [
						hasRight(permissions, Procs.MENU3_MOVIES_READ) ?
							<Resource name="Companiesmovies"
								list={CompaniesmoviesList}
								show={hasRight(permissions, Procs.MENU3_MOVIES_READ) ? CompaniesmoviesShow : null}
								create={hasRight(permissions, Procs.MENU3_MOVIES_WRITE) ? CompaniesmoviesCreate : null}
								edit={hasRight(permissions, Procs.MENU3_MOVIES_WRITE) ? CompaniesmoviesEdit : null}
								icon={MovieIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_MOVIESEDITO_READ) ?
							<Resource name="Movieseditorials"
								show={hasRight(permissions, Procs.MENU3_MOVIESEDITO_READ) ? MovieseditorialsShow : null}
								edit={hasRight(permissions, Procs.MENU3_MOVIESEDITO_WRITE) ? MovieseditorialsEdit : null}
								create={hasRight(permissions, Procs.MENU3_MOVIESEDITO_WRITE) ? MovieseditorialsCreate : null}
								icon={EditorialIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_MOVIESPICTURES_READ) ?
							<Resource name="Moviespictures"
								list={MoviespicturesList}
								edit={hasRight(permissions, Procs.MENU3_MOVIESPICTURES_WRITE) ? MoviespicturesEdit : null}
								icon={PictureIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_MOVIESCONTENTS_READ) ?
							<Resource name="Moviescontents"
								list={MoviescontentsList}
								edit={hasRight(permissions, Procs.MENU3_MOVIESCONTENTS_WRITE) ? MoviescontentsEdit : null}
								create={hasRight(permissions, Procs.MENU3_MOVIESCONTENTS_WRITE) ? MoviescontentsCreate : null}
								icon={ContentIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_PEOPLES_READ) ?
							<Resource name="Companiespeoples"
								list={CompaniespeoplesList}
								show={hasRight(permissions, Procs.MENU3_PEOPLES_READ) ? CompaniespeoplesShow : null}
								create={hasRight(permissions, Procs.MENU3_PEOPLES_WRITE) ? CompaniespeoplesCreate : null}
								edit={hasRight(permissions, Procs.MENU3_PEOPLES_WRITE) ? CompaniespeoplesEdit : null}
								icon={PeopleIcon} /> : null,

						hasRight(permissions, Procs.MENU3_PEOPLESEDITO_READ) ?
							<Resource name="Peopleseditorials"
								show={hasRight(permissions, Procs.MENU3_PEOPLESEDITO_READ) ? PeopleseditorialsShow : null}
								edit={hasRight(permissions, Procs.MENU3_PEOPLESEDITO_WRITE) ? PeopleseditorialsEdit : null}
								create={hasRight(permissions, Procs.MENU3_PEOPLESEDITO_WRITE) ? PeopleseditorialsCreate : null}
								icon={EditorialIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_PEOPLES_READ) ?
							<Resource name="Peoplesmovies"
								list={PeoplesmoviesList}
								icon={MovieIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_PEOPLESPICTURES_READ) ?
							<Resource name="Peoplespictures"
								list={PeoplespicturesList}
								icon={PictureIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_PRODUCTS_READ) ?
							<Resource name="Companiesproducts"
								list={CompaniesproductsList}
								show={hasRight(permissions, Procs.MENU3_PRODUCTS_READ) ? CompaniesproductsShow : null}
								create={hasRight(permissions, Procs.MENU3_PRODUCTS_WRITE) ? CompaniesproductsCreate : null}
								edit={hasRight(permissions, Procs.MENU3_PRODUCTS_WRITE) ? CompaniesproductsEdit : null}
								icon={ProductIcon} />
							: null,

						hasRight(permissions, Procs.MENU4_TRANSFERS_READ) ?
							<Resource name="Companiestransfers"
								list={CompaniestransfersList}
								show={hasRight(permissions, Procs.MENU4_TRANSFERS_READ) ? CompaniestransfersShow : null}
								create={hasRight(permissions, Procs.MENU4_TRANSFERS_WRITE) ? CompaniestransfersCreate : null}
								edit={hasRight(permissions, Procs.MENU4_TRANSFERS_WRITE) ? CompaniestransfersEdit : null}
								icon={TransferIcon} />
							: null,
							
						hasRight(permissions, Procs.MENU2_ALBUMS_READ) ?
							<Resource name="Companiesalbums"
								list={CompaniesalbumsList}
								show={hasRight(permissions, Procs.MENU2_ALBUMS_READ) ? CompaniesalbumsShow : null}
								edit={hasRight(permissions, Procs.MENU2_ALBUMS_WRITE) ? CompaniesalbumsEdit : null}
								create={hasRight(permissions, Procs.MENU2_ALBUMS_WRITE) ? CompaniesalbumsCreate : null}
								//createEdito={hasRight(permissions, Procs.HAWC_MENU1_ALBUMS_WRITE) ? CompaniesalbumsCreateEdito : null}
								icon={AlbumIcon} />
							: null,
						hasRight(permissions, Procs.MENU4_TRIGHTS_READ) ?
							<Resource name="Transfersrights"
								show={hasRight(permissions, Procs.MENU4_TRIGHTS_READ) ? TransfersrightsShow : null}
								edit={hasRight(permissions, Procs.MENU4_TRIGHTS_WRITE) ? TransfersrightsEdit : null}
								create={hasRight(permissions, Procs.MENU4_TRIGHTS_WRITE) ? TransfersrightsCreate : null}
								icon={TransferRightIcon} />
							: null,

						hasRight(permissions, Procs.MENU5_WORKFLOW_READ) ?
							<Resource name="Contentsworkflow/Product"
								icon={MovieIcon} />
							: null,

						hasRight(permissions, Procs.CATALOG_BASE_LIST) ?
							<Resource name="Catalogbase/List"
								list={CatalogList}
								icon={CatalogIcon} />
							: null,

						hasRight(permissions, Procs.MENU2_DOMAINS_READ) ?
							<Resource name="Domainsitems"
								list={DomainsitemsList}
								show={hasRight(permissions, Procs.MENU2_DOMAINS_READ) ? DomainsitemsShow : null}
								icon={DomainIcon} />
							: null,

						hasRight(permissions, Procs.INGESTION_STATUS) ?
							<Resource key="watcherIngestion" name="Watcher/IngestionStatus"
								list={WatcherList}
								icon={WatcherLaterIcon} />
							: null,

						hasRight(permissions, Procs.MENU3_SUPERVISION) ?
							<Resource key="jobsSupervision" name="Jobs/Supervision"
								list={JobsList}
								icon={JobsIcon} />
							: null,

						hasRight(permissions, Procs.MENU4_TRANSFERS_READ) ?
							<Resource name="DispatcherConfigurations"
								list={ConfigurationList}
								show={hasRight(permissions, Procs.MENU4_TRANSFERS_READ) ? ConfigurationShow : null}
								edit={hasRight(permissions, Procs.MENU4_TRANSFERS_WRITE) ? ConfigurationEdit : null}
								icon={SettingsIcon} />
							: null,

						hasRight(permissions, Procs.MOVIES_TOP_PRIO_READ) ?
							<Resource name="Moviespriorities"
								list={MoviesprioritiesList}
								show={hasRight(permissions, Procs.MOVIES_TOP_PRIO_READ) ? MoviesprioritiesShow : null}
								edit={hasRight(permissions, Procs.MOVIES_TOP_PRIO_WRITE) ? MoviesprioritiesEdit : null}
								create={hasRight(permissions, Procs.MOVIES_TOP_PRIO_WRITE) ? MoviesprioritiesCreate : null}
								icon={PriorityHighIcon} />
							: null,

						hasRight(permissions, Procs.MENU2_PRICING_CLASS_PRICE_READ) ?
							<Resource name="PricePlanning/Price"
								list={PricePlanification}
								icon={MonetizationOnIcon} />
							: null,

						hasRight(permissions, Procs.MENU2_PRICING_CLASS_PRICE_READ) ?
							<Resource name="PublicationTracking/Planning"
								list={Planning}
								icon={EventNoteIcon} />
							: null,

						hasRight(permissions, Procs.MENU2_PRICING_CLASS_PRICE_READ) ?
							<Resource name="Importexport"
								list={Importexport}
								icon={RepeatIcon} />
							: null,
						<Resource name="JobHistory"
						list={JobHistory} />,
						hasRight(permissions, Procs.MENU2_PRICING_CLASS_PRICE_READ) ?
							<Resource name="PublicationTracking/Preparation"
								list={PreparationBoard}
								icon={EditIcon} />
							: null,

						<Resource name="Transfersrightsprices/MoviesByPeriods"
							list={PricingTool}
							icon={EuroIcon} />,

						<Resource name="Pricingscodes"
							list={Pricingscodes}
							// show={hasRight(permissions, Procs.HAWC_MENU3_TRANSFERS_READ) ? PromotionsShow : null}
							// create={hasRight(permissions, Procs.HAWC_MENU3_TRANSFERS_WRITE) ? PromotionsCreate : null}
							// edit={hasRight(permissions, Procs.HAWC_MENU3_TRANSFERS_WRITE) ? PromotionsEdit : null}
							icon={AttachMoneyIcon} />,
				
						hasRight(permissions, Procs.MENU3_MOVIES_READ) ?
							<Resource name="Tools/Upload"
								list={MyDropzoneArea}
								icon={CloudUploadIcon} /> : null,

						<Resource name="Pricingslists" />,

						<Resource name="PricePlanning/TransfersRights" />,

						<Resource name="PricePlanning/TransfersRightsByPeriod" />,
						<Resource name="Pricingscodes/PriceInterval" />,
						<Resource name="PublicationsTracking/Planning" />,

						<Resource name="Pricingscodes/PriceInterval" />,

						hasRight(permissions, Procs.MENU3_PRODUCTS_READ) ?
							<Resource name="Promotions"
								list={PromotionsList}
								show={hasRight(permissions, Procs.MENU3_PRODUCTS_READ) ? PromotionsShow : null}
								create={hasRight(permissions, Procs.MENU3_PRODUCTS_WRITE) ? PromotionsCreate : null}
								edit={hasRight(permissions, Procs.MENU3_PRODUCTS_WRITE) ? PromotionsEdit : null}
								icon={LoyaltyIcon} />
							: null,
					]}

				</Admin>
				</JssProvider >
				<IdleLayout />
				<MyFooter />
			</React.Fragment>
		) 
	}
}

export default App
