import Api from './Api'

const CompaniesMovies = {
	getMovie: (Movieid) =>
 		Api.Requests.get(`/Companiesmovies/GetMovieById?mid=${Movieid}`),
	getMoviesBddpByAlbumId: (albumId) =>
		Api.Requests.getAll(`/Companiesmovies/GetMoviesBddpByAlbumId?albumid=${albumId}`)
	


	}

export default CompaniesMovies