import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import DefaultImage from '../../../pictures/defaultImage.png'
import { getAttrValueFromProps } from '../../../tools/xmlDocParser'
import { DomainsItems, Url,Token, Logger } from '../../../tools'
import { AddIcon, CancelIcon, CloseIcon, SaveIcon } from '../../../constants/icons'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import cyan from '@material-ui/core/colors/cyan'
import { CustomSelect, CustomDisplayDialog, CustomAlert, CustomAlertInfo} from '../../layout/GlobalStyles'
import DropZoneGlobal from '../../tools/DropZoneGlobal'
import { Button, Checkbox, Radio, FormControlLabel, Paper, Dialog, DialogContent, DialogActions,DialogTitle, Typography, Grid,Link ,Tooltip, Backdrop} from '@material-ui/core'
import { stringify } from 'query-string'
import { fetchEnd, fetchStart, showNotification } from 'react-admin'

import CircularProgress from '@material-ui/core/CircularProgress'
import Draggable from 'react-draggable'
// import { Draggable } from "react-beautiful-dnd"

const styles = theme => ({
	cardPaddingMargin: {
		padding: 20,
		margin: 20,
		flex: '1 auto'
	},
	cardDiv: {
		flex: '1 auto'
	},
	flexContent: {
		//display: 'flex',
		alignItems: 'flex-start',
		margin: '0rem 1rem 0rem 1rem'
		//marginLeft: -14
	},
	radio:{
		color :'#288DB1'
	},
	divMarginLeft: {
		//marginLeft: -14
	},
	img: {
		width: '50%',
		height: '50%',
		display: 'flex',
		marginLeft: '100px',
		padding: '20px'
	},
	imgPreview: {
		margin: '0 auto',
		maxWidth: 500,
		display: 'block'
	},
	inputTwoSide: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	inputRight: {
		marginRight: theme.spacing(1)
	},
	button: {
		margin: theme.spacing(1),
		marginTop: '0px',
		backgroundColor: '#288DB1',
		width: '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		top: '5px',
		lineHeight: '1.2em',
		color: '#fff',
		'&:hover': {
			background: '#1c627b'
		}
	},
	buttonCancel: {
		margin: theme.spacing(1),
		marginTop: '0px',
		// backgroundColor: '#288DB1',
		width: '150px',
		maxHeight: '35px',
		fontSize: '0.75rem',
		top: '5px',
		lineHeight: '1.2em',
		color: '#fff',
		backgroundColor: '#e23434',
		'&:hover': {
			background: '#f50057'
		}
	},
	rootPadding: {
		padding: 0
	},
	bannerAbsoluteTop: {
		position: 'absolute',
		top: 'auto',
		zIndex: 2,
		display: 'block',
		maxWidth: 450,
		maxHeight: 250
		//width: '100%'
	},
	pictureAbsoluteTop: {
		//width: '100%',
		maxWidth: 450,
		maxHeight: 250,
		position: 'absolute',
		top: 'auto',
		zIndex: 1,
		display: 'block'
	},
	divRelative: {
		position: 'relative',
		padding: 0,
		paddingRight:'400px',
		maxWidth: 450,
		maxHeight: 300,
	},
	bannerRelativeTop: {
		position: 'absolute',
		top: 'auto',
		zIndex: 2,
		display: 'block',
		maxWidth: 450,
		maxHeight: 250
		//width: '100%'
	},
	hidden: {
		display: 'none'
	},
	dialogHeader: {
		padding: '6px 10px 4px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	paper: {
		//width: '100%',
		marginBottom: theme.spacing(2),
		// dropdownStyle: {
		// 	width: '50px'

		// },
	},
	labels: {
		margin: '.5em .5em .5em .5em',
		//color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '0.9rem',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	dialogContent: {
		flex: '1 1 auto',
		color: 'black',
		fontSize: '1rem',
		fontWeight: '400',
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: '10000'
	}
})

function PaperComponent(props) {
	return (
	  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
		<Paper {...props} />
	  </Draggable>
	)
  }
// const currentPivot = () => {
// 	const ids = [6, 13, 16, 18, 19, 55, 71, 84, 195, 239, 305]
// 	let typeid = []
// 	ids.map(id => typeid.push({ id: id, name: `${DomainsItems.getLabel(8, id)} (${id})` }))
// 	return typeid
// }



const getPivotCartouche = (props) => {
	let parser = new DOMParser()
	let xmlDoc = parser.parseFromString(props, "text/xml")
	const len = xmlDoc.getElementsByTagName("PROPS")[0] && xmlDoc.getElementsByTagName("PROPS")[0].childNodes ? xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length : 0
	if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
		for (let i = 0; i < len; i++) {
			if (xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME") === "PIVOTID" && xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("VALUE")) {
				return xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("VALUE")
			}
		}
	}
}

class AssistantButton extends Component {
	state = {
		error: false,
		showDialog: false,
		filename: '',
		cartridge: '',
		idChild: '',
		selectedInputFromRadio: 'a',
		// TODO: schedule refactoring
		disabledA: false,
		disabledB: true,
		banner: false,
		sourceid: 1,
		sendtobddp: true,
		applytoseason: false,
		pivotid: 0,
		paysId: -1,
		typeId: 0,
		cartoucheId: 0,
		destinationtypeId: 0,
		sendtoSFR: false,
		sendtoBouygues: false,
		newImagePivot: [],
		alertMessage: '',
		alertendMessage:'',
		warningRequest: false,
		formError: false,
		removeimage: false,
		errorTypeMessage: null,
		errorDestinationTypeMessage: '',
		disableSaveBtn: false,
		showPivotExist : false,
		finalPivotAlert : false
	}
	

	componentDidUpdate(previousProps, previousState) {
		const { image, types, OnLoadNewImage } = this.props
		if (image !== previousProps.image) {
			// if(image.src !=='' ){
			// 	this.setState({removeimage: false})
			// }
			if (image.preview !== '' && image.height !== 0 && image.width !== 0) {
				let destinationType = types.filter(item => item.height === image.height && item.width === image.width && item.isPivot)

				if (types.find(item => item.height === image.height && item.width === image.width && item.isPivot)) {
					this.setState({
						newImagePivot: destinationType,
						destinationtypeId: destinationType.length === 1 ? destinationType[0].itemid : 0,
						cartoucheId: 0,
						cartridge: null,
						banner: false,
						removeimage: false
					})

				} else {
					this.setState({
						// alertMessage: "Aucun pivot ayant le même ratio que l'image n'a été trouvé.",
						// showalert: true,
						formError: true, removeimage: true
					})
					let newImage = {
						path: '',
						preview: '',
						name: '',
						width: 0,
						height: 0
					}
					OnLoadNewImage(newImage)

				}
			}
		}

	}
	handleCloseErrorMessage = () => {
		this.setState({ warningRequest: false })
	}
	endSave =()=>{
		//this.setState({disableSaveBtn: false})
	}
	CheckForm() {
		const { image, types } = this.props
		let res = true
		if (!this.state.disabledA && this.props.image.preview === '') {
			this.setState({
				//alertMessage: "Veuillez choisir un fichier image sur votre poste.",
				//showalert: true,
				formError: true,
				removeimage: true
			})
			res = false
		}
		else if (!this.state.disabledB && this.state.typeId === 0) {
			this.setState({
				errorTypeMessage: 'Veuillez choisir un pivot cible.',
				//alertMessage: "",
				showalert: false, formError: true
			})
			res = false
		}
		else {
			this.setState({ formError: false, errorTypeMessage: null })
			res = true
		}
		
		if (this.state.applytoseason) {
			var confMsg =
			<ul>
				Cette action déclenchera les actions suivantes pour tous les épisodes :
					<li> Le pivot déjà existant sera écrasé.</li>
					{this.state.sendtobddp && <li>	Un nouvel export d'image vers BDDP pour ce pivot sera effectué</li>	}
				Voules-vous continuer ?
			</ul>
			this.setState({
				warningRequest: true,
				alertMessage: confMsg
			})
			res = false
		}

		let ratioUser = 0
		let ratioPivot = 0
		let widthPivot = types.filter(s => s.itemid === this.state.destinationtypeId)[0] ? types.filter(s => s.itemid === this.state.destinationtypeId)[0].width : 0
		let heightPivot = types.filter(s => s.itemid === this.state.destinationtypeId)[0] ? types.filter(s => s.itemid === this.state.destinationtypeId)[0].height : 0
		if (image.height !== 0 && image.width !== 0) {
			ratioUser = (image.width / image.height).toFixed(2)
		}
		if (widthPivot !== 0 && heightPivot !== 0) {
			ratioPivot = (widthPivot / heightPivot).toFixed(2)
		}
		//RATIO
		if (ratioUser !== 0 && ratioPivot !== 0 && Math.abs(ratioPivot - ratioUser).toFixed(2) > 0.1) {
			this.setState({
				alertMessage: <ul>L'image choisie n'a pas le bon ratio largeur/hauteur.
								Veuillez choisir une image adaptée au pivot cible.</ul>
				, showalert: true, formError: true
			})
			res = false
		}

		// SIZE
		if (widthPivot !== 0 && heightPivot !== 0 && image.height !== 0 && image.width !== 0 && (image.width * image.height) < (widthPivot * heightPivot * 0.81)) {
			this.setState({
				alertMessage: "L'image choisie est trop petite pour le pivot cible.\nPour une qualité optimum, veuillez sélectionner une image plus grande."
				, showalert: true, formError: true
			})
			res = false
		}
		return res

	}

	handleClick = () => {
		this.setState({ showDialog: true })
	}
	resetValue = () => {
		const { OnLoadNewImage } = this.props
		
		let newImage = {
			path: '',
			preview: '',
			name: '',
			width: 0,
			height: 0
		}
		OnLoadNewImage(newImage)
		this.setState({
			disabledA: false,
			disabledB: true,
			banner: false,
			sourceid: 1,
			sendtobddp: true,
			applytoseason: false,
			pivotid: 0,
			paysId: -1,
			typeId: 0,
			cartoucheId: 0,
			destinationtypeId: 0,
			sendtoSFR: false,
			sendtoBouygues: false,
			//showalert: false,
			newImagePivot: [],
			removeimage: true,
			//alertMessage :'',
			disableSaveBtn: false


		})
	}

	handleCloseClick = () => {
		this.resetValue()
		this.setState({
			showDialog: false
		})
	}

	handleSaveClick = () => {
		const {listAlbumsPictures} = this.props
		
		//if (this.CheckForm() && !this.state.formError) {
			//this.setState({disableSaveBtn: true ,showPivotExist:true })
			this.setState({ showPivotExist:true })

		if(listAlbumsPictures.find(p=>p. typeid === this.state.destinationtypeId)){
			this.setState({ 
				alertMessage : "Attention, il existe déjà une image en base de données pour ce type de pivot. Si vous continuer cette opération, celle-ci sera écrasée. Voulez-vous continuer ?"
			})}
			else{
				this.setState({alertMessage : "Les informations vont être enregitrées et le fichier d'image transmis pour traitement. Voulez-vous continuer ?"})				
			}
 
		
			// 	unregisterField(REDUX_FORM_ASSISTANT, 'typeid')
			// // Trigger a submit of our custom quick create form
			// // This is needed because our modal action buttons are oustide the form
			// submit(REDUX_FORM_ASSISTANT)
			// this.setState({ showDialog: false })
		//}
	}
	handleValidPivotExist = () =>{
		this.setState({showPivotExist: false})
		if (this.CheckForm() && !this.state.formError)
			this.handleSubmit()
	}
	handleSubmit = () => {
		const { image, record, objectId } = this.props
		const formData = new FormData()
		formData.append('image', image)
		let results = {}
		const initialValues = Object.entries(image).filter(value => value[0] !== 'typeid' && value[0] !== 'pictures')
		
		initialValues.forEach(value => {
			results[value[0]] = value[1]
			formData.append(value[0], value[1])
		})
 
 		let query = {
			sourceId: this.state.sourceid,
			objectId: objectId,
			id: record.id,
			pivotId: this.state.disabledA ? this.state.typeId : this.state.destinationtypeId,
			cartoucheId: this.state.cartoucheId,
			sendToBddp: this.state.sendtobddp,
			applyToSeason: this.state.applytoseason,
			sendtoSfr: this.state.sendtoSFR,
			sendToBouygues: this.state.sendtoBouygues

		}
		const url = `${process.env.REACT_APP_API}/PicturePivot/Pivot?${stringify(query)}`
		this.setState({warningRequest:false,disableSaveBtn:true})
		fetch(url, {
			method: 'POST',
			headers: new Headers({ 
			//enctype: 'multipart/form-data',
			'Authorization': `Bearer ${Token.getToken()}` }),
			body: formData
		  })
		  .then(response =>
			{ 
			response.json()
			this.setState({
				 //showDialog: false 	,
				 warningRequest : false	 ,
				 finalPivotAlert : true ,
				 alertendMessage: "Le pivot a bien été envoyé.\nLa conversion des visuels s'effectuera dans quelques instants."
				})
		})
		  .then(data => {
				this.setState({
					alertMessage: "Pivot ajouté avec succès",
					formError: false,
					showalert: true,
					warningRequest : false,

				}, 
					
					() =>{
						this.resetValue()
					}

				// 	() => {
				// 		// This function will be executed after the state has been updated
				// 		this.setState({
				// 			finalPivotAlert : true ,
				// 			alertMessage: "Le pivot a bien été envoyé.\nLa conversion des visuels s'effectuera dans quelques instants."
				// 		 },
				// )
				
				// }
			)
			//this.resetValue()
			// Traitez la réponse comme nécessaire
		  })
		  .catch(error => {
			console.error('Error:', error);
			this.setState({
				alertMessage : "Erreur d'ajout du pivot",
				formError: true,
				showalert: false,
				warningRequest : false,
				disableSaveBtn : false
 
				})		 
			})
	}

	handleChange = (event, value) => {
		if (value === 'destination') {
			this.setState({
				destinationtypeId: event.target.value,
				cartoucheId: 0,
				cartridge: null,
				banner: false,
			})

		}
		if (value === 'source') {
			this.setState({
				typeId: event.target.value,
				errorTypeMessage: event.target.value ? null : this.state.errorTypeMessage,
				cartoucheId: 0,
				cartridge: null,
				banner: false,
			})
		}
		// idsChild.forEach(id => {
		// 	if (value === recordChild[id].intTypeid) {
		// 		change(REDUX_FORM_ASSISTANT, 'pivotid', recordChild[id].intTypeid)
		// 		this.setState({ filename: recordChild[id].filename, idChild: recordChild[id].id })
		// 	}
		// })
	}

	handleChangeCartouche = (event) => {
		let value = event.target.value
		const item = value !== 0 ? DomainsItems.getItem(107, value) : null
		this.setState({ cartoucheId: value })
		this.setState({
			cartridge: value !== 0 ? getAttrValueFromProps('FILENAME', item.properties) : null,
			banner: value !== 0 ? true : false
		})
	}
	handleChangeCountry = (event) => {
		this.setState({ paysId: event.target.value })
	}
	handleChangeRadioChecked = event => {
		const value = event.target.value
		this.setState({
			selectedInputFromRadio: value,
			// TODO: schedule refactoring
			disabledA: value === 'a' ? false : true,
			disabledB: value === 'b' ? false : true,
			sourceid: value === 'a' ? 1 : 2,
			cartoucheId: 0,
			cartridge: null,
			banner: false,
		})
		// if (this.state.disabledA) change(REDUX_FORM_ASSISTANT, 'sourceid', 1)
		// if (this.state.disabledB) change(REDUX_FORM_ASSISTANT, 'sourceid', 2)
	}

	// postDefaultValue = () => {
	// 	const id = window.location.href.split('=')[1]
	// 	return ({
	// 		objectid: 1,
	// 		id: parseInt(id, 10),
	// 		sourceid: this.state.sourceid,
	// 		pivotid: this.state.pivotid,
	// 		sendtobddp: this.state.sendtobddp,
	// 		applytoseason: this.state.applytoseason
	// 	})
	// }

	handleOpenAlert = () => {
		this.setState({
			showalert: false,
			//removeimage: false
		})
	}
	handleChangeSendToBdd = () => {
		this.setState({
			sendtobddp: !this.state.sendtobddp
		})
	}
	handleChangeSendPivot = (e,destination) => {
		 if(destination ==='SFR')
		this.setState({
			sendtoSFR: !this.state.sendtoSFR
		})
		if(destination ==='Bouygues')
		this.setState({
			sendtoBouygues: !this.state.sendtoBouygues
		})
	}
	handleChangeapplytoSeason = () => {
		// const { change } = this.props
		// change(REDUX_FORM_ASSISTANT, 'sendtobddp', !this.state.sendtobddp)
		this.setState({
			applytoseason: !this.state.applytoseason
		})
	}
	// handleUploadImage = (event) => {
	// 	this.setState({ file: event.rawFile })
	// }
	getPivotList = () => {
		const { types, listAlbumsPictures, image } = this.props
		// console.log(types)
		// console.log(listAlbumsPictures)

		let parser = new DOMParser()
		let pivotList = []
		types.forEach((elt) => {
			let xmlDoc = parser.parseFromString(elt.properties, "text/xml")
			const len = xmlDoc.getElementsByTagName("PROPS")[0] && xmlDoc.getElementsByTagName("PROPS")[0].childNodes ? xmlDoc.getElementsByTagName("PROPS")[0].childNodes.length : 0
			if (xmlDoc.getElementsByTagName("PROPS")[0] !== undefined) {
				for (let i = 0; i < len; i++) {
					if (xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("NAME") === "PIVOT" && xmlDoc.getElementsByTagName("PROPS")[0].childNodes[i].getAttribute("VALUE")) {
						if (!this.state.disabledB) {
							if (listAlbumsPictures.find(s => s.typeid === elt.itemid)) elt.disabled = false
							else elt.disabled = true
						}
						if (!this.state.disabledA) {
							if (this.state.newImagePivot.length !== 0) {
								if (image.preview !== '' && image.width !== 0 && image.height !== 0 && !this.state.newImagePivot.find(p => p.itemid === elt.itemid))
									elt.disabled = true
								else elt.disabled = false
							}
							else elt.disabled = true
						}
						pivotList.push(elt)
					}
				}
			}
		})
		return pivotList
	}
	cartouche = () => {
		let cartouche = new Array({ itemid: 0, label: 'Aucun' })
		if (this.state.disabledB)
			DomainsItems.getItems(107).map(item => cartouche.push({
				itemid: item.itemid, label: item.label, pivot: getPivotCartouche(item.properties),
				disabled: getPivotCartouche(item.properties) !== this.state.destinationtypeId.toString()
			}))
		else if (this.state.disabledA)
			DomainsItems.getItems(107).map(item => cartouche.push({
				itemid: item.itemid, label: item.label, pivot: getPivotCartouche(item.properties),
				disabled: getPivotCartouche(item.properties) !== this.state.typeId.toString()
			}))
		return cartouche
	}

	ouvrirImage  = ()=>{
		const { record, listAlbumsPictures,image } = this.props
		const url1 =!this.state.disabledA ? image.preview : Url.pictureAlbum(record.id, listAlbumsPictures.filter(s => s.typeid === this.state.typeId)[0]? listAlbumsPictures.filter(s => s.typeid === this.state.typeId)[0].filename : null)
    	const url2 =  Url.pictureCartouche(this.state.cartridge)

		// Ouvrez un nouvel onglet avec les images superposées
		const newTab = window.open('', '_blank')
		const htmlCode = `
		<html>
		<body>
			<img src="${url1}" alt="" style="position: absolute; top: 0; left: 0;">
			<img src="${url2}" alt="" style="position: absolute; top: 0; left: 0;">
		</body>
		</html>
		`
		// Écrivez le code HTML dans le nouvel onglet
		newTab.document.open()
		newTab.document.write(htmlCode)
		newTab.document.close()
	}

	render() {
		const { classes, recordParent, record, OnLoadNewImage, image, listAlbumsPictures, assistanceTitle,types } = this.props
		const title = recordParent ? `${recordParent.title} (${recordParent.id})` : record ? `${record.name} (${record.id})` : null
		const checked = this.state.selectedInputFromRadio
		const banner = this.state.banner
		const previewDefaultPicture = <img src={DefaultImage} alt="" className={classes.img} />
		const cartoucheValue = Url.pictureCartouche(this.state.cartridge)
		// console.log(types)
		// console.log(listAlbumsPictures)
		return (
			<Fragment>
				<Button onClick={this.handleClick.bind()} className={classes.button}>
					<AddIcon /> Assistant
				</Button>
				<CustomAlert libelle={this.state.alertMessage} severity={this.state.formError ? 'error' : 'success'} open={this.state.showalert} setOpen={this.handleOpenAlert} />
				{this.state.disableSaveBtn &&
						(<CustomAlertInfo libelle="Enregistrement en cours ..." severity="info"  
						open={this.state.disableSaveBtn} setOpen={this.endSave} timer ={true}/>)}
						<Backdrop style={{opacity:0.5}}>
				<Dialog
				fullWidth
				open={this.state.showDialog}
				onClose={this.handleCloseClick}
				aria-label="Image pour"
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
				disableEscapeKeyDown={true}
				disableBackdropClick={true}
				> 
				<DialogTitle style={{ cursor: 'move',fontSize: 'medium',padding:0 }}  id="draggable-dialog-title">
				<div className={classes.dialogHeader}>
						Assistant d'images pour {assistanceTitle} {title}
						<Button onClick={this.handleCloseClick} className={classes.closeIcon} >
							<CloseIcon />
						</Button>
					</div>
        		</DialogTitle>
					
					<DialogContent>
						<CustomDisplayDialog info='Alerte' type='window' open={this.state.warningRequest} 
							handleClose={this.handleCloseErrorMessage} handleSave={this.handleSubmit}
							content={this.state.alertMessage} maxWidth="sm" />
						<CustomDisplayDialog info='Alerte' type='window' open={this.state.showPivotExist} 
							handleClose={()=>this.setState({showPivotExist:false,alertMessage:""})} handleSave={this.handleValidPivotExist}
							content={this.state.alertMessage} maxWidth="sm" />
						<CustomDisplayDialog info='Information' type='window' open={this.state.finalPivotAlert} 
							handleClose={()=>this.setState({finalPivotAlert:false, disableSaveBtn: false})}  
							content={this.state.alertendMessage} maxWidth="sm" />

							{this.state.disableSaveBtn    && <div className={classes.circularProgress}><CircularProgress size={50} thickness={3} /> </div>}
						
						{/* <Paper className={classes.paper}> */}
						<div className={classes.dialogContent}>

							<Fragment>
								<Paper className={classes.paper} >
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<Typography variant="title" className={classes.labels}>Choix du pivot source:</Typography>
											<div className={classes.flexContent}>
												<FormControlLabel
													control={<Radio
														color='#288DB1'
														checked={checked === 'a'}
														onChange={this.handleChangeRadioChecked}
														value="a"
														className={classes.radio}
													/>} label="Nouvelle Image" />
												<Paper className={classes.paper}>
													<DropZoneGlobal disabled={this.state.disabledA} title={'IMPORT IMAGE'} image={image} OnLoadNewImage={OnLoadNewImage} removeImage={this.state.removeimage} />
												</Paper>
												{/* <Button  variant="contained" disabled={checked !== 'a'} color="primary" className={classes.button} >Choisir un fichier</Button> */}
												{/* <ImageInput source="pictures" label="Related pictures" accept="image/*" disableClick={disabledA} onChange={this.handleUploadImage.bind(this)} classes={{ removeButton: classes.hidden }}>
										<ImageField source="src" title="title" upload={true} hidden={true} />
									</ImageInput> */}
											</div>

											<div className={classes.flexContent}>
												<FormControlLabel control={<Radio
													color='#288DB1'
													checked={checked === 'b'}
													onChange={this.handleChangeRadioChecked}
													value="b"
													className={classes.radio}
												/>} label="Image disponible"
												/>
												<CustomSelect label="Type" disabled={checked !== 'b'} propsType="domainItem" value={this.state.typeId} errorLabel={checked === 'b' && this.state.errorTypeMessage} values={this.getPivotList()} handleChange={(event, key) => { this.handleChange(event, 'source') }} fullWidth={true} />
												{/* <SelectInput label="fields.typeid" source="typeid" choices={currentPivot()} allowEmpty={false} onChange={(event, key) => { this.handleChange(key) }} disabled={disabledB} /> */}
											</div>
										</Grid>
										<Grid item xs={6}>
											<Typography variant="title" className={classes.labels}>Pré-visualisation</Typography>
											<Tooltip title='Cliquez pour consulter' >

												<Link
													component="button"
													variant="body2"
													disabled={image.preview ==='' &&  this.state.typeId ===0}
													onClick={()=>this.ouvrirImage()}
												>

													{!this.state.disabledA ?
														image.preview ?
															<div className={classes.divRelative}>
																{/* <img src={albumPicture.preview} className={classes.img}/>  */}
																<img src={image.preview} alt="Preview" className={classes.pictureAbsoluteTop} />
																{banner ? (
																	<img
																		alt=""
																		src={cartoucheValue}
																		className={classes.bannerRelativeTop}
																	/>
																) : null}
															</div>
															: previewDefaultPicture
														:
														listAlbumsPictures.filter(s => s.typeid === this.state.typeId) && listAlbumsPictures.filter(s => s.typeid === this.state.typeId)[0] ?
															<div className={classes.divRelative}>
																<img src={Url.pictureAlbum(record.id, listAlbumsPictures.filter(s => s.typeid === this.state.typeId)[0].filename)}
																	alt="Preview" className={classes.pictureAbsoluteTop} />
																{banner ? (
																	<img
																		alt=""
																		src={cartoucheValue}
																		className={classes.bannerAbsoluteTop}
																	/>
																) : null}
															</div> : previewDefaultPicture

													}
												</Link>
											</Tooltip>




										</Grid>
									</Grid>
								</Paper>
								<Paper className={classes.paper} >

									<Typography variant="title" className={classes.labels}>Format du pivot de destination</Typography>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											{/* <SimpleShowLayout classes={{ root: classes.rootPadding }}> */}
											<CustomSelect label="Type" propsType="domainItem" disabled={this.state.disabledA} value={this.state.destinationtypeId} values={this.getPivotList()} handleChange={(event, key) => { this.handleChange(event, 'destination') }} fullWidth={true} errorLabel={!this.state.disabledA && this.state.newImagePivot.length !== 0 && this.state.destinationtypeId === 0 ? 'Selectionner le type de pivot' : null} />
											<CustomSelect label="Cartouche" propsType="domainItem" value={this.state.cartoucheId} disabled={this.state.disabledB && !image.path} values={this.cartouche()} handleChange={(event, key) => { this.handleChangeCartouche(event, key) }} fullWidth={true} />
										</Grid>
										<Grid item xs={6}>
											{/* <SelectInput label="fields.typeid" source="typeid" choices={currentPivot()} allowEmpty={false} onChange={(event, key) => { this.handleChange(key) }} /> */}
											{/* <SelectInput label="fields.cartoucheid" source="cartoucheid" choices={cartouche()} allowEmpty={false} onChange={(event, key) => { this.handleChangeCartouche(key) }} /> */}
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.sendtobddp}
														onChange={this.handleChangeSendToBdd}
														color='#288DB1'
														className={classes.radio}
													/>
												}
												label="Envoyer le pivot vers BDDP"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.applytoseason}
														onChange={this.handleChangeapplytoSeason}
														color='#288DB1'
														className={classes.radio}
														disabled={record.typeid !== 5}
													/>
												}
												label="Appliquer le pivot à tous les épisodes de la saison"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.sendtoSFR}
														onChange={e=>this.handleChangeSendPivot(e,'SFR')}
														color='#288DB1'
														className={classes.radio}
													/>
												}
												label="Envoyer les images vers SFR"
											/>
											<FormControlLabel
												control={
													<Checkbox
														checked={this.state.sendtoBouygues}
														onChange={e=>this.handleChangeSendPivot(e,'Bouygues')}
														color='#288DB1'
														className={classes.radio}
													/>
												}
												label="Envoyer les images vers Bouygues"
											/>
										</Grid>
									</Grid>
									{/* </SimpleShowLayout> */}
								</Paper>



							</Fragment>
							{/* </SimpleForm> */}
						</div>
						{/* </Paper> */}
					</DialogContent>
					<DialogActions>
						{/* <SaveButton
							saving={isSubmitting}
							onClick={this.handleSaveClick}
						/> */}
						<Button onClick={this.handleSaveClick} className={classes.button} disabled={this.state.disableSaveBtn}>
							<SaveIcon /> Enregistrer
						</Button>
						<Button onClick={this.handleCloseClick} className={classes.buttonCancel}>
							<CancelIcon /> Annuler
						</Button>
					</DialogActions>
				</Dialog>
				</Backdrop>
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	// const id = window.location.href.split('=')[1]
	// return ({
	// 	//isSubmitting: isSubmitting(REDUX_FORM_ASSISTANT)(state),
	// 	valuePicture: getFormValues(REDUX_FORM_ASSISTANT)(state),
	// 	recordParent: state.admin.resources['Companiesmovies']
	// 		? state.admin.resources['Companiesmovies'].data[id]
	// 		: {},
	// 	recordChild: state.admin.resources['Moviespictures']
	// 		? state.admin.resources['Moviespictures'].data
	// 		: {},
	// 	idsChild: state.admin.resources['Moviespictures']
	// 		? state.admin.resources['Moviespictures'].list.ids
	// 		: {}
	// })
}

AssistantButton.propTypes = {
	classes: PropTypes.object.isRequired,
}
const mapDispatchToProps = {
	//change,
	fetchEnd,
	fetchStart,
	showNotification,
	//submit,
 
  
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(AssistantButton)
