import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { fetchStart, GET_LIST, translate } from 'react-admin'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { VisibilityIcon, GroupIcon } from '../../../constants/icons'
import cyan from '@material-ui/core/colors/cyan'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles'
import { CloseIcon, CancelIcon, CloudDownloadIcon, AddIcon, CheckIcon } from '../../../constants/icons'

import Tooltip from '@material-ui/core/Tooltip'
import Slide from '@material-ui/core/Slide'
import { DateFormatter,DomainsItems } from '../../../tools'
import {  PromotionsSubscribers } from '../../../requests'
import { CustomTextField,CustomAlert } from '../../layout/GlobalStyles'
import Papa from 'papaparse'
import { onPromotionUpdate, onGetPromo, onPromotionSubscriberExist } from '../../../actions/Promotion'
import dataProvider from '../../../dataProvider'
import PromotionsAddUserModal from './PromotionsAddUserModal'
import { Contacts,Logger } from '../../../tools'
import FilterField from './../../common/fields/FilterField'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
 
const styles = (theme) => ({
	root: {
		margin: 0,
	},
	closeButton: {
		marginLeft: '88%',
		marginTop: '-40px'
	},
	popupTitle: {
		color: 'white',
		fontSize: '18px'
	},
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	buttonCancel: {
		width: '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	customSearchButtonIcon: {
		margin: theme.spacing(1),
		color: theme.palette.text.primary,
		width: '20%',
		height: '38%',
	},
	button: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		marginTop: '0.5em',
		padding: '.5 1 .5 1'
	},
	buttonModal: {
		fontSize: '.9em',
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		padding: '.5 1 .5 1',
		position: "absolute",
		right: "3em",
		top: '6em'
	},
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const PromotionsUsers = (props) => {

	const [subscribers, setSubscribers] = React.useState('chargement...')
	const [open, setOpen] = React.useState(false)
	const [searchUser, setSearchUser] = React.useState([])
	// const [csvLoading, setcsvLoadingState] = React.useState(false)
	const [subscribersearch, setSubscribersearch] = React.useState('')
	const [isLoading, setIsLoading] = React.useState(false)
	const [modeSelected, setModeSelected] = React.useState(2)
	const [openAddUser, setOpenAddUser] = React.useState(false)
	const [rowSelected, setRowSelected] = React.useState([])
	const [rowsSelected, setRowsSelected] = React.useState([])
	const [showError, setShowError] = React.useState(false)

	const { source, classes, withAll, placeholder, promotion, create,type, onPromotionSubscriberExist, ...rest } = props
	const [mode, setMode] = React.useState([])

	useEffect(() => {
		PromotionsSubscribers.getAllSubBypromoId(source.id).then(data => {
			if (subscribers === 'chargement...') {
				setSubscribers(data.data.length)
				onPromotionSubscriberExist(true)
			}
		})

		let userType = Object.values(DomainsItems.getItems(127))  
		 
		let listmode =[]
		userType && userType.forEach(domain => {
				let parser = new DOMParser()
				let xmlDoc = parser.parseFromString(domain.properties, "text/xml")
				listmode.push({
					id: domain.itemid,
					name: domain.label,
					Tag: xmlDoc.getElementsByTagName("PROP")[0].getAttributeNode("VALUE").value,

				})
				
			})
			setMode(listmode)
		//})

	}, source)


	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleAddUserClose = () => {
		setOpenAddUser(false)
	}

	const handleAddUserOpen = () => {
		setOpenAddUser(true)
	}

	// const handleSearchKeyPress = (event) => {
	// 	if (event.key === 'Enter') {
	// 		searchClick()
	// 	}
	// }

	const onChangeSearch = (e, type) => {
		if (type === 'reset') {
			setSubscribersearch('')
			setSearchUser([])		
		}
		else
			setSubscribersearch(e.target.value)
	}

	const searchClick = (type) => {
		setSearchUser([])
		if (subscribersearch.length >= 2 || type ==='ShowAll') {
			let searchWord = "%" + subscribersearch + "%"
			if(type ==='ShowAll') searchWord='%%'
			fetchStart()
			setIsLoading(true)
			dataProvider(GET_LIST,
				"Promotionssubscribers/Search",
				{
					pagination: { page: 1, perPage: 100 },
					sort: { field: {}, order: {} },
					filter: { id: promotion.id, mode: modeSelected, wordSearch: searchWord }
				}).then(resp => {
					resp.data.forEach(r => {
						if (r.disabledon === '0001-01-01T00:00:00.000') {
							r.disabledon = null
						}
						if (r.lastsynchapi === '0001-01-01T00:00:00.000') {
							r.lastsynchapi = null
						}
					})
					setSearchUser(resp.data)

				})
			setIsLoading(false)
		}
	}
	const getMuiTheme = () => createTheme({
		overrides: {
			MuiPaper: {
				elevation4: {
					boxShadow: 'none'
				}
			},
			MUIDataTableToolbarSelect: {
				root: {
					display: "none !important"
				}
			},
			MUIDataTable: {
				responsiveBase: {
					overflow: 'visible'
				}
			},
			MUIDataTableToolbar: {
				actions: {
					display: "none !important"
				}
			},
			MuiDialog :{
				paperWidthLg: {
					minWidth:'1280px',
					//maxHeight: '400px'
					overflow:'hidden'
				}
			},
			MuiTableCell:{
				root: {
					textAlign: 'left',
					lineHeight: '1em',
				},
				fixedHeader: {
					top: '-10px'
				},
				sizeSmall: {
					textAlign: 'left',
					lineHeight: '1em',
					padding: '4px 12px 4px 12px'
			}
		}
		},
	})

	const exportToCsv = (loaded = false) => {

		let subscribers = []

		if (loaded && searchUser.length > 0) {
			searchUser.map(s => {
				subscribers.push({
					promosubscriberid: s.id,
					externalid: s.externalid,
					typeid: s.typeid,
					subscriberid: s.subscriberid,
					cganumber: s.cganumber,
					lastsynchapi: s.lastsynchapi,
					disabled: s.disabled,
					disabledon: s.disabledon,
					disabledby: s.disabledby
				})
			})
			exportCsvFile(subscribers)
		}
		else if (promotion.id !== 0) {
			// setcsvLoadingState(true)
			PromotionsSubscribers.getAllSubBypromoId(promotion.id).then(response => {
				response.data.map(d => {
					subscribers.push({
						promosubscriberid: d.promosubscriberid,
						externalid: d.externalid,
						typeid: d.typeid,
						subscriberid: d.id,
						cganumber: d.cganumber,
						lastsynchapi: d.lastsynchapi,
						disabled: d.disabled,
						disabledon: d.disabledon,
						disabledby: d.disabledby
					})
				})
				exportCsvFile(subscribers)
				// setcsvLoadingState(false)
			})
		}
	}

	const exportCsvFile = (data) => {

		var options = {
			quotes: false,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ";",
			header: true,
			newline: "\r\n",
			skipEmptyLines: false,
			columns: null
		}

		var csv = Papa.unparse(data, options)
		var csvData = new Blob([csv], { type: 'plain/text;charset=utf-8;' })
		var csvURL = null
		if (navigator.msSaveBlob) {
			csvURL = navigator.msSaveBlob(csvData, 'export_subscribers_' + promotion.id + '.txt')
		} else {
			csvURL = window.URL.createObjectURL(csvData)
		}
		var tempLink = document.createElement('a')
		tempLink.href = csvURL
		tempLink.setAttribute('download', 'export_subscribers_' + promotion.id + '.txt')
		tempLink.click()
	}
	const columns = [
		{
			name: 'id',
			label: 'Id',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', width: '10em' } }),
				filter: false,
				setCellProps: () => ({ style: { paddingLeft: '5px', whiteSpace: "pre", fontSize: '.8em' } }),
			}
		},
		{
			name: 'externalid',
			label: 'Id Externe',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000',  } }),
				filter: false,
				setCellProps: () => ({ style: { width: '200em', whiteSpace: "pre", fontSize: '.8em' } })
			}
		},
		{
			name: 'typeid',
			label: 'Type',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', width: '10em'} }),
				filter: false,
				setCellProps: () => ({ style: { whiteSpace: "pre", fontSize: '.8em' } }),
				customBodyRender: (value) => {
					return (
						<div>
							{value ? mode.filter(m => m.id === value)[0].name : null}
						</div>
					)
				}
			}
		},
		{
			name: 'subscriberid',
			label: 'Identifiant P@ss',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000' } }),
				setCellProps: () => ({ style: { whiteSpace: "pre", fontSize: '.8em' } }),
				filter: false
			}
		},
		{
			name: 'cganumber',
			label: 'N° CGA',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000' } }),
				setCellProps: () => ({ style: { whiteSpace: "pre", fontSize: '.8em' } }),
				filter: false
			}
		},
		{
			name: 'lastsynchapi',
			label: 'Dernière synchronisation le',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', width: '200em' } }),
				filter: false,
				setCellProps: () => ({ style: { width: '100em', fontSize: '.8em' } }),
				customBodyRender: (value) => {
					return (
						value != null ?
							(<div>
								{DateFormatter.getDayNameDateHourMinSec(value)}
							</div>) : null
					)
				}
			}
		}, 
		{
			name: 'disabled',
			label: 'Statut',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000', width: '200em' } }),
				filter: false,
				setCellProps: (value) => {
					const color =  value==='Désactive' ? 'red':'green'
					return{
					 style: { fontSize: '.8em',color: color } 
					}
					},
				customBodyRender: (value) => {
					return (
						value ? 'Désactive': 'Actif'
					)
				}
			}
		}, 
		{
			name: 'disabledon',
			label: 'Désactivé le',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000' } }),
				filter: false,
				setCellProps: () => ({ style: { width: '100em', fontSize: '.8em' } }),
				customBodyRender: (value) => {
					return (
						value != null
							? (<div>
								{DateFormatter.getDayNameDateHourMinSec(value)}
							</div>)
							: null
					)
				}
			}
		}, {
			name: 'disabledby',
			label: 'Désactivé par',
			options: {
				setCellHeaderProps: () => ({ style: { fontWeight: 'bold', color: '#000',paddingRight:'0px',right:0 } }),
				filter: false,
				setCellProps: () => ({ style: { width: '100em', paddingLeft: '0px', whiteSpace: "pre", fontSize: '.8em',paddingRight:'8px',right:'2px' } }),
				customBodyRender: (value) => {
					return (
						<div>
							{value ? Contacts.getFormattedLabel(value) : null}
						</div>
					)
				}
			}
		},
	]

	const options = {
		rowsPerPage: 10,
		rowHover: false,
		textLabels: {
			body: {
				noMatch: 'Aucune ligne trouvée',
				toolTip: 'Trier'
			},
		},
		setTableProps: () => {
			return {
				padding: 'normal',
				size: 'small',
			}
		},
		selectableRows: 'multiple',
		isRowSelectable: (dataIndex) => {
			if (searchUser && !searchUser[dataIndex].disabled ) {
				return true
			} else {
				return false
			}
		},
		onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
			setRowSelected([])
			rowsSelected.forEach(s => {
				setRowSelected(old => [...old, { Id: parseInt(searchUser[s].id),SubscriberId: searchUser[s].subscriberid ,Recordid: searchUser[s].recordId, PromoId: promotion.id }])
			})
			setRowsSelected(rowsSelected)
		},
		rowsSelected: rowsSelected,
		resizableColumns: false,
		print: false,
		search: false,
		download: false,
		filter: false,
		viewColumns: false,
		fixedSelectColumn: true,
		customToolbar: false,
		pagination: true
	}

	const handleChangeTypeValue = (event) => {
		setModeSelected(event.target.value)
	}

	const disableUsers = async () => {
		
		for (const row of rowSelected) {
			return  new Promise((resolve, reject) => {
				 PromotionsSubscribers.disableUser(row).then((data) => {
						console.log(data)	
						resolve()
						setSearchUser([])
						setRowSelected([])
						setRowsSelected([])
						searchClick()
					}
					).catch((error) => {
						Logger.debug(error)
						console.log(error)			

						reject()
						setShowError(true)
						const timeId = setTimeout(() => {
							setShowError(false)
						}, 3000)
						return () => {
							clearTimeout(timeId)
						}
					})
		})
		}
		// setSearchUser([])
		// setRowSelected([])
		// setRowsSelected([])
		// searchClick()
	}
	const handleOpenAlert = () => {
 		setShowError(false)
 	}
	const modalContent = {
		title: "Ajouter utilisateurs",
		//description : translate('messages.importPlanningInstruction'),
		disagreeButtonText: translate('ra.action.undo'),
		agreeButtonText: translate('ra.action.confirm')
	}
	return (
		<div>
		{showError && (<CustomAlert libelle="Erreur lors de désactivation" severity="error" open={showError} setOpen={handleOpenAlert} />) }

		<Grid item xs={12}>
			<Grid container direction="row">
				<GroupIcon /><Typography variant="title" style={{ marginLeft: '.5em', marginBottom: '1em' }}>Utilisateurs</Typography>
			</Grid>
			<Grid item xs={8}>
				<Grid container direction="row">
					<Grid item xs={4}>
						<CustomTextField label="Nombre d'utilisateur" value={subscribers} />
					</Grid>
					<Grid item xs={2} style={{ marginRight: '-2em' }}>
						<Button className={classes.button} variant={'contained'} disabled={type==='create'} color={'primary'} onClick={handleClickOpen}>
							<VisibilityIcon style={{ marginRight: '.5em' }} />Voir
						</Button>
					</Grid>
					<Grid item xs={2}>
						<Button className={classes.button} variant={'contained'} color={'primary'} onClick={handleAddUserOpen} disabled={create || promotion.closed}>
							<AddIcon style={{ marginRight: '.5em' }} />Ajouter
						</Button>
					</Grid>
					<Grid item xs={2}>
						<Tooltip title="Télécharger tous les utilisateurs">
							<Button variant='contained' color="primary" disabled={type==='create'} className={classes.button} onClick={() => exportToCsv(false)}>
								<CloudDownloadIcon style={{ marginRight: '.5em' }} /> TELECHARGER
								{/* { csvLoading ? 
										(<CircularProgress color="secondary" style={{ display: 'block'}} style={{ width: "25px", height: "25px", marginLeft: ".5em"}}/>)
										: null
									} */}
							</Button>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
			<PromotionsAddUserModal open={openAddUser} create={create}
				onClose={handleAddUserClose}
				title="AJOUTER DES UTILISATEURS"
				fileRules="*Le fichier doit être de type .txt avec une taille inférieure ou égale à 20 Mo"
				mode={mode} />
			{/* LISTE USERS DIALOG */}
			<ThemeProvider theme={getMuiTheme()}>

			<Dialog onClose={handleClose}
				maxWidth="lg"
				fullWidth
				aria-labelledby="customized-dialog-title"
				open={open}
				TransitionComponent={Transition}
				style={{overflow:'hidden'}}
			>
				<div className={classes.dialogHeader}>
					LISTE DES UTILISATEURS
					<Button onClick={handleClose} className={classes.closeIcon} >
						<CloseIcon />
					</Button>
				</div>
				<DialogContent dividers style={{ paddingTop: '.5em', paddingBottom: '.2em' }}>
					{/* <div style={{display: 'flex', alignItems: 'center', width: '100%'}}> */}
					<FilterField selectedValue={modeSelected} values={mode} classes={classes} removeicon={true}
						valueChange={(e) => onChangeSearch(e, '')} selectedChange={event => handleChangeTypeValue(event)}
						ButtonClick={() => searchClick()} textValue={subscribersearch} errorLabel={subscribersearch !=='' && subscribersearch.length < 2 ?"Recherche à partir de 2 caractères":''}
						valueReset={event => onChangeSearch(event, 'reset')}
						switch={
							<div>
							<Tooltip title="Télécharger la liste">
								<Button variant={'contained'} className={classes.buttonModal} color={'primary'} onClick={() => exportToCsv(true)} disabled={searchUser.length <= 0} 
								style={{ position: "absolute", top: "4.58em" , right: "3em"}}>
									<CloudDownloadIcon /> TELECHARGER
								</Button>
							</Tooltip>
							<Button variant={'contained'} className={classes.buttonModal} color={'primary'} 
								onClick={() => searchClick('ShowAll')}  style={{ position: "absolute", right: "16em", top: "4.58em" }}>
								AFFICHER TOUS	
							</Button>
							</div>
						}

					/>
					{/* <CustomSelect values={mode} 
							style={{marginTop: '7px'}}
							value={modeSelected} 
							handleChange={handleChangeTypeValue}
						/> */}
					{/* <div style={{display: "flex", paddingTop: "1%", alignItems: "center", justifyContent: "space-between"}}>
							 <TextField
							placeholder="Rechercher par code"
								style={{marginTop: '7px'}}
								onChange={onChangeSearch} variant="outlined"
								size="small" onKeyPress={handleSearchKeyPress}
								helperText="Recherche à partir de 2 lettre"
								/>
							<IconButton type="button" 
								aria-label="search" 
								onClick={(e) => searchClick(e)}
								color="primary" 
								className={classes.customSearchButtonIcon}
								disabled = {!searchUser}>
								<SearchIcon />
							</IconButton> 
							
							 { isLoading ? (<CircularProgress size="1.8em" style={{marginLeft: '.5em', marginRight: '.5em'}}/>) : null} 
							<Tooltip title="Télécharger la liste">
								<Button variant={'contained'} className={classes.buttonModal} color={'primary'} onClick={() => exportToCsv(true)} disabled={searchUser.length <= 0}>
									<CloudDownloadIcon style={{marginRight: '.5em'}}/> TELECHARGER
									{ csvLoading ?
										(<CircularProgress color="secondary" style={{ display: 'block'}} style={{ width: "25px", height: "25px", marginLeft: ".5em"}}/>)
										: null
									}
								</Button>
							</Tooltip>
						</div> */}
					{/* </div> */}
					{ isLoading ? (<CircularProgress size="1.8em" style={{position:'absolute',top:'50%', left :'50%'}}/>) : null} 
					<div style={{ paddingTop: '8px' }}>
						<Paper elevation={3} >

							<ThemeProvider theme={getMuiTheme()}>
								{searchUser ?
									(<MUIDataTable data={searchUser}
										columns={columns} options={options} />)
									: null
								}
							</ThemeProvider>
						</Paper>
					</div>
				</DialogContent>
				<DialogActions>
					<Tooltip title="Désactiver les utilisateurs sélectionnés">
						<Button onClick={disableUsers} color="primary" variant="contained" className={classes.buttonCheck} disabled={rowSelected.length <= 0}>
							<CheckIcon className={classes.leftIcon} />
							Désactiver
						</Button>
					</Tooltip>
					<Button onClick={handleClose} color="secondary" variant="contained" className={classes.buttonCancel}>
						<CancelIcon className={classes.leftIcon} />
						Annuler
					</Button>
				</DialogActions>
			</Dialog>
			</ThemeProvider>
		</Grid>
		</div>
	)
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null
})

const mapDispatchToProps = ({
	onPromotionUpdate,
	onGetPromo,
	onPromotionSubscriberExist
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsUsers)