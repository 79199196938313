import React, { Component } from 'react'
import { fetchStart, GET_LIST, SimpleShowLayout } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { onPromotionUpdate, onGetPromo, onPromoApplyAllCatalogue } from '../../../actions/Promotion'

import AddIcon from '@material-ui/icons/Add'
import { Button } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MUIDataTable from 'mui-datatables'
import { createTheme, withStyles, ThemeProvider } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import cyan from '@material-ui/core/colors/cyan'
import { CloseIcon, CancelIcon, CheckIcon, VisibilityIcon, YoutubeSearchedForIcon, WarningIcon, EditIcon } from '../../../constants/icons'
import { InputLabel } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'

import dataProvider from '../../../dataProvider'
// import { DomainsItems } from '../../../requests'
import PromotionsAdd from './PromotionsAdd'
import PromotionsCheckBox from './PromotionsCheckBox'
import PromotionsProgrammes from './PromotionsProgrammes'
import XMLViewer from 'react-xml-viewer'
import { CustomTextField, CustomSelect, CustomDisplayDialog } from '../../layout/GlobalStyles'
import CompaniesAlbums from './../../../requests/CompaniesAlbums'
import { DomainsItems } from '../../../tools'

const styles = (theme) => ({
	borderRadius: 15,
	border: 1,
	borderColor: 'white',
	borderStyle: 'solid',
	color: 'white',
	padding: '10px',
	paddingBottom: '30px',
	margin: '15px',
	marginBottom: '30px',
	marginRight: '30px',
	chip: {
		color: '#fff',
		backgroundColor: '#288DB1',
		'&:hover': {
			backgroundColor: '#1c627b'
		},
		'&:active': {
			backgroundColor: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1'
		},
	},
	dialogHeader: {
		padding: '10px 10px 8px',
		background: cyan[500],
		color: 'white',
		fontSize: 'medium',
		fontWeight: 'bold',
		textAlign: 'center',
	},
	closeIcon: {
		width: '15px',
		position: 'absolute',
		top: '0px',
		right: '0px',
		borderRadius: '0',
		color: 'white',
		'&:hover': {
			background: 'red'
		}
	},
	buttonCheck: {
		margin: theme.spacing(1),
		width: '150px',
		background: '#0c9e0c'
	},
	buttonCancel: {
		width: '150px',
		background: '#e23434',
		margin: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	button: {
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		}
	},
	labels: {
		margin: '.5em .5em .5em .5em',
		color: '#288DB1',
		fontWeight: 'bold',
		fontSize: '13px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
	},
	switch: {
		float: "left",
		marginRight: ".5em"
	},
	switchMode: {
		padding: "0 0 1em .5em"
	},
	switchSelectAll: {
		position: "relative",
	},
	switchLabel: {
		color: '#111',
		fontSize: '15px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: 1,
		paddingTop: ".8em"
	},
	buttonRules: {
		backgroundColor: '#288DB1',
		maxHeight: "3em",
		width: '12em',
		padding: 0,
		'&:hover': {
			background: '#1c627b'
		},
		position: 'absolute',
		bottom: '3em',
		right: '3em',
	},
	badgeIcon: {
		fontSize: 18,
		marginTop: '.4em',
	},
	validateIcon: {
		backgroundColor: '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		margin: '.5em 0 .5em .5em'
	},
	alertIcon: {
		background: '#e23434',
		position: 'absolute',
		top: '35%',
		right: '3%'
	},
	validIcon: {
		background: '#0c9e0c',
		position: 'absolute',
		top: '35%',
		right: '3%'
	},
	warningIcon: {
		width: ".7em",
		height: "auto",
		marginBottom: "-0.15em",
		marginRight: "0.2em"
	},
	inputStyle: {
		marginBottom: "1.2em"
	},
	MultiinputStyle: {
		marginBottom: "0.2em"
	},
	card: {
		padding: ".5em 1em .5em 1em"
	}
})

const listStyle = {
	marginTop: 10,
	width: "91vw"
}

const listItemCustom = {
	paddingTop: '5px',
	paddingBottom: '5px',
	marginBottom: '-15px'
}

function getMatchingElement(value, data) {
	let matching
	data && data.forEach(element => {
		if (parseInt(element.itemid) === parseInt(value)) {
			matching = element
		}
	})
	if (matching === undefined) {
		matching = 0
	}
	return matching
}

class PromotionsRules extends Component {

	constructor(props) {
		super(props)
		this.onChangeRules = this.onChangeRules.bind(this)
		this.state = {
			rules: '<RULES><COUNT>0</COUNT><REBATE TYPEID="0" MODEID="0" MEDIA="0" DISTTECHNOLOGY="0">0</REBATE><TTL TYPEID="2" DURATION="" /><TARGETS /><MIDS /><AIDS /><PRICES /><BDDPIDS /><HAPILISTIDS /><USERQUALIFIER /><RECURRING/></RULES>',
			target: '',
			promoType: '0',
			promoValue: '0',
			consommation: '0',
			validity: '2',
			micro: '',
			diffusion: 10,
			media: 0,
			albumSearch: '',
			albumList: '',
			programmes: [],
			albumsList: [],
			bddpIdList: [],
			hapiList:[],
			programmeList: [],
			albumsDisplay: [],
			recStart: '',
			recEnd: '',
			initUse: 0,
			openAddProgrammes: false,
			openAddAlbums: false,
			openRules: false,
			searchMovies: [],
			isLoading: false,
			selectedAddedRows: [],
			programmesAdded: [],
			albumsAdded: [],
			searchValue: '',
			doublon: [],
			rowsSelected: [],
			mediaDisplay: [],
			debugOpen: false,
			nbDisplay: 0,
			nbAssign: 0,
			rebate: 0,
			applyToAllcatalog: false,
			applyAllDiffMode: false,
			applyAllMedia: false,
			applyAllMicro: false,
			updateBddpState: false,
			updateHapilistState: false,
			promoTypeList: [],
			consumptionList: [],
			validityList: [],
			microList: [],
			programmeSearchByList: false,
			textFieldValueProgrammeList: "",
			searchIdsNotExist: [],
			checkProgrammeIsLoading: false,
			textFieldValueProgrammeListError: "",
			infiniUserNumber: false,
			infiniAssociationNumber: false,
			nbAssignError: '',
			nbDisplayError: '',
			rebateError: '',
			nbUse :1
			
		}
	}

	componentDidMount = async () => {

		const { promotion, promotionApplyAllCatalogue } = this.props
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		this.setState({
			nbDisplay: xmlDoc.getElementsByTagName("COUNT")[0].childNodes[0].nodeValue,
			nbAssign: xmlDoc.getElementsByTagName("ASSIGN")[0] ? xmlDoc.getElementsByTagName("ASSIGN")[0].childNodes[0].nodeValue : 0,
			rebate: xmlDoc.getElementsByTagName("REBATE")[0].childNodes[0].nodeValue ? xmlDoc.getElementsByTagName("REBATE")[0].childNodes[0].nodeValue.replace(',','.') : 0,
			infiniAssociationNumber: xmlDoc.getElementsByTagName("ASSIGN")[0] && xmlDoc.getElementsByTagName("ASSIGN")[0].childNodes[0].nodeValue === '-1' ? true : false,
			infiniUserNumber: xmlDoc.getElementsByTagName("COUNT")[0].childNodes[0].nodeValue === '-1' ? true : false
		})

		if (promotionApplyAllCatalogue) {
			this.setState({ applyToAllcatalog: true })
		}
		if(xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MEDIA") === '67' ) this.setState({applyAllMedia:true})
		let promoTypeList =await  Object.values(DomainsItems.getItems(115))//await DomainsItems.getAll(115)
		let consumptionList = await Object.values(DomainsItems.getItems(116))//await DomainsItems.getAll(116)
		let validityList =await  Object.values(DomainsItems.getItems(117))//await DomainsItems.getAll(117)
		let microList =await Object.values(DomainsItems.getItems(140))//await DomainsItems.getAll(140)
		// 140 POUR LA PROD
		//DomainsItems.getAll(127)
		
		this.setState({ promoTypeList: promoTypeList })
		this.setState({ consumptionList: consumptionList })
		this.setState({ validityList: validityList })
		this.setState({ microList: microList })
	}
	componentDidUpdate (previousProp,previousState){
		const { promotion, create,onPromotionUpdate} = this.props
		if(!create && promotion !== previousProp.promotion){
			onPromotionUpdate(promotion)
			let parser = new DOMParser()
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			this.setState({
				nbDisplay: xmlDoc.getElementsByTagName("COUNT")[0].childNodes[0].nodeValue,
				nbAssign: xmlDoc.getElementsByTagName("ASSIGN")[0] ? xmlDoc.getElementsByTagName("ASSIGN")[0].childNodes[0].nodeValue : 0,
				rebate: xmlDoc.getElementsByTagName("REBATE")[0].childNodes[0].nodeValue ? xmlDoc.getElementsByTagName("REBATE")[0].childNodes[0].nodeValue.replace(',','.') : 0,
				infiniAssociationNumber: xmlDoc.getElementsByTagName("ASSIGN")[0] && xmlDoc.getElementsByTagName("ASSIGN")[0].childNodes[0].nodeValue === '-1' ? true : false,
				infiniUserNumber: xmlDoc.getElementsByTagName("COUNT")[0].childNodes[0].nodeValue === '-1' ? true : false
			})
			if( xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MEDIA") === '67') 
				this.setState({applyAllMedia: true})
		}
	}
	mediaInfos = { 1: 'SD', 2: 'HD', 64: 'UHD/4K' }
	// mediaInfos = { 1: 'SD', 2: 'HD', 16: 'DVD', 64: 'UHD/4K', 32: 'Bluray' }

	createList(create, listValue = null, type = null, valeur = null) {
		let liste = []
		if (listValue !== null || listValue.length !== 0) {

			switch (type) {
				case 'micro':
					if (listValue.includes(';')) {
						let splitValue = listValue.split(';')
						splitValue.forEach(element => {
							liste.push(<ListItem style={listItemCustom} button> <ListItemText primary={element} /> </ListItem>)
						});
					} else {
						liste.push(<ListItem style={listItemCustom} button> <ListItemText primary={listValue} /> </ListItem>)
					}
					break;

				default:
					for (let index = 0; index < listValue.length; index++) {
						let element = listValue[index].getAttribute('ID');

						liste.push(<ListItem style={listItemCustom} button> <ListItemText primary={element} /> </ListItem>)
					}
					break;
			}
		}
		return liste
	}

	updateState() {
		// this.setState({ rules: '<RULES><COUNT>'+this.state.nbDisplay+'</COUNT><ASSIGN>'+this.state.nbAssign+'</ASSIGN><REBATE TYPEID="' + this.state.promoType + '" MODEID="' + this.state.consommation + '" MEDIA="' + this.state.media + '" DISTTECHNOLOGY="' + this.state.diffusion + '">' + this.state.rebate + '</REBATE><TTL TYPEID="' + this.state.validity + '" />' +( this.state.target[0] ==='*' ?  '<TARGETS/>' : '<TARGETS>' +  this.state.target+ '</TARGETS>')+'<MIDS>' + this.state.programmeList + '</MIDS><AIDS>' + this.state.albumsList + '</AIDS> <PRICES /><BDDPIDS>' + this.state.bddpIdList + '</BDDPIDS><HAPILISTIDS>'+this.state.hapiList+'</HAPILISTIDS><USERQUALIFIER MICRO="' + this.state.micro + '"/><RECURRING /></RULES>' },

		this.setState({ rules: '<RULES><COUNT>'+this.state.nbDisplay+'</COUNT><ASSIGN>'+this.state.nbAssign+'</ASSIGN><NBUSE>'+this.state.nbUse+'</NBUSE><REBATE TYPEID="' + this.state.promoType + '" MODEID="' + this.state.consommation + '" MEDIA="' + this.state.media + '" DISTTECHNOLOGY="' + this.state.diffusion + '">' + this.state.rebate + '</REBATE><TTL TYPEID="' + this.state.validity + '" DURATION="'+ this.state.duration+'" /> <TARGETS>' +  this.state.target+ '</TARGETS><MIDS>' + this.state.programmeList + '</MIDS><AIDS>' + this.state.albumsList + '</AIDS> <PRICES /><BDDPIDS>' + this.state.bddpIdList + '</BDDPIDS><HAPILISTIDS>'+this.state.hapiList+'</HAPILISTIDS><USERQUALIFIER MICRO="' + this.state.micro + '"/><RECURRING /></RULES>' },
			() => {
				this.setState({ programmeList: [], albumsList: [] })
				this.updateProgrammesAdd()
			})
	}

	updateProgrammesAdd() {
		let parser = new DOMParser()
		let xmlDoc = parser.parseFromString(this.state.rules, "text/xml")

		if (this.state.programmesAdded && this.state.programmesAdded.length > 0) {
			this.setState({ programmesAdded: [...this.state.programmesAdded, ...xmlDoc.getElementsByTagName("MIDS")[0].getElementsByTagName("MID")] }, () => {
			})
		} else {
			this.setState({ programmesAdded: xmlDoc.getElementsByTagName("MIDS")[0].getElementsByTagName("MID") }, () => {
			})
		}

		if (this.state.albumsAdded && this.state.albumsAdded.length > 0) {
			this.setState({ albumsAdded: [...this.state.albumsAdded, ...xmlDoc.getElementsByTagName("AIDS")[0].getElementsByTagName("AID")] }, () => {
			})
		} else {
			this.setState({ albumsAdded: xmlDoc.getElementsByTagName("AIDS")[0].getElementsByTagName("AID") }, () => {
			})
		}
	}

	onChangeRules = (event, infos) => e => {
		let value
		switch (event) {
			case 'target':
				value = e.substring(0, e.length - 1)
 				this.setState({
					[event]: value
				}, () => {
					this.updateState()
				})

				break;

			case 'micro':
				this.setState({ micro: e }, () => {
					this.updateState()
				});
				break;

			case 'diffusion':
				if (e.target.checked) {
					let temp = this.state.diffusion
					temp = temp + parseInt(e.target.name, 10)
					this.setState({ [event]: temp }, () => {
						this.updateState()
					});
				} else {
					let temp = this.state.diffusion
					temp = temp - parseInt(e.target.name, 10)
					this.setState({ [event]: temp }, () => {
						this.updateState()
					});
				}
				break;
			case 'media':
				if (e.target.checked) {
					let temp = this.state.media
					temp = temp + parseInt(e.target.name, 10)
					this.setState({ [event]: temp }, () => {
						this.updateState()
					});
				} else {
					let temp = this.state.media
					temp = temp - parseInt(e.target.name, 10)
					this.setState({ [event]: temp }, () => {
						this.updateState()
					});
				}
				break;
			case 'albums':
				this.setState({ rowsSelected: [], selectedAddedRows: [], doublon: [], searchAlbums: [] })

				//check if albumId is link to a BDDP list
				let listAlbums = ''
				// let openInfo = false
				// let arr = []

				this.state.selectedAddedRows.forEach(index => {
					listAlbums += '<AID ID="' + this.state.searchAlbums[index].id + '" EXCLUDE="0" />'
				})

				this.setState({ albumsList: listAlbums }, () => {
					this.updateState()
					this.handleChangeAlbumsValue(listAlbums)
				})
				this.handleCloseAddAlbums()

				break
			case 'programmes':
				this.setState({ rowsSelected: [], selectedAddedRows: [], doublon: [] })
				let listProgrammes = ''

				if (this.state.programmeSearchByList) {
					this.state.searchMovies.forEach(movie => {
						listProgrammes += '<MID ID="' + movie.id + '" EXCLUDE="0" />'
					})

				} else {
					this.state.selectedAddedRows.forEach(index => {
						listProgrammes += '<MID ID="' + this.state.searchMovies[index].id + '" EXCLUDE="0" />'
					})
				}

				this.setState({ programmeList: listProgrammes }, () => {
					this.updateState()
					this.handleChangeProgrammesValue(listProgrammes)
				})
				this.handleCloseAddProgrammes()
				break;
			case 'bddp':
				let listBddpIds = ''
				this.state.bddpIdList.forEach(index => {
					listBddpIds += '<BDDPID ID="' + this.state.bddpIdList[index].id + '" EXCLUDE="0" />'
				})

				this.setState({ listBddpIds: listBddpIds }, () => {
					this.updateState()
					// this.handleChangeProgrammesValue(listProgrammes)
				})
				break;
			case 'hapi':
					let listhapiIds = ''
					this.state.listhapiIds.forEach(index => {
						listhapiIds += '<HAPILISTID ID="' + this.state.listhapiIds[index].id + '" EXCLUDE="0" />'
					})
	
					this.setState({ listhapiIds: listhapiIds }, () => {
						this.updateState()
						// this.handleChangeProgrammesValue(listProgrammes)
					})
					break;
			case 'recStart':
			case 'recEnd':
			case 'initUse':
				this.setState({ [event]: e }, () => {
					this.updateState()
				});
				break;
			default:
				value = e
				this.setState({
					[event]: value
				}, () => {
					this.updateState()
				})
				break;
		}
	}

	debugRules() {
		const { promotion } = this.props
		let display = []
		display.push(<XMLViewer xml={promotion.rules} style={{ backgroundColor: 'white', paddingLeft: '2em' }} />)
		return display
	}

	handleCloseAddProgrammes = () => {
		this.setState({ openAddProgrammes: false, isLoading: false, searchMovies: [], textFieldValueProgrammeList: "", searchIdsNotExist: [] })
	}

	handleClickOpenAddProgrammes = () => {
		this.setState({ openAddProgrammes: true, doublon: [], rowsSelected: [], selectedAddedRows: [], searchMovies: [] })
	}

	handleCloseAddAlbums = () => {
		this.setState({ openAddAlbums: false, isLoading: false, searchAlbums: [] })
	}

	handleClickOpenAddAlbums = () => {
		this.setState({ openAddAlbums: true, doublon: [], rowsSelected: [], selectedAddedRows: [], searchAlbums: [] })
	}

	handleCloseRules = () => {
		this.setState({ openRules: false })
	}

	handleClickOpenRules = () => {
		this.setState({ openRules: true })
	}

	handleChangeInput = (event) => {
		this.setState({ searchValue: event.target.value })
	}

	handleChangeTypeValue = (event) => {
		let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = this.props
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		const serializer = new XMLSerializer()
		let prom = promotion
		if (xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("TYPEID")) {
			xmlDoc.getElementsByTagName("REBATE")[0].setAttribute("TYPEID", event.target.value)
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	// handleChangeValidityValue = (event) => {
	// 	let parser = new DOMParser()

	// 	const { promotion, onPromotionUpdate } = this.props
	// 	let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
	// 	const serializer = new XMLSerializer()
	// 	let prom = promotion
	// 	console.log(typeof event.target.value)
	// 	if (xmlDoc.getElementsByTagName("TTL")[0].getAttribute("TYPEID")) {
	// 		if(event.target.value !== 3 && xmlDoc.getElementsByTagName("TTL")[0].getAttribute('DURATION') ){			 
	// 			xmlDoc.getElementsByTagName("TTL")[0].removeAttribute('DURATION')	 
	// 		}
	// 		xmlDoc.getElementsByTagName("TTL")[0].setAttribute("TYPEID", event.target.value)
	// 		prom.rules = serializer.serializeToString(xmlDoc)
	// 		onPromotionUpdate(prom)
	// 	}
	// }

	handleChangeConsumptionTypeValue = (event) => {
		let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = this.props
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		const serializer = new XMLSerializer()
		let prom = promotion
		if (xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MODEID")) {
			xmlDoc.getElementsByTagName("REBATE")[0].setAttribute("MODEID", event.target.value)
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	handleChangeProgrammesValue = (value) => {
		let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = this.props
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		const serializer = new XMLSerializer()
		let prom = promotion
		if (xmlDoc.getElementsByTagName("MIDS")[0]) {
			xmlDoc.getElementsByTagName("MIDS")[0].prepend(value)
			prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			onPromotionUpdate(prom)
		}
	}

	handleChangeAlbumsValue = (value) => {
		let parser = new DOMParser()

		const { promotion, onPromotionUpdate } = this.props
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		const serializer = new XMLSerializer()
		let prom = promotion
		if (xmlDoc.getElementsByTagName("AIDS")[0]) {
			xmlDoc.getElementsByTagName("AIDS")[0].prepend(value)
			prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
			onPromotionUpdate(prom)
		}
	}
	handleChangeNbDisplayValue = (event) => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()

		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion

		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		let textvalue = event.target.value.replace(/[^0-9]/g, '')
		if(textvalue === ''){
			this.setState({
				nbDisplay: textvalue !== '' ? textvalue : '',
				nbDisplayError: 'la valeur doit être un entier'
			})
		}
		else if (  textvalue >= -1) {
			if (xmlDoc.getElementsByTagName("COUNT")[0]) {
				xmlDoc.getElementsByTagName("COUNT")[0].childNodes[0].nodeValue = textvalue !== '' ? textvalue : 0
				prom.rules = serializer.serializeToString(xmlDoc)
				this.setState({
					nbDisplay: textvalue !== '' ? textvalue : '',
					//infiniUserNumber: event.target.value === '-1' ? false : true,
					nbDisplayError: ''
				})
				onPromotionUpdate(prom)
			}
		}
		// else
		// 	this.setState({ nbDisplayError: "la valeur doit être supérieur ou égale à -1" })
	}

	handleChangenbAssignValue = (event) => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()
		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		let textvalue = event.target.value.replace(/[^0-9]/g, '')
		if(textvalue === ''){
			this.setState({
				nbAssign: textvalue !== '' ? textvalue : '',
				nbAssignError: 'la valeur doit être un entier'
			})
		}
		else if ( (textvalue) >= -1) {
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			if (xmlDoc.getElementsByTagName("ASSIGN")[0]) {
				xmlDoc.getElementsByTagName("ASSIGN")[0].childNodes[0].nodeValue = textvalue !== '' ? textvalue : 0
			}
			else {
				let newEle = xmlDoc.createElement("ASSIGN")
				let newText = xmlDoc.createTextNode(textvalue !== '' ? textvalue : 0)
				newEle.appendChild(newText)
				xmlDoc.getElementsByTagName("RULES")[0].appendChild(newEle)
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
			this.setState({
				nbAssign: textvalue !== '' ? textvalue : '',
				//infiniAssociationNumber: event.target.value === '-1' ? false : true,
				nbAssignError: ''
			})
		}
		 
	}
	handleChangeRebateValue = (event) => {
		let parser = new DOMParser()
		const serializer = new XMLSerializer()

		const { promotion, onPromotionUpdate } = this.props
		let prom = promotion
		let rebatevalue = event.target.value //.replace('.',',')
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		let promotype = getMatchingElement(xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("TYPEID"), this.state.promoTypeList)
		if(parseInt(rebatevalue) > 100 && promotype.itemid === 1 ){
			this.setState({rebateError: 'La valeur doit étre inferieur ou égale à 100'})
		}
		else //if(rebatevalue !== '')
		{if (xmlDoc.getElementsByTagName("REBATE")[0]) {
			xmlDoc.getElementsByTagName("REBATE")[0].childNodes[0].nodeValue = rebatevalue !== '' ? rebatevalue : 0
			prom.rules = serializer.serializeToString(xmlDoc)
			this.setState({ rebate: event.target.value })
			onPromotionUpdate(prom)
			this.setState({rebateError: ''})

		}}
		// else{
		// 	this.setState({ rebate: event.target.value })
		// 	this.setState({ rebateError: 'La valeur doit étre inferieur ou égale à 100'})
		// }
	}

	getMuiTheme = () => createTheme({
		overrides: {
			MuiPaper: {
				elevation4: {
					boxShadow: 'none'
				}
			},
			MUIDataTableToolbarSelect: {
				root: {
					display: "none !important"
				}
			}
		},
	})

	updateAddedProgrammes = (value) => {
		this.setState({ programmesAdded: value })
	}

	updateAddedAlbums = (value) => {
		this.setState({ albumsAdded: value })
	}

	updateDiffusionDisplay = (boolStreaming, boolDll) => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		if (boolStreaming & !boolDll) {
			this.setState({ diffusion: 1, applyAllDiffMode: false })
			this.applyToStoreDiffusion(xmlDoc, prom, 1)
		} else if (!boolStreaming && boolDll) {
			this.setState({ diffusion: 2, applyAllDiffMode: false })
			this.applyToStoreDiffusion(xmlDoc, prom, 2)
		} else if (!boolStreaming && !boolDll) {
			this.setState({ diffusion: 0, applyAllDiffMode: false })
			this.applyToStoreDiffusion(xmlDoc, prom, 0)
		} else if (boolStreaming && boolDll) {
			this.setState({ diffusion: 3, applyAllDiffMode: true })
			this.applyToStoreDiffusion(xmlDoc, prom, 3)
		}
	}

	applyToStoreDiffusion = (xmlDoc, prom, value) => {
		const { onPromotionUpdate } = this.props

		const serializer = new XMLSerializer()
		if (xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("DISTTECHNOLOGY")) {
			xmlDoc.getElementsByTagName("REBATE")[0].setAttribute("DISTTECHNOLOGY", value)
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
		}
	}

	applyToStoreMedia = (xmlDoc, prom, value) => {
		const { onPromotionUpdate } = this.props
		const serializer = new XMLSerializer()
		if (xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MEDIA")) {
			xmlDoc.getElementsByTagName("REBATE")[0].setAttribute("MEDIA", value)
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)
			if (value === 67)
				this.setState({ applyAllMedia: true })
			else
				this.setState({ applyAllMedia: false })
		}
	}
	applyToStoreMicro = (xmlDoc, prom, value) => {
		const { onPromotionUpdate } = this.props

		const serializer = new XMLSerializer()
		if (value !== "") {
			xmlDoc.getElementsByTagName("USERQUALIFIER")[0].setAttribute("MICRO", value)
		} else {
			if (xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO"))
				xmlDoc.getElementsByTagName("USERQUALIFIER")[0].removeAttribute("MICRO")
		}

		prom.rules = serializer.serializeToString(xmlDoc)
		onPromotionUpdate(prom)
	}

	handleChange = () => {
		this.setState({ debugOpen: !this.state.debugOpen })
	}

	applyToAllDiffModeItems = () => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		this.setState({ applyAllDiffMode: true })
		this.applyToStoreDiffusion(xmlDoc, prom, 3)
	}

	deselectAllDiffModeItems = () => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		this.setState({ applyAllDiffMode: false })
		this.applyToStoreDiffusion(xmlDoc, prom, 0)
	}
	checkInfiniNbAssign = (event) => {
		const { promotion, create, onPromotionUpdate } = this.props
		if (create) {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let prom = promotion
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			if (xmlDoc.getElementsByTagName("ASSIGN")[0]) {
				xmlDoc.getElementsByTagName("ASSIGN")[0].childNodes[0].nodeValue = event.target.checked ? '-1' : this.state.nbAssign
			}
			else {
				let newEle = xmlDoc.createElement("ASSIGN")
				let newText = xmlDoc.createTextNode(event.target.checked ? '-1' : this.state.nbAssign)
				newEle.appendChild(newText)
				xmlDoc.getElementsByTagName("RULES")[0].appendChild(newEle)
			}
			prom.rules = serializer.serializeToString(xmlDoc)
			onPromotionUpdate(prom)

			this.setState({
				nbAssign: event.target.checked ? '-1' : 0,
				infiniAssociationNumber: event.target.checked,
				nbAssignError: ''
			})
		}

	}
	checkInfiniNbDisplay = (event) => {
		const { promotion, create, onPromotionUpdate } = this.props
		if (create) {
			let parser = new DOMParser()
			const serializer = new XMLSerializer()
			let prom = promotion

			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			if (xmlDoc.getElementsByTagName("COUNT")[0]) {
				xmlDoc.getElementsByTagName("COUNT")[0].childNodes[0].nodeValue = event.target.checked ? -1 : this.state.nbDisplay
				prom.rules = serializer.serializeToString(xmlDoc)
				onPromotionUpdate(prom)
			}
			this.setState({
				nbDisplay: event.target.checked ? '-1' : 0,
				infiniUserNumber: event.target.checked,
				nbDisplayError: ''
			})
		}

	}
	applyToAllMediaItems = () => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		this.applyToStoreMedia(xmlDoc, prom, 67)
		this.setState({ applyAllMedia: true })

	}

	deselectAllMediaItems = () => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		this.setState({ applyAllMedia: false })
		this.applyToStoreMedia(xmlDoc, prom, 0)
	}

	applyToAllMicroItems = () => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		let listMicro = []
		this.state.microList.forEach(micro => {
			let xmlvalue = parser.parseFromString(micro.properties, "text/xml")
			listMicro.push(xmlvalue.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")[0].getAttribute("VALUE"))
		})

		let valueAllMicro = listMicro.join(";")

		this.applyToStoreMicro(xmlDoc, prom, valueAllMicro)
		this.setState({ applyAllMicro: true })
	}

	deselectAllMicroItems = () => {
		const { promotion } = this.props
		let parser = new DOMParser()
		let prom = promotion
		let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

		this.setState({ applyAllMicro: false })
		this.applyToStoreMicro(xmlDoc, prom, "")
	}

	
	render() {
		const { source, classes, withAll, create, type, info, promotion, controlField, ...rest } = this.props
		const promoTypeList = this.state.promoTypeList
		const consumptionList = this.state.consumptionList
		const validityList = this.state.validityList
		const options = {
			rowsPerPage: 10,
			rowHover: false,
			textLabels: {
				body: {
					noMatch: 'Aucune ligne trouvée',
					toolTip: 'Trier'
				},
			},
			setTableProps: () => {
				return {
					padding: 'normal',
					size: 'small',
				}
			},
			resizableColumns: false,
			print: false,
			download: false,
			search: false,
			filter: false,
			viewColumns: false,
			filterType: 'multiselect',
			selectableRowsOnClick: true,
			selectableRows: 'multiple',
			fixedSelectColumn: true,
			customToolbar: false,
			rowsSelected: this.state.rowsSelected,
			onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
				this.setState({ rowsSelected: rowsSelected })
				this.setState({ selectedAddedRows: rowsSelected })
			},
			isRowSelectable: (dataIndex) => {
				if (this.state.searchAlbums && this.state.searchAlbums.length > 0) {
					if (this.state.doublon.includes(this.state.searchAlbums[dataIndex] && this.state.searchAlbums[dataIndex].id))
						return false
					return true
				}
				if (this.state.searchMovies && this.state.searchMovies.length > 0) {
					if (this.state.doublon.includes(this.state.searchMovies[dataIndex].id))
						return false
					return true
				}
			},
		}
		let parser = new DOMParser()
		let xmlDoc, promoType, consumptionType, validityType, medias, albums, microEligibiliter, diffusion, bddp, hapi,validityDuration
		let promoTypeDisplay, promoValueDisplay, consumptionTypeDisplay, validityTypeDisplay, programmeNbDisplay, associateNbAssign
		let diffusionDisplay = []
		let mediaDisplay = []
		let programmes = []
		let microDisplay = []
		let microSelected = []

		const searchCompaniesMovies = (e) => {
			e.preventDefault()
			this.setState({ doublon: [] })

			if (this.state.searchValue.includes(';')) {

				this.setState({ searchMovies: [] }, () => {
					//let split = this.state.searchValue.split(';')
					let split = encodeURIComponent(this.state.searchValue).toString()

					fetchStart()
					this.setState({ isLoading: true }, () => {
						dataProvider(GET_LIST,
							"Companiesmovies",
							{
								pagination: { page: 1, perPage: 100 },
								sort: { field: {}, order: {} },
								filter: { q: split, mode: 1024 }
							}).then(resp => {
								if (!this.isUnmounted) {
									let element = []

									for (let index = 0; index < programmes.length; index++) {
										element = [...element, parseInt(programmes[index].getAttribute('ID'))]
									}

									if (this.state.programmesAdded !== undefined) {
										for (let index = 0; index < this.state.programmesAdded.length; index++) {
											element = [...element, parseInt(this.state.programmesAdded[index].getAttribute('ID'))]
										}
									}

									resp.data.forEach((r, i) => {
										if (this.state.searchMovies && this.state.searchMovies.map(m => m.id).includes(r.id) === false) {

											if (element.includes(r.id)) {
												// resp.data.splice(i, 1)
												this.setState({ doublon: [...this.state.doublon, r.id] })
											} else {
												this.setState({ searchMovies: [...this.state.searchMovies, r] })
											}
										}
									})

									this.setState({ isLoading: false })
								}
							})
					})
				})
			} else {
				let mode = 2
				if (Number.isInteger(parseInt(this.state.searchValue)))
					mode = 1

				fetchStart()
				this.setState({ isLoading: true }, () => {
					dataProvider(GET_LIST,
						"Companiesmovies",
						{
							pagination: { page: 1, perPage: 100 },
							sort: { field: {}, order: {} },
							filter: { q: this.state.searchValue, mode: mode }
						}).then(resp => {

							if (!this.isUnmounted) {
								let element = []

								if (programmes !== undefined) {
									for (let index = 0; index < programmes.length; index++) {
										element = [...element, parseInt(programmes[index].getAttribute('ID'))]
									}
								}

								if (this.state.programmesAdded !== undefined) {
									for (let index = 0; index < this.state.programmesAdded.length; index++) {
										element = [...element, parseInt(this.state.programmesAdded[index].getAttribute('ID'))]
									}
								}

								let arr = JSON.parse(JSON.stringify(resp.data));

								resp.data.forEach((s) => {
									if (element.includes(s.id)) {
										// let i = arr.map(a => { return a.id; }).indexOf(s.id);
										// arr.splice(i, 1)
										this.setState({ doublon: [...this.state.doublon, s.id] })
									}
								})
								this.setState({ searchMovies: arr })
								this.setState({ isLoading: false })
							}
						})
				})
			}
		}
		const searchAlbums = (e) => {
			e.preventDefault()
			this.setState({ doublon: [] })
			if (this.state.searchValue.includes(';')) {
				let split = this.state.searchValue.split(';')
				fetchStart()
				this.setState({ searchAlbums: [], isLoading: true }, () => {
					split.forEach(s => {
						if (Number.isInteger(parseInt(s))) {
							dataProvider(GET_LIST,
								"Companiesalbums/Search",
								{
									pagination: { page: 1, perPage: 100 },
									sort: { field: {}, order: {} },
									filter: { q: s, mode: 2 }
								}).then(resp => {
									if (!this.isUnmounted) {
										let element = []

										for (let index = 0; index < albums.length; index++) {
											element = [...element, parseInt(albums[index].getAttribute('ID'))]
										}

										if (this.state.albumsAdded !== undefined) {
											for (let index = 0; index < this.state.albumsAdded.length; index++) {
												element = [...element, parseInt(this.state.albumsAdded[index].getAttribute('ID'))]
											}
										}

										let arr = JSON.parse(JSON.stringify(resp.data))
										let finalArr = []

										resp.data.forEach((s) => {
											if (element.includes(s.id)) {
												// let i = arr.map(a => { return a.id; }).indexOf(s.id);
												// arr.splice(i, 1)
												this.setState({ doublon: [...this.state.doublon, s.id] })
											}
										})

										arr.forEach(value => {
											let parser = new DOMParser()
											let xmlDocRes = parser.parseFromString(value.properties, "text/xml")
											let propsArray = xmlDocRes.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")
											if (propsArray.length > 0) {
												for (let i = 0; i < propsArray.length; i++) {
													if (propsArray[i].getAttribute("NAME") === "BDDP_ID") {
														if (parseInt(propsArray[i].getAttribute("VALUE")) > 0) {
															value.bddpid = parseInt(propsArray[i].getAttribute("VALUE"))
															finalArr.push(value)
														}
													}
												}
											}
										})
										this.state.searchAlbums.forEach(s => {
											finalArr.push(s)
										})
										this.setState({
											searchAlbums: finalArr.sort(function (a, b) {
												return (a.id - b.id)
											})
										})
										this.setState({ isLoading: false })
									}
								})
						}
					})
				})
			} else {
				let mode = 1
				if (Number.isInteger(parseInt(this.state.searchValue)))
					mode = 2

				fetchStart()
				this.setState({ isLoading: true }, () => {
					dataProvider(GET_LIST,
						"Companiesalbums/Search",
						{
							pagination: { page: 1, perPage: 100 },
							sort: { field: {}, order: {} },
							filter: { q: this.state.searchValue, mode: mode }
						}).then(resp => {

							if (!this.isUnmounted) {

								let element = []

								if (albums !== undefined) {
									for (let index = 0; index < albums.length; index++) {
										element = [...element, parseInt(albums[index].getAttribute('ID'))]
									}
								}

								if (this.state.albumsAdded !== undefined) {
									for (let index = 0; index < this.state.albumsAdded.length; index++) {
										element = [...element, parseInt(this.state.albumsAdded[index].getAttribute('ID'))]
									}
								}

								let arr = JSON.parse(JSON.stringify(resp.data))
								let finalArr = []

								resp.data.forEach((s) => {
									if (element.includes(s.id)) {
										// let i = arr.map(a => { return a.id; }).indexOf(s.id);
										// arr.splice(i, 1)
										this.setState({ doublon: [...this.state.doublon, s.id] })
									}
								})

								arr.forEach(value => {
									let parser = new DOMParser()
									let xmlDocRes = parser.parseFromString(value.properties, "text/xml")
									let propsArray = xmlDocRes.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")
									if (propsArray.length > 0) {
										for (let i = 0; i < propsArray.length; i++) {
											if (propsArray[i].getAttribute("NAME") === "BDDP_ID") {
												if (parseInt(propsArray[i].getAttribute("VALUE")) > 0) {
													value.bddpid = parseInt(propsArray[i].getAttribute("VALUE"))
													finalArr.push(value)
												}
											}
										}
									}
								})
								this.setState({ searchAlbums: finalArr })
								this.setState({ isLoading: false })
							}
						})
				})
			}
		}

		const createCheckBox = (label = null, checked = false, create = false, media = 0) => {
			if (create) {
				return checked ? <Chip className={classes.chip} label={label} style={{ margin: '.5em' }} onClick={() => updateMediaDisplay(label, true, media)} />
					: <Chip color="default" label={label} style={{ margin: '.5em' }} onClick={() => updateMediaDisplay(label, false, media)} />
			} else {
				return checked ? <Chip className={classes.chip} label={label} style={{ margin: '.5em' }} />
					: <Chip color="default" label={label} style={{ margin: '.5em' }} />
			}
		}

		const createMedias = (media, create, activated) => {
			const typeMedia = ['SD', 'HD', 'UHD/4K']
			// const typeMedia = ['SD', 'HD', 'DVD','Bluray', 'UHD/4K']
			let mediaDisplay = []
			let mediaValue = []
			let listMedias = []
			//Create and order value array that will be use to check if the number is inside
			for (var key in this.mediaInfos) {
				mediaValue.push([key])
			}
			mediaValue.sort(function (a, b) {
				return b - a;
			})

			let temp = media

			for (let index = 0; index < mediaValue.length; index++) {
				if ((temp - mediaValue[index]) >= 0) {
					temp = temp - mediaValue[index]
					listMedias.push(parseInt(mediaValue[index], 10))
				}
			}

			typeMedia.forEach(element => {
				let created = false

				listMedias.forEach(mediaChecked => {
					if (element === this.mediaInfos[mediaChecked] && !created) {
						mediaDisplay.push(createCheckBox(element, true, create, media))
						created = true
					}
				})
				if (!created) {
					mediaDisplay.push(createCheckBox(element, false, create, media))
				}
			})
			return mediaDisplay
		}

		const createMicro = (micro) => {
			let parser = new DOMParser()
			const typeMicro = this.state.microList
			let microDisplay = []
			let microSelected = micro ? micro.split(";") : ""
			if (create) {
				typeMicro.forEach(element => {
					let xmlvalue = parser.parseFromString(element.properties, "text/xml")
					let microValue = xmlvalue.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")[0].getAttribute("VALUE")

					if (microSelected.includes(microValue)) {
						microDisplay.push(<Chip className={classes.chip} label={element.label} style={{ margin: '.5em' }} onClick={() => updateMicroDisplay(element, false)} />)
					} else {
						microDisplay.push(<Chip color="default" label={element.label} style={{ margin: '.5em' }} onClick={() => updateMicroDisplay(element, true)} />)
					}
				})

			} else {
				typeMicro && typeMicro.forEach(element => {
					let xmlvalue = parser.parseFromString(element.properties, "text/xml")
					let microValue = xmlvalue.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")[0].getAttribute("VALUE")

					if (microSelected.includes(microValue)) {
						microDisplay.push(<Chip className={classes.chip} label={element.label} style={{ margin: '.5em' }} />)
					} else {
						microDisplay.push(<Chip color="default" label={element.label} style={{ margin: '.5em' }} />)
					}
				})
			}

			return microDisplay
		}
		const updateMediaDisplay = (label, activated, media) => {
			const { promotion } = this.props
			let parser = new DOMParser()
			let prom = promotion
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

			for (var key in this.mediaInfos) {
				if (this.mediaInfos[key] === label) {
					let newMedia = 0
					if (activated)
						newMedia = parseInt(media) - parseInt(key)
					else
						newMedia = parseInt(media) + parseInt(key)
					this.setState({ mediaDisplay: createMedias(newMedia, true, activated) })
					this.applyToStoreMedia(xmlDoc, prom, newMedia)
				}
			}
			// if (media === 64) {
			// 	this.setState({ applyAllMedia: true })
			// } else {
			// 	this.setState({ applyAllMedia: false })
			// }
		}

		const updateMicroDisplay = (element, activated) => {
			const { promotion } = this.props
			let parser = new DOMParser()
			let prom = promotion
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
			let micro = xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO")
			let microSelected = micro ? micro.split(";") : []

			let xmlvalue = parser.parseFromString(element.properties, "text/xml")
			let microValue = xmlvalue.getElementsByTagName("PROPS")[0].getElementsByTagName("PROP")[0].getAttribute("VALUE")

			if (activated) {
				microSelected.push(microValue)
			} else {
				let index = microSelected.indexOf(microValue)
				if (index > -1) {
					microSelected.splice(index, 1)
				}
			}
			this.applyToStoreMicro(xmlDoc, prom, microSelected.join(";"))

			if (this.state.microList.length === microSelected.length) {
				this.setState({ applyAllMicro: true })
			} else {
				this.setState({ applyAllMicro: false })
			}

			// let obj = {RCPL_TVOD: false, CP_ALD: false}

			// if(microSelected.includes(MicroEligibilityType.RCPL_TVOD))
			// 	obj.RCPL_TVOD = true
			// if(microSelected.includes(MicroEligibilityType.CP_ALD))
			// 	obj.CP_ALD = true

			// if(activated) {
			// 	if(label === MicroEligibilityType.RCPL_TVOD)
			// 		obj.RCPL_TVOD = true
			// 	else if(label === MicroEligibilityType.CP_ALD)
			// 		obj.CP_ALD = true
			// } else {
			// 	if(label === MicroEligibilityType.RCPL_TVOD)
			// 		obj.RCPL_TVOD = false
			// 	else if(label === MicroEligibilityType.CP_ALD)
			// 		obj.CP_ALD = false
			// }

			// if(obj.RCPL_TVOD && obj.CP_ALD) {
			// 	this.applyToStoreMicro(xmlDoc, prom, MicroEligibilityType.RCPL_TVOD + ";" + MicroEligibilityType.CP_ALD)
			// 	this.setState({ applyAllMicro: true })
			// }
			// else if(obj.RCPL_TVOD && !obj.CP_ALD) {
			// 	this.applyToStoreMicro(xmlDoc, prom, MicroEligibilityType.RCPL_TVOD)
			// 	this.setState({ applyAllMicro: false })
			// }
			// else if(!obj.RCPL_TVOD && obj.CP_ALD) {
			// 	this.applyToStoreMicro(xmlDoc, prom, MicroEligibilityType.CP_ALD)
			// 	this.setState({ applyAllMicro: false })
			// }
			// else if(!obj.RCPL_TVOD && !obj.CP_ALD) {
			// 	this.setState({ applyAllMicro: false })
			// 	this.applyToStoreMicro(xmlDoc, prom, "")
			// }
		}

		const handleApplyAllCatalogChange = () => {
			const { onPromoApplyAllCatalogue } = this.props

			this.setState({ applyToAllcatalog: !this.state.applyToAllcatalog }, () => {
				onPromoApplyAllCatalogue(this.state.applyToAllcatalog)
			})

			let parser = new DOMParser()

			const { promotion, onPromotionUpdate } = this.props
			let xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

			const serializer = new XMLSerializer()
			let prom = promotion

			if (xmlDoc.getElementsByTagName("MIDS")[0]) {
				let v = xmlDoc.getElementsByTagName("MIDS")[0]
				while (v.lastElementChild)
					v.removeChild(v.lastElementChild)
				prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
				onPromotionUpdate(prom)
				this.updateAddedProgrammes([])
			}

			if (xmlDoc.getElementsByTagName("AIDS")[0]) {
				let v = xmlDoc.getElementsByTagName("AIDS")[0]
				while (v.lastElementChild)
					v.removeChild(v.lastElementChild)
				prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
				onPromotionUpdate(prom)
				this.updateAddedAlbums([])
			}

			if (xmlDoc.getElementsByTagName("BDDPIDS")[0]) {
				const v = xmlDoc.getElementsByTagName("BDDPIDS")[0]
				while (v.lastElementChild)
					v.removeChild(v.lastElementChild)
				prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
				onPromotionUpdate(prom)
				this.setState({ updateBddpState: true })
			}

			if (xmlDoc.getElementsByTagName("HAPILISTIDS")[0]) {
				const v = xmlDoc.getElementsByTagName("HAPILISTIDS")[0]
				while (v.lastElementChild)
					v.removeChild(v.lastElementChild)
				prom.rules = serializer.serializeToString(xmlDoc).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
				onPromotionUpdate(prom)
				this.setState({ updateHapilistState: true })
			}
		}

		const handleSearchMovieKeyPress = (event) => {
			if (event.key === 'Enter') {
				searchCompaniesMovies(event)
			}
		}

		const handleSearchAlbumsKeyPress = (event) => {
			if (event.key === 'Enter') {
				searchAlbums(event)
			}
		}

		const handleChangeModeSwitch = () => {
			this.setState({ programmeSearchByList: !this.state.programmeSearchByList, searchMovies: [], textFieldValueProgrammeList: "", searchIdsNotExist: [] })
		}
		const ChangeModeSwitch = () => (
			<FormControl component="fieldset" className={classes.switchMode}>
				<FormControlLabel
					value="top"
					control={
						<Switch checked={this.state.programmeSearchByList}
							onChange={handleChangeModeSwitch}
							color="primary" size="small"
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					}
					label={<span style={{ fontSize: '.8em', marginLeft: '.5em' }}>Passer en mode <b>injection d'une liste</b> ?</span>}
					labelPlacement="right"
				/>
			</FormControl>
		)

		const handleTextFieldValueProgrammeList = (event) => {
			this.setState({ textFieldValueProgrammeList: event.target.value, searchMovies: [], searchIdsNotExist: [], textFieldValueProgrammeListError: "" })
		}

		const handleClickCheckIdsPromoExist = () => {
			this.setState({ checkProgrammeIsLoading: true })

			let tempTextFieldValueProgrammeList = this.state.textFieldValueProgrammeList.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, '')
			let arr = this.state.textFieldValueProgrammeList.split('\n')
				.filter((el) => {
					return el !== null && el !== ""
				})

			if (this.state.textFieldValueProgrammeList !== tempTextFieldValueProgrammeList) {
				this.setState({ textFieldValueProgrammeListError: "La saisie ne respecte pas les règles ci dessous." })
			} else {
				CompaniesAlbums.getMoviesbyIds(arr).then((json) => {
					if (json.data !== undefined) {
						let notExist = []
						arr.forEach(id => {
							if (!json.data.map(p => p.id).includes(parseInt(id)))
								notExist.push(id)
						})

						this.setState({ searchIdsNotExist: notExist, searchMovies: json.data, checkProgrammeIsLoading: false, textFieldValueProgrammeListError: "" })
					}
				})
			}

			this.setState({ checkProgrammeIsLoading: false })

			// this.state.textFieldValueProgrammeList.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').split('\n').forEach(v => {
			// 	if(Number.isInteger(parseInt(v)))
			// 		arr.push(parseInt(v))
			// })


		}

		const handleClickChangeMode = () => {
			let newTextField = ""
			if (this.state.searchIdsNotExist.length > 0)
				newTextField = this.state.searchIdsNotExist.join('\n') + '\n' + this.state.searchMovies.map(m => m.id).join('\n')
			else
				newTextField = this.state.searchMovies.map(m => m.id).join('\n')
			this.setState({ searchMovies: [], textFieldValueProgrammeList: newTextField })
		}
		xmlDoc = parser.parseFromString(promotion.rules, "text/xml")
		promoType = getMatchingElement(xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("TYPEID"), promoTypeList)
		medias = xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MEDIA")
		//validityType = getMatchingElement(xmlDoc.getElementsByTagName("TTL")[0].getAttribute("TYPEID"), validityList)
		consumptionType = getMatchingElement(xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("MODEID"), consumptionList)
		bddp = xmlDoc.getElementsByTagName("BDDPIDS")[0].getElementsByTagName("BDDPID")
		hapi = xmlDoc.getElementsByTagName("HAPILISTIDS")[0] ? xmlDoc.getElementsByTagName("HAPILISTIDS")[0].getElementsByTagName("HAPILISTID") : ''
		albums = xmlDoc.getElementsByTagName("AIDS")[0].getElementsByTagName("AID")
		microEligibiliter = xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO")
		programmes = xmlDoc.getElementsByTagName("MIDS")[0].getElementsByTagName("MID")
		promoValueDisplay = <CustomTextField label={promoType.itemid === 3 ? "Prix promotion" : "Réduction par programme"} textAdornment={promoType.itemid === 1 ? '%' : '€'} type="number" value={this.state.rebate} fullWidth="true" handleChange={this.handleChangeRebateValue} create={create} errorLabel={this.state.rebateError} />
		programmeNbDisplay = <CustomTextField label="Nombre de transactions" type="number" value={this.state.nbDisplay} fullWidth="true" handleChange={this.handleChangeNbDisplayValue} create={create} readOnly={this.state.infiniUserNumber} errorLabel={this.state.nbDisplayError} />
		associateNbAssign = <CustomTextField label="Nombre d'associations" type="number" value={this.state.nbAssign} fullWidth="true" handleChange={this.handleChangenbAssignValue} readOnly={this.state.infiniAssociationNumber} create={create} errorLabel={this.state.nbAssignError} />
		diffusion = xmlDoc.getElementsByTagName("REBATE")[0].getAttribute("DISTTECHNOLOGY")
		if (create !== true) {
			promoTypeDisplay = <CustomTextField label="Type de réduction" value={promoType.label} fullWidth="true" />
			consumptionTypeDisplay = <CustomTextField label="Mode de consommation" value={consumptionType.label} fullWidth="true" />
			// validityTypeDisplay = <CustomTextField label="Validité" value={validityType.label} fullWidth="true" />
 


			//Setup display diffusion
			if (parseInt(diffusion) === 1) {
				diffusionDisplay.push(<Chip className={classes.chip} label="Streaming" style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip color="default" label="Download" style={{ margin: '.5em' }} />)
			} else if (parseInt(diffusion) === 2) {
				diffusionDisplay.push(<Chip color="default" label="Streaming" style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip className={classes.chip} label="Download" style={{ margin: '.5em' }} />)
			} else if (parseInt(diffusion) === 0) {
				diffusionDisplay.push(<Chip color="default" label="Streaming" style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip color="default" label="Download" style={{ margin: '.5em' }} />)
			} else {
				diffusionDisplay.push(<Chip className={classes.chip} label="Streaming" style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip className={classes.chip} label="Download" style={{ margin: '.5em' }} />)
			}

			mediaDisplay.push(createMedias(medias))
			microDisplay.push(createMicro(microEligibiliter))

		} else {
			xmlDoc = parser.parseFromString(promotion.rules, "text/xml")

			if (type === 'edit' || type === 'create') {
				if (xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO") !== undefined) {
					microEligibiliter = xmlDoc.getElementsByTagName("USERQUALIFIER")[0].getAttribute("MICRO")
					microSelected = microEligibiliter ? microEligibiliter.split(";") : []

				}
				if (xmlDoc.getElementsByTagName("AIDS")[0].getElementsByTagName("AID") !== undefined) {
					albums = xmlDoc.getElementsByTagName("AIDS")[0].getElementsByTagName("AID")
				}
				if (xmlDoc.getElementsByTagName("MIDS")[0].getElementsByTagName("MID") !== undefined) {
					programmes = xmlDoc.getElementsByTagName("MIDS")[0].getElementsByTagName("MID")
				}
			}

			promoTypeDisplay = <CustomSelect values={promoTypeList} propsType="domainItem" required={true} value={promoType ? Number.parseInt(promoType.itemid) : null} label="Type de réduction" fullWidth="true" handleChange={this.handleChangeTypeValue} errorLabel={controlField && controlField.promotype !== "" ? controlField.promotype : null} />
			consumptionTypeDisplay = <CustomSelect values={consumptionList} propsType="domainItem" value={consumptionType ? Number.parseInt(consumptionType.itemid) : null} required={true} label="Mode de consommation" fullWidth="true" handleChange={this.handleChangeConsumptionTypeValue} errorLabel={controlField && controlField.consumptiontype !== "" ? controlField.consumptiontype : null} />
			// validityTypeDisplay = <CustomSelect values={validityList} propsType="domainItem" value={validityType ? Number.parseInt(validityType.itemid) : null} required={true} label="Validité" fullWidth="true" handleChange={this.handleChangeValidityValue} errorLabel={controlField && controlField.validitytype !== "" ? controlField.validitytype : null} />

			if (parseInt(diffusion) === 1) {
				diffusionDisplay.push(<Chip className={classes.chip} label="Streaming" onClick={() => this.updateDiffusionDisplay(false, false)} style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip color="default" label="Download" onClick={() => this.updateDiffusionDisplay(true, true)} style={{ margin: '.5em' }} />)
			} else if (parseInt(diffusion) === 2) {
				diffusionDisplay.push(<Chip color="default" label="Streaming" onClick={() => this.updateDiffusionDisplay(true, true)} style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip className={classes.chip} label="Download" onClick={() => this.updateDiffusionDisplay(false, false)} style={{ margin: '.5em' }} />)
			} else if (parseInt(diffusion) === 0) {
				diffusionDisplay.push(<Chip color="default" label="Streaming" onClick={() => this.updateDiffusionDisplay(true, false)} style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip color="default" label="Download" onClick={() => this.updateDiffusionDisplay(false, true)} style={{ margin: '.5em' }} />)
			} else {
				diffusionDisplay.push(<Chip className={classes.chip} label="Streaming" onClick={() => this.updateDiffusionDisplay(false, true)} style={{ margin: '.5em' }} />)
				diffusionDisplay.push(<Chip className={classes.chip} label="Download" onClick={() => this.updateDiffusionDisplay(true, false)} style={{ margin: '.5em' }} />)
				if (!this.state.applyAllDiffMode)
					this.setState({ applyAllDiffMode: true })
			}

			// if(this.state.mediaDisplay.length > 0) {
			// 	mediaDisplay = this.state.mediaDisplay
			// } else {
			if (medias === 67)
				this.setState({ applyAllMedia: true })
			mediaDisplay.push(createMedias(medias, create))
			//}

			// let microSelected = microEligibiliter ? microEligibiliter.split(";") : []
			microDisplay.push(createMicro(microEligibiliter))
			if (microSelected.length !== 0 && (microSelected.length === this.state.microList.length) && !this.state.applyAllMicro) {
				this.setState({ applyAllMicro: true })
			}
			if (medias === 67)
				this.setState({ applyAllMedia: true })
		}

		return (
			<ThemeProvider>
				<Grid container>
					<Grid item xs={12}>
						<SimpleShowLayout record={source}>
							<Grid container spacing={3}>
								<Grid item xs={12} >
									<Grid container spacing={16}>
										<Grid item xs={12}>
											<Grid container spacing={16}>
												<Grid item xs={4}>
													<Grid item xs={12} className={classes.inputStyle}>
														{consumptionTypeDisplay}
													</Grid>
													{/* <Grid item xs={12} className={classes.inputStyle}>
															{validityTypeDisplay}
													</Grid> */}
													{/* {isValidityPeriod && (<Grid item xs={12} className={classes.MultiinputStyle}>
														
															<Grid container spacing={3} justify="flex-start" direction="row">

																<Grid item xs={6}>
																	<CustomTextField type='number' label='Nombre' value={this.state.nbDuration} fullWidth={true} create={create}
																	handleChange={e =>this.updateDuration(e,'nombre')} required={isValidityPeriod} errorLabel={(isValidityPeriod && controlField && controlField.validitytypeduration !== "" || this.state.nbDuration <0) ? controlField.validitytypeduration : null}/>
																</Grid>
																<Grid item xs={6}>
																	{create ? 
																	<CustomSelect values={periodes} label='Période' fullWidth={true} value={this.state.durationPeriode}  
																	onChange={e =>this.updateDuration(e,'periode')}  required={isValidityPeriod} errorLabel={isValidityPeriod && controlField && controlField.validitytypeduration !== "" ? controlField.validitytypeduration : null}/>
																	:
																	<CustomTextField  label='Période' value={periodes.find(s=>s.id ===this.state.durationPeriode) && periodes.find(s=>s.id ===this.state.durationPeriode).name} fullWidth={true} create={create}/>
																	}
																</Grid>
																
															</Grid>

														

														
													</Grid>)} */}
													<Grid item xs={12} className={classes.MultiinputStyle}>
														<Grid container spacing={3} justify="flex-start" direction="row">
															<Grid item xs={6}>
																{promoTypeDisplay}
															</Grid>
															<Grid item xs={6}>
																{promoValueDisplay}
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12} className={classes.MultiinputStyle}>
														<Grid container spacing={3} justify="flex-start" direction="row">
															<Grid item xs={6}>
																{programmeNbDisplay}
															</Grid>
															<Grid item xs={6}>
																<Tooltip title="Infini"
																	placement="bottom-start"
																	TransitionComponent={Zoom}
																>
																	<div style={{ paddingTop: "15px" }} >
																		<Switch
																			checked={this.state.infiniUserNumber}
																			color="primary" size="small"
																			inputProps={{ 'aria-label': 'primary checkbox' }}
																			onChange={this.checkInfiniNbDisplay}
																			className={classes.switchSelectAll}
																		/>
																		Infini
																	</div>
																</Tooltip>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12} className={classes.MultiinputStyle}>
														<Grid container spacing={3} justify="flex-start" direction="row" padding='0px'>
															<Grid item xs={6}>
																{associateNbAssign}
															</Grid>
															<Grid item xs={6}>
																<Tooltip title="Infini"
																	placement="bottom-start"
																	TransitionComponent={Zoom}
																>
																	<div style={{ paddingTop: "15px" }} >
																		<Switch
																			checked={this.state.infiniAssociationNumber}
																			color="primary" size="small"
																			inputProps={{ 'aria-label': 'primary checkbox' }}
																			onChange={this.checkInfiniNbAssign}
																			className={classes.switchSelectAll}
																		/>
																		Infini
																	</div>
																</Tooltip>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={7} style={{ marginLeft: "4em" }}>
													<Grid item xs={12} style={{ marginBottom: '1em' }}>
														{create ?
															<InputLabel htmlFor="input-with-icon-adornment" required={true} className={classes.labels} style={{ float: "left" }} >Cible de diffusion</InputLabel>
															:
															<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Cible de diffusion</InputLabel>
														}
														<PromotionsCheckBox withAll={false} source={promotion} alwaysOn create={create} handleInput={this.onChangeRules('target')}
															style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} />
													</Grid>
													<Grid item xs={12} style={{ marginBottom: '1em', display: 'flex', flexDirection: 'column' }}>
														<div>
															{create ?
																<InputLabel htmlFor="input-with-icon-adornment" required={true} className={classes.labels} style={{ float: "left" }}>Mode de diffusion</InputLabel>
																:
																<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Mode de diffusion</InputLabel>
															}
															{create ?
																this.state.applyAllDiffMode ?
																	<Tooltip title="Désélectionner tout"
																		placement="right-start"
																		TransitionComponent={Zoom}
																	>
																		<Switch
																			checked={this.state.applyAllDiffMode}
																			onChange={this.deselectAllDiffModeItems}
																			color="primary" size="small"
																			inputProps={{ 'aria-label': 'primary checkbox' }}
																			disabled={!create}
																			className={classes.switchSelectAll}
																		/>
																	</Tooltip>
																	:
																	<Tooltip title="Sélectionner tout"
																		placement="right-start"
																		TransitionComponent={Zoom}
																	>
																		<Switch
																			checked={this.state.applyAllDiffMode}
																			onChange={this.applyToAllDiffModeItems}
																			color="primary" size="small"
																			inputProps={{ 'aria-label': 'primary checkbox' }}
																			disabled={!create}
																			className={classes.switchSelectAll}
																		/>
																	</Tooltip>
																: null
															}
														</div>
														<div>{diffusionDisplay}</div>
													</Grid>
													<Grid item xs={12} style={{ marginBottom: '1em', display: 'flex', flexDirection: 'column' }}>
														<div>
															{create ?
																<InputLabel htmlFor="input-with-icon-adornment" required={true} className={classes.labels} style={{ float: "left" }}>Medias inclus</InputLabel>
																:
																<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Medias inclus</InputLabel>
															}
															{create ?
																// this.state.applyAllMedia ?
																	<Tooltip title={this.state.applyAllMedia ? " Désélectionner tout" : "Sélectionner tout"}

																		placement="right-start"
																		TransitionComponent={Zoom}
																	>
																		<Switch
																			checked={this.state.applyAllMedia}
																			onChange={this.state.applyAllMedia? this.deselectAllMediaItems: this.applyToAllMediaItems}
																			color="primary" size="small"
																			inputProps={{ 'aria-label': 'primary checkbox' }}
																			disabled={!create}
																			className={classes.switchSelectAll}
																		/>
																	</Tooltip>
										
																: null
															}
														</div>
														<div>{mediaDisplay}</div>
													</Grid>
													<Grid item xs={12} style={{ marginBottom: '1em', display: 'flex', flexDirection: 'column' }}>
														<div>
															{create ?
																<InputLabel htmlFor="input-with-icon-adornment" required={true} className={classes.labels} style={{ float: "left" }}>Micro éligibilité</InputLabel>
																:
																<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Micro éligibilité</InputLabel>
															}
															{create ?
																//this.state.applyAllMicro ?
																	<Tooltip title={this.state.applyAllMicro ? " Désélectionner tout" : "Sélectionner tout"}
																		placement="right-start"
																		TransitionComponent={Zoom}
																	>
																		<Switch
																			checked={this.state.applyAllMicro}
																			onChange={this.state.applyAllMicro ? this.deselectAllMicroItems : this.applyToAllMicroItems}
																			color="primary" size="small"
																			inputProps={{ 'aria-label': 'primary checkbox' }}
																			disabled={!create}
																			className={classes.switchSelectAll}
																		/>
																	</Tooltip>
																	// :
																	// <Tooltip title="Sélectionner tout"
																	// 	placement="right-start"
																	// 	TransitionComponent={Zoom}
																	// >
																	// 	<Switch
																	// 		checked={this.state.applyAllMicro}
																	// 		onChange={this.applyToAllMicroItems}
																	// 		color="primary" size="small"
																	// 		inputProps={{ 'aria-label': 'primary checkbox' }}
																	// 		disabled={!create}
																	// 		className={classes.switchSelectAll}
																	// 	/>
																	// </Tooltip>
																: null
															}
														</div>
														<div>{microDisplay}</div>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Switch
												checked={this.state.applyToAllcatalog}
												onChange={handleApplyAllCatalogChange}
												color="primary"
												inputProps={{ 'aria-label': 'primary checkbox' }}
												disabled={!create}
												className={classes.switch}
											/>
											<InputLabel className={classes.switchLabel}>Appliquer la promotion à tout le catalogue</InputLabel>
										</Grid>
									</Grid>

									{/* PROGRAMMES */}
									<Grid item xs={12} style={listStyle}>
										<Card className={classes.card} variant="outlined">
											<Grid container spacing={3} justify="flex-start" direction="row" style={{ marginBottom: '.5em' }}>
												<Grid item xs={1}>
													<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Programmes</InputLabel>
												</Grid>
												{
													create &&
													<Grid item xs={1}>
														<Button variant="contained" size="small" color='primary' className={classes.button} onClick={this.handleClickOpenAddProgrammes} disabled={this.state.applyToAllcatalog}><AddIcon /></Button>
													</Grid>
												}

											</Grid>
											{
												create &&
												<div>
													<Dialog onClose={this.handleCloseAddProgrammes} maxWidth="sm" fullWidth aria-labelledby="customized-dialog-title" open={this.state.openAddProgrammes}>
														<div className={classes.dialogHeader}>
															Ajouter un ou plusieurs programmes
															<Button onClick={this.handleCloseAddProgrammes} className={classes.closeIcon} >
																<CloseIcon />
															</Button>
														</div>
														<DialogContent dividers style={{ paddingTop: '.5em', paddingBottom: '.2em' }}>

															<div><ChangeModeSwitch /></div>

															{!this.state.programmeSearchByList && <>
																<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
																	<Paper component="form" style={{ padding: '1px 1px', marginBottom: '0.5rem', display: 'flex', alignItems: 'center', width: 400, }}>
																		<InputBase
																			style={{ marginLeft: '0.2rem', flex: 1, }}
																			placeholder="Recherche par Identifiant ou par Titre"
																			onChange={this.handleChangeInput}
																			inputProps={{ 'aria-label': 'search google maps' }}
																			onKeyPress={handleSearchMovieKeyPress}
																		/>
																		<IconButton type="button" aria-label="search"
																			onClick={(e) => searchCompaniesMovies(e)}>
																			<SearchIcon />
																		</IconButton>
																		<Tooltip title="Pour rechercher plusieurs titres par identifiants, ajouter ; entre chaque identifiant (ex: 12;13;14)"
																			placement="right"
																			TransitionComponent={Zoom}
																		>
																			<IconButton>
																				<InfoIcon />
																			</IconButton>
																		</Tooltip>
																	</Paper>
																	{
																		this.state.selectedAddedRows.length > 0 &&
																		<DialogContentText id="alert-dialog-slide-description" style={{ marginLeft: '1em', width: '15em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
																			{this.state.selectedAddedRows.length > 1 ? this.state.selectedAddedRows.length + ' lignes sélectionnées' : this.state.selectedAddedRows.length + ' ligne sélectionnée'}
																			<DeleteIcon color="primary" style={{ marginLeft: '.8em' }}
																				onClick={() => this.setState({ rowsSelected: [], selectedAddedRows: [] })} />
																		</DialogContentText>
																	}
																	{this.state.isLoading ? (<CircularProgress size="1.8em" style={{ marginLeft: '.5em' }} />) : null}
																</div>
																<ThemeProvider theme={this.getMuiTheme()}>
																	<MUIDataTable data={this.state.searchMovies}
																		columns={[{ name: 'id', label: 'Identifiant' }, { name: 'value', label: 'Programme' }]} options={options} />
																</ThemeProvider>
															</>}
															{this.state.programmeSearchByList && <>
																{this.state.searchMovies.length > 0 ?
																	<List component="nav" className={classes.root} dense aria-label="contacts"
																		style={{ height: '12em', overflow: 'auto', paddingTop: '10.5px', paddingBottom: '10.5px', margin: ".5em 0 .5em 0", border: "1px solid #E0E0E0", borderRadius: "4px", width: "99%" }}>
																		{this.state.searchIdsNotExist.length > 0 ?
																			this.state.searchIdsNotExist.map(id =>
																				<ListItem button style={{ background: "#e23434", color: "white" }}>
																					<ListItemText inset primary={id} style={{ paddingLeft: 0, maxWidth: "85%" }} /><WarningIcon />
																				</ListItem>)
																			:
																			null
																		}
																		{this.state.searchMovies.map(m =>
																			<ListItem button>
																				<ListItemText inset primary={m.shorttitle + " (" + m.id + ")"} style={{ paddingLeft: 0, maxWidth: "80%" }} /><CheckIcon style={{ marginLeft: "1em" }} />
																			</ListItem>
																		)}
																	</List>
																	:
																	<CustomTextField value={this.state.textFieldValueProgrammeList} multiline={true}
																		disabled={!this.state.programmeSearchByList} fullWidth={true} rows={10}
																		handleChange={handleTextFieldValueProgrammeList} create={create}
																		errorLabel={this.state.textFieldValueProgrammeListError} />
																}
																<div style={{ paddingLeft: ".5em", marginTop: "1.2em" }}>
																	<Typography variant="caption" gutterBottom >
																		Chaque ligne ne doit contenir qu'un seul identifiant et aucun séparateur.
																	</Typography>
																</div>
																{this.state.searchMovies.length > 0 ?
																	<Button variant="contained" size="small" color='primary'
																		className={classes.validateIcon}
																		onClick={handleClickChangeMode}
																		disabled={!this.state.textFieldValueProgrammeList.length > 0}>
																		<EditIcon style={{ marginRight: '.2em' }} /> EDITER
																	</Button>
																	:
																	<Button variant="contained" size="small" color='primary'
																		className={classes.validateIcon}
																		onClick={handleClickCheckIdsPromoExist}
																		disabled={!this.state.textFieldValueProgrammeList.length > 0}>
																		{this.state.checkProgrammeIsLoading ? (<CircularProgress size="1.8em" style={{ marginLeft: '.5em', marginRight: '.2em' }} />) : <YoutubeSearchedForIcon style={{ marginRight: '.2em' }} />}
																		VERIFIER
																	</Button>
																}
															</>}
														</DialogContent>
														<DialogActions>
															<Button onClick={this.onChangeRules('programmes')} color="primary" variant="contained"
																className={classes.buttonCheck}
																disabled={(this.state.programmeSearchByList && !(this.state.searchMovies.length > 0)) || (!this.state.programmeSearchByList && !(this.state.selectedAddedRows.length > 0)) || (this.state.searchIdsNotExist.length > 0)}>
																<CheckIcon className={classes.leftIcon} />
																Valider
															</Button>
															<Button onClick={this.handleCloseAddProgrammes} color="secondary" variant="contained" className={classes.buttonCancel}>
																<CancelIcon className={classes.leftIcon} />
																Annuler
															</Button>
														</DialogActions>
													</Dialog>
												</div>
											}
											<PromotionsProgrammes create={create} value={programmes} info={'programmes'} handleInput={this.onChangeRules('programmes')} added={this.state.programmesAdded} handler={this.updateAddedProgrammes} disabled={this.state.applyToAllcatalog} updateState={this.state.updateBddpState} />
										</Card>
									</Grid>


									<Grid item xs={12} style={listStyle}>
										<Card className={classes.card} variant="outlined">
											<Grid container spacing={3} justify="flex-start" direction="row" style={{ marginBottom: '.5em' }}>
												<Grid item xs={1}>
													<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Albums</InputLabel>
												</Grid>
												{
													create &&
													<Grid item xs={1}>
														<Button variant="contained" size="small" color='primary' className={classes.button} onClick={this.handleClickOpenAddAlbums} disabled={this.state.applyToAllcatalog}><AddIcon /></Button>
													</Grid>
												}
											</Grid>
											{
												create &&
												// <SearchListField open={this.state.openAddAlbums} close={this.handleCloseAddAlbums} exceptionList={albums}
												// Title='Liste des albums' ListToSearch={this.state.searchAlbums} TypeItem='Albums' multiSelect={true} />
												<div>
													<Dialog onClose={this.handleCloseAddAlbums} maxWidth="lg" aria-labelledby="customized-dialog-title" open={this.state.openAddAlbums}>
														<div className={classes.dialogHeader}>
															Ajouter un ou plusieurs albums
															<Button onClick={this.handleCloseAddAlbums} className={classes.closeIcon} >
																<CloseIcon />
															</Button>
														</div>
														<DialogContent dividers style={{ paddingTop: '.5em', paddingBottom: '.2em' }}>
															<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
																<Paper component="form" style={{ padding: '1px 1px', marginBottom: '0.5rem', display: 'flex', alignItems: 'center', width: 400, }}>
																	<InputBase
																		style={{ marginLeft: '0.2rem', flex: 1, }}
																		placeholder="Recherche par Identifiant ou par Titre"
																		onChange={this.handleChangeInput}
																		onKeyPress={handleSearchAlbumsKeyPress}
																	/>
																	<IconButton type="button" aria-label="search" onClick={(e) => searchAlbums(e)}>
																		<SearchIcon />
																	</IconButton>
																	<Tooltip title="Pour rechercher plusieurs titres par identifiants, ajouter ; entre chaque identifiant (ex: 12;13;14)"
																		placement="right"
																		TransitionComponent={Zoom}
																	>
																		<IconButton>
																			<InfoIcon />
																		</IconButton>
																	</Tooltip>
																</Paper>
																{
																	this.state.selectedAddedRows.length > 0 &&
																	<DialogContentText id="alert-dialog-slide-description" style={{ marginLeft: '1em', width: '15em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
																		{this.state.selectedAddedRows.length > 1 ? this.state.selectedAddedRows.length + ' lignes sélectionnées' : this.state.selectedAddedRows.length + ' ligne sélectionnée'}
																		<DeleteIcon color="primary" style={{ marginLeft: '.8em' }}
																			onClick={() => this.setState({ rowsSelected: [], selectedAddedRows: [] })} />
																	</DialogContentText>
																}
																{this.state.isLoading ? (<CircularProgress size="1.8em" style={{ marginLeft: '.5em' }} />) : null}
															</div>
															<div style={{ marginBottom: "1em" }}>
																<Typography variant="caption" gutterBottom>
																	<WarningIcon className={classes.warningIcon} />Seuls les albums liés à un BDDPID sont affichés dans la liste de recherche.
																</Typography>
															</div>
															<ThemeProvider theme={this.getMuiTheme()}>
																<MUIDataTable data={this.state.searchAlbums}
																	columns={[{ name: 'id', label: 'Identifiant' }, { name: 'value', label: 'Album' }, { name: 'bddpid', label: 'Bddp id' }]}
																	options={options}
																/>
															</ThemeProvider>
														</DialogContent>
														<DialogActions>
															<Button onClick={this.onChangeRules('albums')} color="primary" variant="contained" className={classes.buttonCheck}>
																<CheckIcon className={classes.leftIcon} />
																Valider
															</Button>
															<Button onClick={this.handleCloseAddAlbums} color="secondary" variant="contained" className={classes.buttonCancel}>
																<CancelIcon className={classes.leftIcon} />
																Annuler
															</Button>
														</DialogActions>
													</Dialog>
												</div>
											}
											<PromotionsProgrammes create={create} value={albums} info={'albums'} handleInput={this.onChangeRules('albums')} added={this.state.albumsAdded} handler={this.updateAddedAlbums} disabled={this.state.applyToAllcatalog} updateState={this.state.updateBddpState} />
										</Card>
									</Grid>
									<Grid container spacing={8}>
										<Grid item xs={3} style={listStyle}>
											<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Liste BDDP</InputLabel>
											<PromotionsAdd create={create} value={bddp} info={'bddp'} handleInput={this.onChangeRules('bddp')} type={'bddp'} disabled={this.state.applyToAllcatalog} updateState={this.state.updateBddpState} />
										</Grid>
										<Grid item xs={3} style={listStyle}>
											<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Liste ID Hapi</InputLabel>
											<PromotionsAdd create={create} value={hapi} info={'hapi'} handleInput={this.onChangeRules('hapi')} type={'hapi'} disabled={this.state.applyToAllcatalog} updateState={this.state.updateHapilistState} />
										</Grid>
										{/* <Grid item xs={3}></Grid> */}
										<Grid item xs={6} style={{ position: 'relative' }}>
											<Button color="primary" variant="contained" className={classes.buttonRules} onClick={this.handleClickOpenRules}>
												<IconButton>
													<VisibilityIcon style={{ color: '#fff' }} />
												</IconButton>
												VOIR RULES
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<CustomDisplayDialog info="rules" handleClose={this.handleCloseRules} open={this.state.openRules} content={this.debugRules()} />
						</SimpleShowLayout>
					</Grid>
					<Grid item xs={12}>
					</Grid>
				</Grid>
			</ThemeProvider>
		)
	}
}

const mapStateToProps = state => ({
	...state,
	promotion: state.reducer ? state.reducer.Promotion.payload : null,
	controlField: state.reducer ? state.reducer.Promotion.controlField : null,
	promotionApplyAllCatalogue: state.reducer ? state.reducer.Promotion.promotionApplyAllCatalogue : null,
})

const mapDispatchToProps = ({
	onPromotionUpdate,
	onGetPromo,
	onPromoApplyAllCatalogue
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

export default enhance(PromotionsRules)
