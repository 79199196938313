import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import { translate } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'
import {  Button, } from '@material-ui/core'
import { CustomAlert, CustomCheckBox, CustomSelect,CustomSwitch } from '../layout/GlobalStyles'

import {DomainsItems} from '../../requests'

import Grid from '@material-ui/core/Grid'
import { InputLabel } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import albums from '../../reducers/albums'
import CompaniesAlbums from '../../requests/CompaniesAlbums'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(1),
			width: '100%',
			height: theme.spacing(10),
		},
	},
	
	labels: {
		fontSize: '1.1em',
		//fontWeight: 'bold',
		// color: '#288DB1',
		color: 'black',
	},
	
	chip: {
		color: '#fff',
		backgroundColor : '#288DB1',
		'&:hover': {
			background: '#1c627b'
		},
		'&:active': {
			background: '#1c627b'
		},
		'&:focus': {
			background: '#288DB1',
			color:'#fff'
		},
		// '&:disabled':{
		// 	background:'#1c627b' ,
		// 	color:'#fff'
		// }
	},
})


// const sfrSerieList =[ //type = 4
// 	{id:0,name: 'Série uniquement'},
// 	{id:1,name: 'Toutes les saisons'},
// 	{id:2,name: 'Série + saisons'}
// ]
// const sfrSaisonList =[ //type = 5
// 	{id:0,name: 'Saison uniquement'},
// 	{id:1,name: 'Toutes les saisons'},
// 	{id:2,name: 'Série + saisons'},
// 	{id:3,name: 'Programmes uniquement'}
// ]

// const sfrPackList =[ //type = 2
// 	{id:0,name: 'Pack uniquement'},
// 	{id:1,name: 'Pack + Programmes'},
// 	{id:2,name: 'Programmes uniquement'}
// ]
// const sfrData =[
// 	{id:0,name: 'Tout'},
// 	{id:1,name: 'Metadata uniquement'},
// 	{id:2,name: 'Visuals uniquement'}
// ]
const modeMedia =[
	{id:0,name: 'Défaut'},
	{id:1,name: 'BA Uniquement'},
	{id:2,name: 'Ignorer BA'},
	{id:3,name: 'Ajouter BA'},
	{id:4,name: 'Disponibilté média'}
]

const imageBddpOption =[
	{id:0,name: 'Tout'},
	{id:1,name: 'Album uniquement'},
	{id:2,name: 'Programmes uniquement'}
]
const onChangeRules = (event, infos) => e => {
	let value
	switch (event) {
		case 'target':
			value = e.substring(0, e.length - 1)
			 this.setState({
				[event]: value
			}, () => {
				this.updateState()
			})

			break

	
	}
}
const JobsComponent = (props) => {
	const { classes, job, listcheckedJobs, album, onExportJobs, ExportJobs } = props
	//const [selectedAlbum,setAlbum] = useState({})
	///sfr job
	const [sfrAlbum, setsfrAlbum] = useState(0)
	// const [selectsfrdata, setsfrdata] = useState(0)
	const [loading, setLoading] = useState(true);

	//
	const [mediahubMode, setMediahubMode] = useState(0)
	const [mediahubCibles, setMediahubCibles] = useState(-1)
	const [cablelabsHapi, setCablelabsHapi] = useState(album.isPack ? 0 : 2)
	const [albumImageBDDP, setAlbumImageBDDP] = useState(1)
	const [choices, setChoices] = React.useState([])
	const [selectedMediaCible, setSelectedMediaCible] = useState([])
	const [albumList, setAlbumList] = useState([])
	const [albumMovieList, setAlbumMovieList] = useState([])
	const [imgAlbumTypes, setImgAlbumTypes] = useState([])
	const sfrList =[
		{id:0,name: album.typeid === 2 ? 'Pack uniquement' : album.typeid === 4 ? 'Série uniquement':album.typeid === 5 ? 'Saison uniquement' : 'Album' },
		{id:1,name: album.typeid === 4 || album.typeid ===5 ? 'Toutes les saisons' : 'N/A'  },
		{id:2,name: album.typeid === 4 || album.typeid ===5 ? 'Série + saisons' : 'Pack + Programmes'},
		{id:3,name: album.typeid === 5 || album.typeid ===2 ? 'Programmes uniquement'  : 'N/A' }
	
	]
	const cableabsOption =[
		{id:0,name:album.isPack ? 'Tout' : 'N/A'},
		{id:1,name:album.isPack ?  'Infos Pack uniquement' : 'N/A'},
		{id:2,name: 'Programmes uniquement'}
	]
	const handleMediaCibleClick = (label) => {
		setSelectedMediaCible((prevSelected) =>
			prevSelected.includes(label)
				? prevSelected.filter((chip) => chip !== label)
				: [...prevSelected, label]
		)
	}
	// useEffect(() => {
	// 	setCablelabsHapi(album.isPack ? 1 : 2)
	// }, [album])
	useEffect( () => {
			if(listcheckedJobs.find(s=>s === 12)){
				//setJobsProperties(selectsfrdata,'sfrData')	
				setJobsProperties(sfrAlbum,'sfrAlbumType')	
			}
			// if(listcheckedJobs.find(s=>s === job.itemid)){
			// 	setJobsProperties(0,'Other')	
			// }

			if(listcheckedJobs.find(s=>s === 29)){
				setJobsProperties(cablelabsHapi,'cablelabsHapi')	
			}
			
		
	}, [listcheckedJobs])

	useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);  // Après 2 secondes, désactivez l'état de chargement
        }, 2000);

        return () => clearTimeout(timer);  // Nettoyage du timer si le composant est démonté
    }, []);

 
	 

 
// const GetAlbumList = async () => {
// 	try {
// 		let res = await CompaniesAlbums.getAllalbums();
// 		setAlbumList(res.data);
// 	} catch (error) {
// 		console.error("Erreur lors de la récupération de la liste des albums", error);
// 	}
// }
// const GetMoviebyAlbum = async ()=>{
// 		if (album) {
// 			await CompaniesAlbums.getAlbumMoviesbyIds(album.id).then((res) => {
// 				if (res.data) {
// 					setAlbumMovieList(res.data)
// 				}
// 			})
// 		}
// }

	const AddButton = withStyles((theme) => ({
		root: {
			color: 'white',
			backgroundColor: '#288DB1',
			'&:hover': {
				backgroundColor: '#1C627B',
			},
		},
	}))(Button)
 
	const onChangeImagetype = (checked, index) => {  
		// Créez une copie de imgAlbumTypes pour éviter de muter directement l'état
		let checkedList = [...imgAlbumTypes];
	  
		if (checked && !checkedList.includes(index)) {
		  checkedList.push(index); // Ajoute l'élément s'il est coché
		}
		if (!checked && checkedList.includes(index)) {
		  checkedList = checkedList.filter(s => s !== index); // Retire l'élément s'il est décoché
		}	  
		// Mettre à jour l'état avec la nouvelle liste
		setImgAlbumTypes(checkedList);
	  }
	  
	  // Utilisez useEffect pour appeler setJobsProperties après la mise à jour de imgAlbumTypes
	  useEffect(() => {
		if (imgAlbumTypes.length > 0) { // Vous pouvez ajuster la condition selon vos besoins
		  setJobsProperties(albumImageBDDP, 'BddpAlbum');
		}
		else{
			let listjobs = ExportJobs.filter(s => s.typeid !== 30 && s.typeid !== 27);
			onExportJobs(listjobs)

		}
	  }, [imgAlbumTypes, albumImageBDDP]); // Surveillez imgAlbumTypes et albumImageBDDP
	  
	const setJobsProperties = async (id,propList) =>{
		let listjobs = ExportJobs
		//switch (job.itemid){
			let date = new Date()
			switch (job.itemid){

				// case 8:{
				// 	let listjobs = ExportJobs.filter(s=>s.typeid !== 8)
				// 	let newjob ={
				// 		typeid : job.itemid,
				// 		refid : album.id,
				// 		executiondate : date.toLocaleString("en-US")
				// 	}
				// 	listjobs.push(newjob)
				// 	onExportJobs(listjobs)	
				// }
				// case (8 || 28 || 37):{
				// 	let jobtoadd = ExportJobs.filter(s=>s.typeid ===job.itemid) && ExportJobs.filter(s=>s.typeid ===job.itemid)[0]
				// 	console.log(jobtoadd)
				// 	let list = ExportJobs.filter(s=>s.typeid !==job.itemid)

				// 	if(!jobtoadd ||jobtoadd.length === 0 )
				// 		jobtoadd ={typeid: job.itemid,refid:album.id}
				// 	if(propList ==='Other'){
				// 		console.log(jobtoadd)
				// 	list.push(jobtoadd)
				// 	console.log(list)
				// 	onExportJobs(list)	
				// 	}
				// }
				case 12:{
					let sfrJob = ExportJobs.filter(s=>s.typeid ===12) && ExportJobs.filter(s=>s.typeid ===12)[0]
					let list = ExportJobs.filter(s=>s.typeid !==12)

					if(!sfrJob ||sfrJob.length === 0 )
						sfrJob ={typeid: job.itemid,refid:album.id, xml:1,picture:1,sfralbumType: sfrAlbum}
	 
				if(propList==='sfrAlbumType'){
					// listjobs = listjobs.filter(s=>s.typeid !== 12)
					sfrJob.xml = 1
					sfrJob.picture =1
					sfrJob.sfralbumType = id
					setsfrAlbum(id)
					 
				
				}	
					list.push(sfrJob)
					onExportJobs(list)	
				}
				// case 28:{
				// 	// let listJobs = ExportJobs.filter(s=>s.typeid !== 28)
				// 	// CompaniesMovies.getMoviesBddpByAlbumId(album.id).then(movies =>{
				// 	// 	if(!movies.data) return
				// 	// 	if(movies.data && movies.data.companiesmovies  ){
				// 	// 		movies.data.companiesmovies.forEach(movie => {
				// 	// 			if(movie.idbddp)
				// 	// 			{	 
				// 	// 				let newjob ={
				// 	// 					typeid : job.itemid,
				// 	// 					refid : movie.movieId,
				// 	// 					executiondate : date.toLocaleString("en-US"),
				// 	// 					idbddp : movie.idbddp
				// 	// 				}
				// 	// 				listJobs.push(newjob)
				// 	// 			}
				// 	// 		})
				// 	// 		onExportJobs(listJobs)
				// 	// 	}
				// 	// })
				// }
				case 29 :{
						
					if(propList ==='cablelabsHapi'){
						setCablelabsHapi(id)
						let albumHAPIJob = ExportJobs.filter(s=>s.typeid ===29) && ExportJobs.filter(s=>s.typeid ===29)[0]
						let list = ExportJobs.filter(s=>s.typeid !==29)
						if(!albumHAPIJob ||albumHAPIJob.length === 0 )
							albumHAPIJob ={typeid: job.itemid,refid:album.id,albumHAPI:id }
						albumHAPIJob.albumHAPI = id

						list.push(albumHAPIJob)
						onExportJobs(list)	
					}
					
				}
				case 30 :{
					
					if(propList ==='BddpAlbum'){
							setAlbumImageBDDP(id)
							let bddpalbumJob = ExportJobs.filter(s=>s.typeid ===30) && ExportJobs.filter(s=>s.typeid ===30)[0]
							let list = ExportJobs.filter(s=>s.typeid !==30)

							if(!bddpalbumJob ||bddpalbumJob.length === 0 )
								bddpalbumJob ={typeid: job.itemid,refid:album.id}
							if(imgAlbumTypes.length !==0) 
								bddpalbumJob.imgtype = imgAlbumTypes.join(';')
							else return
							bddpalbumJob.bddpAlbum = id
							list.push(bddpalbumJob)
							onExportJobs(list)
							// if(id ===0 || id ===1){
							// 	// // let newjob ={
							// 	// // 	typeid : job.itemid,
							// 	// // 	refid : album.id,
							// 	// // 	executiondate : date.toLocaleString("en-US")
							// 	// // 	}
							
							// }
							// if(id ===0 || id ===2){
							// 	CompaniesAlbums.getAlbumMoviesbyIds(album.id).then(res =>{
							// 		console.log(res.data)
							// 		if(res.data){
							// 			listjobs = listjobs.filter(s=>s.typeid !== 27)
							// 			res.data && res.data.forEach((movie =>{
							// 				let newjob ={
							// 					typeid : 27,
							// 					refid : movie.movie.id,
							// 					executiondate : date.toLocaleString("en-US")
							// 					}
							// 				if(imgAlbumTypes.length !==0) 
							// 					newjob.imgtype = imgAlbumTypes.join(';')
							// 				else return
							// 				listjobs.push(newjob)
		
							// 			}))
							// 			onExportJobs(listjobs)
							// 		}
							// 	})
							// }
							
					}
		
				}
				// case (8 || 28 || 37 ):{
				// 	let listjobs = ExportJobs.filter(s=>s.typeid !== job.itemid )
				// 	console.log(listjobs)
				// 	let newjob ={
				// 		typeid : job.itemid,
				// 		refid : album.id,
				// 		executiondate : date.toLocaleString("en-US")
				// 	}
				// 	listjobs.push(newjob)
				// 	onExportJobs(listjobs)	
				// }
 			}
			
			 

	}

	switch (job.itemid) {
		case 0:{
			if(loading)
			return  <CircularProgress /> 
		}
		case 4:
			return (
				<div>
					<AddButton variant="contained" size="small" disabled={!listcheckedJobs.find(s=>s ===4) || album.typeid === 5} style={{ marginRight: '10px', marginBottom: '5px' }}>
						Voir les statut Bouygues (Ingrid)
					</AddButton>
				</div>
			)
			break
		case 5:
			return (
				<div>

					<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 5
					</AddButton>
				</div>
			)
			break
		case 7:
			return (
				<div>

					<AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 7
					</AddButton>
				</div>
			)
			break
		case 12:
			return (
				<div>
					<Grid container>
						<Grid item xs='4px' >							
							<CustomSelect values={sfrList.filter(s=>s.name!=='N/A')} value={sfrAlbum} handleChange={(e) =>setJobsProperties(e.target.value,'sfrAlbumType')} disabled={!listcheckedJobs.find(s=>s ===12)}/>

						</Grid>
						{/* <Grid item xs='4px' >
							<CustomSelect values={sfrData} value={selectsfrdata} handleChange={(e) =>setJobsProperties(e.target.value,'sfrData') } disabled={!listcheckedJobs.find(s=>s ===12)} />
						</Grid> */}
					</Grid>
				</div>
			)
			break
		case 15:
				return (
					<div>
	
						<CustomSelect values={modeMedia} label='Mode' disabled={!listcheckedJobs.find(s=>s ===15)} value={mediahubMode} handleChange={e=>setMediahubMode(e.target.value)}/>

						<Grid container>
							<Grid item xs='4px' >
							<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Cibles</InputLabel>
							</Grid>
							<Grid item xs='4px' >
								<Tooltip title={mediahubCibles === -1 ? "Désélectionner tout" : "Sélectionner tout"}	> 	
									<CustomSwitch
										checked={mediahubCibles === -1}
										onChange={(e,checked) => {checked ? setMediahubCibles(-1): setMediahubCibles(0)}}
										color="primary" size="small" 
										inputProps={{ 'aria-label': 'primary checkbox' }}
										className={classes.switch}
										disabled={!listcheckedJobs.find(s=>s === 15)}
									/>
								</Tooltip>
								</Grid>
								<Grid item xs='6px' style={{margin: '.5em'}}>
									{choices.map((element, index) => (
										<Tooltip key={index} title={element.label} style={{ backgroundColor: "#000",margin: '1.5em'}}>
											<Chip className={classes.chip}  clickable disabled={!listcheckedJobs.find(s => s === 15)} 
											label={element.label} 
											style={{background : selectedMediaCible.includes(element.label) ?'#288DB1': 'grey' ,margin: '.5em'}}
											onClick={() => handleMediaCibleClick(element.label)}
 											/>
										</Tooltip>
									))}
								</Grid>
							</Grid>
					</div>
				)
				break
		case 29:
			return (
				<div>
					<CustomSelect values={cableabsOption.filter(s=>s.name!=='N/A')}  disabled={!listcheckedJobs.find(s=>s ===29)} value={cablelabsHapi} handleChange={e=>setJobsProperties(e.target.value,'cablelabsHapi') }/>

				</div>
			)
			break
		case 30:
			return (
				<div>
				<div>
					<InputLabel htmlFor="input-with-icon-adornment" className={classes.labels} >Options d'export image BDDP:</InputLabel>
					<CustomCheckBox label="Jaquette Standard (13)"  disabled={!listcheckedJobs.find(s=>s ===30)} labelPlacement='end' color="#000" onChange={(e, checked)=>onChangeImagetype(checked,13)}/>
					<CustomCheckBox label="16/9 (239)"    disabled={!listcheckedJobs.find(s=>s ===30)} labelPlacement='end' color="#000" onChange={(e, checked)=>onChangeImagetype(checked,239)}/>
				</div>
				<div>		 
				<CustomSelect values={imageBddpOption} value={albumImageBDDP} handleChange={e=>setJobsProperties(e.target.value,'BddpAlbum')} disabled={!listcheckedJobs.find(s=>s ===30)}/>
				</div>
				</div>
			)
			
			break
		case 37:
			return (
				<div>

					{/* <AddButton variant="contained" size="small" style={{ marginRight: '10px', marginBottom: '5px' }}>
						test 37
					</AddButton> */}
				</div>
			)
			break

		default:
			return (<div></div>)
			break
	}

}

const enhance = compose(
	withStyles(styles)
)

export default translate(enhance(JobsComponent))

