import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Card from '@material-ui/core/Card'
import { ImageGridList } from '../common/lists'
import LogoPicture from '../../pictures/typo_logo_incruste_hawc_bleu.png'
import { Funny } from '../../requests'
import { AUTH_PAGE_LOADED,RESET_PASSEWORD,CREATE_PASSEWORD } from '../../constants/actions'
import { darkTheme } from '../layout/themes'
import { MyNotification } from '../layout'
import { createTheme } from '@material-ui/core/styles'
import {  withStyles, ThemeProvider } from '@material-ui/core/styles'
import LoginFrm from './Login'
import ForgottenPwd from './ForgottenPwd'
import Button from '@material-ui/core/Button'

// eslint-disable-next-line react/prop-types
const Logo = ({ src, classes }) => <img src={src} alt="Hawc" className={classes.logo} />

const styles = theme =>   ({
	main: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		height: '1px',
		alignItems: 'center',
		justifyContent: 'flex-start',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
 	},
	card: {
		position: 'fixed',
		top: '30%',
		minWidth: 300,
		textAlign: 'center',
	},
	logo: {
		width: 'auto',
		height: '50px',
		marginTop: '20px',
	},
	error :{
		color:'red',
		fontSize : 'larger',
		fontWeight:'bold',
		padding:'24px'
	}
})
// eslint-disable-next-line no-unused-vars
const sanitizeRestProps = ({ classes, className, location, title, array, theme, staticContext, onLoadMosaic, ...rest }) => rest

class Login extends Component {
	state = {
		pictures: null,
		ResetPwd : false,
		createPwd: false
	}

	UNSAFE_componentWillMount() {
		const key = 'AUTH_MOSAIC'
		const mosaic = localStorage.getItem(key)
		if (null != mosaic) {
			this.props.onLoadMosaic(JSON.parse(mosaic))
		}
		else {
			Funny.getRandomMosaic(346, 1).then(json => {
				this.props.onLoadMosaic(json.data)
				localStorage.setItem(key, JSON.stringify(json.data))
			})
		}
		const refresh = 'RefreshVer_Count'
		const countRefresh = localStorage.getItem(refresh)  		
		if(null == countRefresh){
			localStorage.setItem(refresh, 0)
		}
		// const ispostbak = localStorage.getItem('C_BLACKLIST_CACHEKEY')
		// if(null ==ispostbak){
		// 	localStorage.setItem('C_BLACKLIST_CACHEKEY', '')
		// }
	 

	}

	render() {
		const { classes, className, loginForm, pictures,resetPwd,createPwd,onResetPwd,onCreatePwd, ...rest } = this.props
		return (
			<div
				className={classnames(classes.main, className)}
				{...sanitizeRestProps(rest)}
			>
				<ImageGridList list={pictures} />
				<Card className={classes.card}>
					<div className={classes.avatar}>
						<Logo src={LogoPicture} classes={classes} />
					</div>
					{
					
					// localStorage.getItem('C_BLACKLIST_CACHEKEY') !=='' && parseInt (localStorage.getItem('C_BLACKLIST_CACHEKEY').split('_')[1]) > 6 ?
					// 	<div className={classes.error}>Accès Interdit.<br/> Veuillez contacter l'administrateur.</div>
					// : 
					parseInt(localStorage.getItem('RefreshVer_Count')) < 4 ? 
					((resetPwd ||createPwd) 
					? <ForgottenPwd createPwd={createPwd} resetPwd={resetPwd} onResetPwd={onResetPwd} onCreatePwd={onCreatePwd}/>
					: <LoginFrm onResetPwd={onResetPwd} onCreatePwd={onCreatePwd} /> 
					)
					:
					<div className={classes.error}>Problème de version incompatible<br/> Veuillez vous adresser à votre administrateur.</div>}
				</Card> 
			
			<MyNotification />
			</div>
		)
	}
}

Login.propTypes = {
	className: PropTypes.string,
	authProvider: PropTypes.func,
	classes: PropTypes.object.isRequired,
	input: PropTypes.object,
	meta: PropTypes.object,
	previousRoute: PropTypes.string,
	loginForm: PropTypes.element,
	onLoadMosaic: PropTypes.func,
}

// Login.defaultProps = {
// 	loginForm: <LoginForm />,
// 	//loginForm: <LoginFrm onResetPwd={onResetPwd}/> ,
// }

const mapStateToProps = state => ({
	...state,
	pictures: state.reducer ? state.reducer.Auth.pictures : null,
	resetPwd: state.reducer ? state.reducer.Auth.resetPwd : false,
	createPwd:state.reducer ? state.reducer.Auth.createPwd : false,
})

const mapDispatchToProps = dispatch => ({
	onLoadMosaic: (payload) =>
		dispatch({ type: AUTH_PAGE_LOADED, payload: payload }),
	onResetPwd : (payload) =>
		dispatch({ type: RESET_PASSEWORD, payload: payload }),
	onCreatePwd : (payload) =>
		dispatch({ type: CREATE_PASSEWORD, payload: payload })
})

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)

const EnhancedLogin = enhance(Login)

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const LoginWithTheme = props => (
	<ThemeProvider theme={createTheme(darkTheme)}>
		{/* <CssBaseline /> */}
		<EnhancedLogin {...props} />
	</ThemeProvider>
)

export default LoginWithTheme
