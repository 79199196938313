import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import authProvider from './authProvider'
import { AUTH_LOGOUT } from 'react-admin'
import { ON_REFRESH_TOKEN } from './constants/actions'
import { Logger, Token } from './tools'
import { get } from 'axios'
import { Contacts, DomainsItems, Companiestransfers, Authentification, Domains } from './requests'

 
export default class IdleLayout extends Component {
	constructor(props) {
		super(props)

		this.state = {
			// idleTimeout : 1000 * 10 * 1,
			// callRefreshTimeout : 1000 * 5 * 1,
			idleTimeout : 1000 * 60 * 60 * 12,
			callRefreshTimeout : 1000 * 60 * 60 * 11,
			showModal: false,
			userLoggedIn: false
		}

		this.idleTimer = null
		this.onAction = this._onAction.bind(this)
		this.onActive = this._onActive.bind(this)
		this.onIdle = this._onIdle.bind(this)
	}

	componentDidMount(){
		//this.checkAuthentication();
		//get the default value from the code app settings
		get(process.env.REACT_APP_AUTH + '/session').then(result => {
			
			if(result !== undefined && result.data.data !== undefined){
				this.setState({
					idleTimeout : result.data.data.idleTimeout * 1000 * 60,
					callRefreshTimeout : (result.data.data.idleTimeout - 2) * 1000 * 60
				})
			}	
		}).catch(error =>{
			Logger.debug('Get session error: ' + error.message)
			return Promise.reject(new Error(error.message))
		})
	}
 
	render() {
		console.log('idlelayout test')
		return (
			<div>
				<IdleTimer
					ref={ref => { this.idleTimer = ref }}
					element={document}
					onActive={this.onActive}
					onIdle={this.onIdle}
					onAction={this.onAction}
					debounce={100}
					timeout={this.state.idleTimeout}
				/>
			</div>
		)
	}
 
	_onAction(e) {
		if(Token.tokenExists()){
			// console.log('elapsed time ', this.idleTimer.getElapsedTime())
			// console.log('user did something', e)
			if(this.idleTimer.getElapsedTime() >= this.state.callRefreshTimeout){
				Logger.debug('Do the refresh token - Elapsed time '+this.idleTimer.getElapsedTime())
				console.log('Do the refresh token - Elapsed time '+this.idleTimer.getElapsedTime())
				//Do the refresh token
				authProvider(ON_REFRESH_TOKEN)
				this.idleTimer.reset()
			}
			// console.log('user did something', e)
			// console.log('time remaining', this.idleTimer.getRemainingTime())
		}
	}

	_onActive(e) {
		if(Token.tokenExists()){
			console.log('user is active', e)
			console.log('time remaining', this.idleTimer.getRemainingTime())
		}
	}

	_onIdle(e) {
		// console.log('user is idle', e)
		if(Token.tokenExists()){
			const idle = {
				message : 'idle timeout'
			}
			//Log out the user
			Logger.debug('Logout the user because he/she is idle')
			authProvider(AUTH_LOGOUT, idle)
		}
		console.log('last active', this.idleTimer.getLastActiveTime())
	}
}